import { createRoutesFromElements } from 'react-router';
import { Navigate, Route, createBrowserRouter } from 'react-router-dom';
import { PATH, DYNAMIC_PATH } from '@data/enums';
import {
  DashboardPage,
  SilobagListPage,
  SilobagCreatePage,
  SilobagEditPage,
  ReportDevices,
  AdministratorPage,
  NotificactionPage,
  ActivityReportPage,
} from '@pages/private';
import { MapPage } from '@pages/private/map';
import { StockPage } from '@pages/private/report-stock';
import { SilobagDetails } from '@pages/private/silobag-detail';
import {
  LoginPage,
  ForgotPasswordPage,
  VerifyRegisterPage,
  RegisterPage,
  CompleteRegister,
  VerifyResetAccount,
  ResetPage,
} from '@pages/public';
import NotFoundPage from '@pages/public/not-found';
import { PrivatePagesLayout } from '@ui/private-layout/private-layout';
import { ProtectedRoute } from './protected-routes';
import { StateStockPage } from '@pages/private/report-state-stock';
import { SkipRoute } from './public-routes';
import { useGlobalStore } from '@global-store/use-store';
import { ReportExitenceStockPage } from '@pages/private/report-existence-stock';
import { SiloInsurancePage } from '@features/silobag-insurance/silo-insurance';
import { NotConnectedPage } from '@pages/public/not-connected';
import { SyncCorvusContainer } from '@pages/private/sync-corvus-container';

const email = useGlobalStore.getState().email;
const isUserConnected = useGlobalStore.getState().isUserOnline;

export const routerConfigFromElements = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* PUBLIC ROUTES */}
      <Route path={PATH.LOGIN} element={<LoginPage />} />
      <Route path={PATH.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
      <Route path={PATH.VERIFY_REGISTER} element={<VerifyRegisterPage />} />
      <Route path={PATH.REGISTER} element={<RegisterPage />} />
      <Route path={PATH.COMPLETE_REGISTER} element={<CompleteRegister />} />
      <Route path={PATH.VERIFY_RESET_ACCOUNT} element={<VerifyResetAccount />} />
      <Route path={PATH.RESET} element={<ResetPage />} />
      {/* PLATFORM USER AUTH REQUIRED ROUTES */}
      <Route index element={<Navigate to={PATH.DASHBOARD} />} /> {/* default home */}
      <Route element={<ProtectedRoute />}>
        <Route element={<PrivatePagesLayout withHeader withFooter withSideSpaces />}>
          <Route path={PATH.DASHBOARD}>
            <Route index element={<DashboardPage />} />
          </Route>

          <Route path={PATH.USER_NOTIFICATION} element={<NotificactionPage />} />

          <Route path={PATH.SILOBAG}>
            <Route index element={<SilobagListPage />} />
            <Route path={DYNAMIC_PATH.NEW} element={<SilobagCreatePage />} />
            <Route path={DYNAMIC_PATH.INSURANCE} element={<SiloInsurancePage />} />
            <Route path={DYNAMIC_PATH.CORVUS} element={<SyncCorvusContainer />} />
            <Route path={DYNAMIC_PATH.SILO_ID}>
              <Route index element={<SilobagDetails />} />
              <Route path={DYNAMIC_PATH.EDIT} element={<SilobagEditPage />} />
            </Route>
          </Route>
          <Route path={PATH.REPORT}>
            <Route path={DYNAMIC_PATH.STOCK} element={<StockPage />} />
            <Route path={DYNAMIC_PATH.DEVICE} element={<ReportDevices />} />
            <Route path={DYNAMIC_PATH.STATE_STOCK} element={<StateStockPage />} />
            <Route path={DYNAMIC_PATH.ACTIVITY} element={<ActivityReportPage />} />
            <Route path={DYNAMIC_PATH.EXISTENCE_STOCK} element={<ReportExitenceStockPage />} />
          </Route>
        </Route>
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route element={<PrivatePagesLayout withHeader />}>
          <Route path={PATH.MAP}>
            <Route index element={<MapPage />} />
            <Route path={DYNAMIC_PATH.SILO_ID} element={<MapPage />} />
          </Route>
        </Route>
      </Route>
      {/* ADMIN AUTH REQUIRED ROUTES */}
      <Route element={<ProtectedRoute admin />}>
        <Route element={<PrivatePagesLayout withHeader withFooter withSideSpaces />}>
          <Route path={PATH.ADMINISTRATOR}>
            <Route index element={<AdministratorPage />} />
            <Route path={DYNAMIC_PATH.NOTIFICATION} element={<NotificactionPage />} />
          </Route>
        </Route>
      </Route>
      {/*
          NOT FOUND ROUTE (SOFT) // TODO: consultar
          No se envía un status 404, se resuelve con status 200
          El status 404 solo puede ser enviado desde servidor, no desde el cliente
          Se puede hacer un endpoint 404 que devuelva solo el status 404 para manejar esto
        */}
      <Route element={email ? <ProtectedRoute /> : <SkipRoute />}>
        <Route
          element={
            email ? (
              <PrivatePagesLayout withHeader withFooter footerConfig={{ withMarginTop: false }} />
            ) : (
              <SkipRoute />
            )
          }
        >
          <Route>
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Route>
      </Route>
      <Route element={!isUserConnected && email ? <ProtectedRoute /> : <SkipRoute />}>
        <Route
          element={
            !isUserConnected && email ? (
              <PrivatePagesLayout withHeader withFooter footerConfig={{ withMarginTop: false }} />
            ) : (
              <SkipRoute />
            )
          }
        >
          <Route>
            <Route path={PATH.NO_CONNECTION} element={<NotConnectedPage />} />
          </Route>
        </Route>
      </Route>
    </>
  )
);
