import { Box, Typography, Grid, Button } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import i18n from '../../../../libs/language';
import { formatDate } from '@utils/helper';
import { configButtonStylesContained } from '@styles/generic-styles';

type ModalContentProps = {
  dateToShow: string | Date | bigint;
  silobagStatus?: boolean;
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
  handleClose: () => void;
  handleUpdateDate: () => void;
  dataSatBaggingDate?: Array<{
    sat_view_id_1_image: { timestamp: string };
    sat_view_id_2_image: { timestamp: string };
  }>;
  startDate?: number | string | Date;
  endDate?: number | string | Date;
};

export function ModalContent({
  selectedDate,
  setSelectedDate,
  handleClose,
  silobagStatus,
  handleUpdateDate,
  startDate,
  endDate,
}: ModalContentProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Box component={'div'}>
        <Typography variant="h5">{i18n.t('app.silobag_detail.different_date')}</Typography>
      </Box>

      <Box component={'div'}>
        <Typography variant="body1">{i18n.t('app.silobag_detail.different_context')}</Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="body1">{i18n.t('app.silobag_detail.silosat_date')}</Typography>
          <Typography variant="body1">{`${formatDate(startDate)} ― ${formatDate(endDate)}`}</Typography>
        </Grid>
        {silobagStatus ? (
          <Grid item xs={6}>
            <DatePicker
              label={i18n.t('app.silobag_data.bagging_date2')}
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
            />
          </Grid>
        ) : null}
      </Grid>

      <Box width="100%" display="flex" justifyContent="end" component="div">
        <Box width="100%" display="flex" justifyContent="flex-end">
          <Button variant="text" onClick={handleClose}>
            {i18n.t('app.close')}
          </Button>
          {silobagStatus ? (
            <Button sx={configButtonStylesContained} variant="contained" onClick={handleUpdateDate}>
              {i18n.t('app.save')}
            </Button>
          ) : null}
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
