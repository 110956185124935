import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Divider,
  ListItemButton,
  Button,
  Container,
  List,
  ListItem,
  Box,
  CircularProgress,
  Chip,
  IconButton,
  Tooltip,
} from '@mui/material';

/* COMPONENTS */
import { Tutorial } from '../../features/tutorial/tutorial';

/* GENERIC ICONS */
import ChevronRight from '@assets/svg/chevron-right.svg';
//import ChevronDown from '@assets/svg/chevron-down.svg';
import i18n from '../../libs/language';

/* LOCAL ICONS */
import SilobagIcon from '@assets/svg/dashboard/silobag-icon.svg';
import CountrysideIcon from '@assets/svg/dashboard/countryside-icon.svg';
import DeviceIcon from '@assets/svg/dashboard/device-icon.svg';
import AlertIcon from '@assets/svg/dashboard/bell-icon.svg';
import TonsIcon from '@assets/svg/dashboard/ton-icon.svg';
import SpeciesIcon from '@assets/svg/arroz-puita.svg';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@data/enums';

import { useService } from '@hooks/use-service';
import { useToggle, useEffectOnUpdate, useEffectOnMount } from '@hooks/core';
import { useGlobalStore } from '@global-store/use-store';
import {
  CustomPaper,
  DashboardWelcome,
  LoadingBox,
  configButtonStylesOutlined,
} from '@styles/generic-styles';
import { formatTons } from '@utils/helper';
import { DashboardSilobagAlert } from '@data/to-refactor/page-dashboard';
//import { AgrotoknIntegrationModal } from '@features/agrotoken-integration';
import { DrawerAlertCard } from '@features/global-nav/alert-panel/alert-panel';
import CheckIcon from '../../assets/svg/check.svg';
import CheveronDown from '../../assets/svg/chevron-down.svg';
import { useEffect, useState } from 'react';
import { ModalInfoRegisterSilobags } from '@features/silobags/modal-info-silobag/modal-info';
import LaSegundaIntegrationModal from '@features/lasegunda-integration';
import RusIntegrationModal from '@features/rus-integration';
import { SILOBAG_STATUSES_ID } from '@data/to-refactor/constants';
import { dashboardInfoService } from '@services/domain/page/dashboard/dashboard-info';
import { silobagDashboardInfoService } from '@services/domain/base/silobag/page-related/silobag-dashboard-info';
import { listCountrysideService } from '@services/domain/base/countryside/countryside-list';
import mixpanel from 'mixpanel-browser';

type SummaryCountryside = Awaited<ReturnType<typeof listCountrysideService>>['summary'][0];

export function DashboardPage() {
  const navigate = useNavigate();
  const {
    userFullname,
    was_tutorial_shown,
    // was_agrotoken_integration_popup_shown,
    permissions,
    organizationName,
    organizationId,
    setCountrysides,
    was_lasegunda_integration_popup_shown,
    setWas_lasegunda_integration_popup_shown,
    country_id,
    was_rus_popup_shown,
    setWas_rus_popup_shown,
    ishowOneModal,
  } = useGlobalStore();
  const orgWritePermission = permissions.organization.write;
  const [openInfoModal, setOpenInfoModal] = useState(false);

  // IMPLEMENTACION
  const {
    trigger: dashboardInformation,
    isLoading: isDashboardInfoLoading,
    summary: dashboardInfoSummary,
  } = useService(dashboardInfoService, { fetchOnMount: true }); // TODO: FALTA HACERLO DINAMICO Y CONECTARLO AL SELECTOR

  const queryCountrysideListService = { organization_id: organizationId };
  const { trigger, summary: countrysideSummary } = useService(listCountrysideService, {
    fetchOnMount: [{ query: queryCountrysideListService }],
  });

  const hasWritePermission = (countrysides: SummaryCountryside[]) => {
    return countrysides.some((countryside) =>
      countryside.v_countrysides_list.some((permission) => permission.write_permission)
    );
  };

  const reFetchCountrysideListData = () => trigger({ query: queryCountrysideListService });

  const handleRegisterSilobag = () => {
    if (
      (dashboardInfoSummary && dashboardInfoSummary.countrysides.length > 0) ||
      (countrysideSummary && countrysideSummary.length > 0 && hasWritePermission(countrysideSummary))
    ) {
      navigate(PATH.SILOBAG + '/new');
    } else {
      setOpenInfoModal(true);
    }
  };

  useEffect(() => {
    if (!isDashboardInfoLoading && dashboardInfoSummary && dashboardInfoSummary.countrysides) {
      setCountrysides(dashboardInfoSummary?.countrysides);
    }
  }, [dashboardInfoSummary]);

  useEffectOnUpdate(() => {
    dashboardInformation();
    reFetchCountrysideListData();
  }, [organizationId]);

  const renderModal = () => {
    switch (true) {
      case !was_tutorial_shown:
        return <Tutorial wasShown={!was_tutorial_shown} />;
      case !was_lasegunda_integration_popup_shown && country_id === 1 && !ishowOneModal:
        return (
          <LaSegundaIntegrationModal
            open={!was_lasegunda_integration_popup_shown}
            setOpen={setWas_lasegunda_integration_popup_shown}
          />
        );
      case !was_rus_popup_shown && country_id === 1 && !ishowOneModal:
        return <RusIntegrationModal open={!was_rus_popup_shown} setOpen={setWas_rus_popup_shown} />;
      default:
        return null;
    }
  };

  useEffectOnMount(() => mixpanel.track('DEV@DASHBOARD_PAGE'));

  return (
    <>
      {isDashboardInfoLoading || !dashboardInfoSummary ? (
        <Box sx={LoadingBox}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {renderModal()}
          <ModalInfoRegisterSilobags open={openInfoModal} setIsOpen={setOpenInfoModal} />
          <Box
            style={{
              width: '98%',
              margin: 'auto',
            }}
          >
            <Box style={{ padding: '1rem' }}>
              <Grid container direction={{ xs: 'column', md: 'row' }} sx={DashboardWelcome}>
                <Grid item xs={12} md={6} lg={6}>
                  <Stack direction={{ xs: 'column-reverse', md: 'column' }}>
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      display={'flex'}
                      justifyContent={'start'}
                      alignItems={{ xs: 'start', sm: 'start', md: 'center' }}
                      spacing={1}
                    >
                      <Typography color={'#49454F'} fontSize={'32px'}>
                        {i18n.t('app.dashboard_page.hi')}, <b>{userFullname}</b>
                      </Typography>
                      <Typography variant="subtitle1" fontSize={'16px'}>
                        {organizationName}
                      </Typography>
                    </Stack>
                    <Typography color="#36620D">{i18n.t('app.dashboard_page.welcome_to_field')}</Typography>
                  </Stack>
                </Grid>

                <Grid
                  item
                  xs={6}
                  display={'flex'}
                  justifyContent={{ xs: 'center', sm: 'center', md: 'end' }}
                  style={{ textAlign: 'right' }}
                  alignItems={'center'}
                >
                  <Box
                    sx={CustomPaper}
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={{ xs: 'center', sm: 'center', md: 'end' }}
                  >
                    <Button
                      onClick={handleRegisterSilobag}
                      variant="outlined"
                      size="large"
                      sx={configButtonStylesOutlined}
                      disabled={!orgWritePermission}
                    >
                      {i18n.t('app.register_silobag')}
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      sx={configButtonStylesOutlined}
                      style={{ color: '#fff' }}
                      onClick={() => navigate(PATH.MAP)}
                    >
                      {i18n.t('app.dashboard_page.view_map')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box style={{ padding: '1rem' }}>
              <TotalsCard
                totals={dashboardInfoSummary.totals}
                totalBySpecies={dashboardInfoSummary.totalBySpecies}
                totalsByCountryside={dashboardInfoSummary.totalsByCountryside}
              />
              <DashboardLists
                totals={dashboardInfoSummary.totals}
                countrysides={dashboardInfoSummary.countrysides}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

type DashboardInfoDataProps = Awaited<ReturnType<typeof dashboardInfoService>>['summary'];
type TotalSpecieType = DashboardInfoDataProps['totalBySpecies'];
type TotalCountrysideType = DashboardInfoDataProps['totalsByCountryside'];
type TotalType = DashboardInfoDataProps['totals'];
type CountrysidesType = DashboardInfoDataProps['countrysides'];

type SilobagDashboardType = Awaited<ReturnType<typeof silobagDashboardInfoService>>['summary'];
type ValidSilobagType = TotalType['by_status'][0];

type TotalsListTypes = {
  totals: TotalType;
  totalBySpecies: TotalSpecieType;
  totalsByCountryside: TotalCountrysideType;
};

type TotalByStatusType = DashboardInfoDataProps['totals']['by_status'][0];

function TotalsCard({ totals, totalBySpecies, totalsByCountryside }: TotalsListTypes) {
  const navigate = useNavigate();
  const [alertSide, setAlertSideBar] = useToggle(false);
  const [invisible, setInvisible] = useToggle(true);

  const OpenAlertSideBar = () => {
    setAlertSideBar(true);
  };

  const validSillobags = totals.by_status.find(
    (el: TotalByStatusType) => el.silobag_data_status_id === SILOBAG_STATUSES_ID.VALID_SILOBAG_STATUS_ID
  );
  const nonValidSillobags = totals.by_status.find(
    (el: TotalByStatusType) => el.silobag_data_status_id === SILOBAG_STATUSES_ID.NON_VALID_SILOBAG_STATUS_ID
  );

  const validSilobagsQty = validSillobags?.total_silobags;
  const nonValidSilobagsQty = nonValidSillobags?.total_silobags;

  const monitoredTons = formatTons(parseInt(validSillobags?.remaining_stock ?? '0')) || 0;
  const noValidatedMonitoredTons = formatTons(parseInt(nonValidSillobags?.remaining_stock ?? '0')) || 0;

  return (
    <>
      <DrawerAlertCard open={alertSide} onClose={() => setAlertSideBar(false)} onChangeBadge={setInvisible} />
      <Accordion style={{ boxShadow: 'none', borderRadius: '10px' }}>
        <Stack>
          <Grid item>
            <Stack
              direction={{ xs: 'row', sm: 'row', md: 'row', lg: 'row' }}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                alignContent: 'center',
              }}
            >
              <Grid
                item
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate(PATH.SILOBAG)}
                  color={'#49454F'}
                >
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    display={'flex'}
                    justifyContent={{ xs: 'center' }}
                    alignItems={'center'}
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      width={'3rem'}
                      component={'img'}
                      height="100%"
                      src={SilobagIcon}
                    />
                    <Stack direction={'column'}>
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        display={'flex'}
                        justifyContent={{ xs: 'center' }}
                        alignItems={'center'}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '1rem',
                            lineHeight: 1.7,
                            verticalAlign: 'middle',
                          }}
                          fontSize={{ xs: '2.3rem', md: '2.5rem' }}
                          fontWeight={'bold'}
                        >
                          {validSilobagsQty}
                        </Box>
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '0 1rem 0 1rem',
                            lineHeight: 1,
                            verticalAlign: 'middle',
                          }}
                          fontSize={{ xs: '1rem', md: '1.2rem' }}
                        >
                          {i18n.t('app.dashboard_page.silobags_actives')}
                        </Box>
                      </Stack>
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: '1rem',
                          lineHeight: 1,
                          verticalAlign: 'middle',
                        }}
                        fontSize={{ xs: '.7rem', md: '1rem' }}
                        color={'#8d8d90'}
                      >
                        {nonValidSilobagsQty} {i18n.t('app.dashboard_page.non_validated')}
                      </Box>
                    </Stack>
                  </Stack>
                </Typography>
              </Grid>
              <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                  <Typography
                    component="div"
                    style={{
                      height: '100%',
                      display: 'flex',
                    }}
                    color={'#49454F'}
                  >
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      display={'flex'}
                      justifyContent={{ xs: 'center' }}
                      alignItems={'center'}
                    >
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'3rem'}
                        component={'img'}
                        height="2rem"
                        src={TonsIcon}
                      />
                      <Stack direction={'column'}>
                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          display={'flex'}
                          justifyContent={{ xs: 'center' }}
                          alignItems={'center'}
                        >
                          <Box
                            style={{ display: 'flex', alignItems: 'center', marginLeft: '1rem' }}
                            fontSize={{ xs: '2.3rem', md: '2.5rem' }}
                            fontWeight={'bold'}
                          >
                            {i18n.numberToDelimited(monitoredTons, {
                              delimiter: i18n.t('config.delimiter'),
                            })}
                          </Box>
                          <Box
                            style={{
                              fontSize: '1.2rem',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              margin: '0 1rem 0 1rem',
                            }}
                          >
                            <Box
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              fontSize={{ xs: '1rem', md: '1.2rem' }}
                            >
                              {i18n.t('app.dashboard_page.validated_tons')}
                            </Box>
                          </Box>
                        </Stack>
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '1rem',
                            lineHeight: 1,
                            verticalAlign: 'middle',
                            height: '100%',
                          }}
                          fontSize={{ xs: '.7rem', md: '1rem' }}
                          color={'#8d8d90'}
                        >
                          {i18n.numberToDelimited(noValidatedMonitoredTons, {
                            delimiter: i18n.t('config.delimiter'),
                          })}{' '}
                          {i18n.t('app.dashboard_page.non_monitored_validated')}
                        </Box>
                      </Stack>
                      <img src={CheveronDown} alt="open-accordeon" style={{ marginLeft: '5px' }} />
                    </Stack>
                  </Typography>
                </AccordionSummary>
              </Grid>
              <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{
                    height: '100%',
                    display: 'flex',
                    cursor: 'pointer',
                  }}
                  onClick={OpenAlertSideBar}
                  color={'#49454F'}
                >
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    display={'flex'}
                    justifyContent={{ xs: 'center' }}
                    alignItems={'center'}
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      width={'3rem'}
                      component={'img'}
                      height="2rem"
                      src={AlertIcon}
                    />

                    <Box style={{ display: 'flex', alignItems: 'center', marginLeft: '1rem' }}>
                      <span style={{ fontSize: '2.5rem', fontWeight: 'bold' }}>{totals.total_alerts}</span>
                    </Box>
                    <Box
                      style={{
                        fontSize: '1.2rem',
                        display: 'flex',
                        alignItems: 'center',
                        margin: '0 1rem 0 1rem',
                      }}
                    >
                      {i18n.t('app.dashboard_page.siloreal_alerted')}
                    </Box>
                  </Stack>
                </Typography>
              </Grid>
            </Stack>
          </Grid>
        </Stack>
        <AccordionDetails>
          <Grid container spacing={1} columns={12} style={{}}>
            <Stack
              direction={{ xs: 'column', md: 'row', lg: 'row' }}
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              style={{ width: '100%' }}
            >
              <Grid item xs={12} md={5} lg={5}>
                <Typography color={'#49454F'} fontWeight={'bold'}>
                  {i18n.t('app.dashboard_page.tons_for_species')}:
                </Typography>
                <Grid container spacing={1} columns={12} style={{ marginTop: '1rem', padding: '0.3rem' }}>
                  {totalBySpecies?.map((item: TotalSpecieType[0], i: number) => (
                    <Grid item xs={12} sm={12} md={6} lg={4} key={i}>
                      <Box
                        style={{
                          display: 'flex',
                          marginTop: '0.8rem',
                          flex: '0 0 auto',
                          width: '100%',
                        }}
                      >
                        <Box
                          style={{
                            height: '80%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img src={item.image_url ?? SpeciesIcon} style={{ width: '90%' }} />
                        </Box>
                        <Stack
                          style={{ marginLeft: '0.5rem' }}
                          direction={{ xs: 'column', md: 'column', lg: 'column' }}
                        >
                          <Typography color={'#49454F'} fontWeight={'bold'} fontSize={'.8rem'}>
                            {item.name}
                          </Typography>
                          <Typography color={'#49454F'}>
                            <span style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
                              {i18n.numberToDelimited(
                                formatTons(parseInt(item.remaining_stock ?? '0')) || 0,
                                {
                                  delimiter: i18n.t('config.delimiter'),
                                }
                              )}{' '}
                            </span>
                            <span style={{ fontSize: '0.8rem' }}>Toneladas</span>
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} md={7} lg={7}>
                <Typography color={'#49454F'} fontWeight={'bold'}>
                  {i18n.t('app.dashboard_page.tons_for_countrysides')}:
                </Typography>
                <Grid container spacing={2} columns={16} style={{ marginTop: '1rem' }}>
                  {totalsByCountryside?.map((item: TotalCountrysideType[0], i: number) => (
                    <Grid item xs={12} sm={12} md={8} lg={8} key={i}>
                      <Box style={{ display: 'flex', marginTop: '0.5rem', flex: '0 0 auto', width: '100%' }}>
                        <Box
                          style={{
                            height: '80%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img src={CountrysideIcon} width={'90%'} />
                        </Box>
                        <Box style={{ marginLeft: '.5rem' }}>
                          <Typography fontSize={'0.8rem'} color={'#49454F'}>
                            {item.organization_name}
                          </Typography>
                          <Typography fontWeight={'bold'} fontSize={'.8rem'} color={'#49454F'}>
                            {item.name}
                          </Typography>
                          <Typography fontSize={'.9rem'} color={'#49454F'}>
                            {i18n.numberToDelimited(formatTons(parseInt(item.remaining_stock ?? '0')) || 0, {
                              delimiter: i18n.t('config.delimiter'),
                            })}{' '}
                            <span style={{ fontSize: '0.8rem' }}>Toneladas</span>
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

type DashboardListTypes = {
  totals: TotalType;
  countrysides: CountrysidesType;
};

function DashboardLists({ totals, countrysides }: DashboardListTypes) {
  return (
    <Box style={{ marginTop: '1rem', backgroundColor: '#f3f2f7', height: '70%' }}>
      <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={2} style={{ width: '100%' }}>
        <Box width={{ sm: '100%', md: '35%' }}>
          <Stack direction={'column'} spacing={2}>
            <CountrysidesList countrysides={countrysides} />
            <DevicesList totals={totals} />
          </Stack>
        </Box>
        <Box width={{ sm: '100%', md: '65%' }}>
          <SilobagsList totals={totals} />
        </Box>
      </Stack>
    </Box>
  );
}

function CountrysidesList({ countrysides }: { countrysides: CountrysidesType }) {
  const navigate = useNavigate();
  return (
    <>
      <Container style={{ background: '#fff', padding: '1rem', borderRadius: '20px', width: '100%' }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            height: '2rem',
          }}
        >
          <Stack direction={'row'} spacing={1} display={'flex'} alignItems={'center'}>
            <Tooltip title={i18n.t('app.dashboard_page.countrysides_tooltip')} placement="top-end">
              <Typography variant="h6" color={'#49454F'}>
                {i18n.t('app.countrysides')}
              </Typography>
            </Tooltip>

            <Typography variant="body2" color={'#49454F'}>
              ({countrysides.length})
            </Typography>
          </Stack>
          <Button
            variant="text"
            color="info"
            size="small"
            onClick={() => navigate(PATH.MAP)}
            style={{ textTransform: 'none' }}
          >
            {i18n.t('app.dashboard_page.see_map')}
          </Button>
        </Box>
        <Box
          style={{
            maxHeight: '20rem',
            overflow: 'auto',
            borderRadius: 'sm',
          }}
        >
          <List>
            {countrysides.map((item, i) => (
              <ListItem key={i} disablePadding>
                <ListItemButton onClick={() => navigate(PATH.MAP)}>
                  <Box
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'start',
                      marginRight: '1rem',
                    }}
                  >
                    <img src={CountrysideIcon} style={{ width: '90%' }} />
                  </Box>
                  <Box
                    style={{
                      color: '#000',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'start',
                      height: '100%',
                    }}
                  >
                    <Box style={{ height: '70%' }}>
                      <Typography style={{ display: 'flex', justifyContent: 'start' }}>
                        <span style={{ fontSize: '12px' }}>{item.organization_name}</span>
                      </Typography>
                      <Typography style={{ display: 'flex', justifyContent: 'start' }}>
                        <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{item.name}</span>
                      </Typography>
                    </Box>
                  </Box>
                  <img src={ChevronRight} alt="chevron-right" style={{ width: '20px' }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Container>
    </>
  );
}

function SilobagsList({ totals }: { totals: TotalType }) {
  const navigate = useNavigate();

  const { isLoading: isSilobagListLoading, summary: silobags } = useService(silobagDashboardInfoService, {
    fetchOnMount: true,
  });

  const validSillobags = totals?.by_status.find(
    (el: ValidSilobagType) => el.silobag_data_status_id === SILOBAG_STATUSES_ID.VALID_SILOBAG_STATUS_ID
  );

  return (
    <>
      {isSilobagListLoading ? (
        <Box sx={LoadingBox}>
          <CircularProgress />
        </Box>
      ) : (
        <Container
          style={{
            background: '#fff',
            height: '100%',
            padding: '1rem',
            borderRadius: '20px',
            width: '100%',
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              height: '2rem',
            }}
          >
            <Stack direction={'row'} spacing={1} display={'flex'} alignItems={'center'}>
              <Typography variant="h6" color={'#49454F'}>
                {i18n.t('app.siloreales')}
              </Typography>
              <Typography variant="body2" color={'#49454F'}>
                ({validSillobags && validSillobags.total_silobags})
              </Typography>
            </Stack>
            <Button
              variant="text"
              color="info"
              size="small"
              onClick={() => navigate(PATH.MAP)}
              style={{ textTransform: 'none' }}
            >
              {i18n.t('app.dashboard_page.see_map')}
            </Button>
          </Box>
          <Box
            style={{
              height: '40rem',
              overflow: 'auto',
              borderRadius: 'sm',
            }}
          >
            <List>
              {silobags?.map((item: SilobagDashboardType[0]) => (
                <ListItem key={item.silobag_id}>
                  <ListItemButton
                    onClick={() => navigate(PATH.SILOBAG + '/' + item.silobag_id)}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      backgroundColor: '#f3f2f7',
                      borderRadius: '10px',
                      borderLeft: `0.8rem solid ${item.hex}`,
                    }}
                  >
                    <Stack direction="row" spacing={5} alignItems="center" style={{ width: '100%' }}>
                      <Stack direction="row" spacing={1} alignItems="center" style={{ flex: 1 }}>
                        <img src={item.silobag_species_type_logo_url} style={{ width: 48, height: 48 }} />
                        <Stack direction={{ xs: 'column-reverse', md: 'column' }} color={'#000'}>
                          <Typography
                            display={{ xs: 'none', md: 'flex' }}
                            justifyContent={'start'}
                            fontSize={'0.8rem'}
                            color={'#49454F'}
                          >
                            {item.organization_name}
                          </Typography>
                          <Typography
                            display={'flex'}
                            justifyContent={'start'}
                            fontSize={'1rem'}
                            color={'#49454F'}
                            fontWeight={'bold'}
                            style={{
                              fontSize: '0.85rem',
                              fontWeight: 600,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {item.silobag_name.length > 18
                              ? item.silobag_name.substring(0, 18) + '...'
                              : item.silobag_name}
                          </Typography>
                          <Typography
                            fontSize={'0.8rem'}
                            display={'flex'}
                            justifyContent={'start'}
                            color={'#49454F'}
                          >
                            {item.countryside_name.length > 15
                              ? item.countryside_name.substring(0, 15) + '...'
                              : item.countryside_name}{' '}
                            / {item.lot_name}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row">
                        <Typography
                          style={{
                            fontWeight: 'bold',
                            marginRight: '0.25rem',
                            opacity: '0.9',
                          }}
                          fontSize={{ xs: '1rem', md: '1.rem' }}
                          color={'#49454F'}
                        >
                          {i18n.numberToDelimited(formatTons(parseFloat(item.remaining_stock || '0')) ?? 0, {
                            delimiter: i18n.t('config.delimiter'),
                          })}
                        </Typography>
                        <Typography style={{ fontSize: '1rem' }} color={'#49454F'}>
                          Toneladas
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center" display={{ xs: 'none', lg: 'flex' }}>
                        <Typography style={{ marginRight: '1rem' }} color={'#49454F'}>
                          Valor alerta: {item.alert_value ?? '―'}
                        </Typography>
                        <Chip
                          style={{
                            width: 60,
                            borderRadius: '10px',
                            color: item.severity === 1 ? '#000' : '#fff',
                          }}
                          label={item.short_name_last_alert ?? <img src={CheckIcon} alt="check" />}
                          color={
                            DashboardSilobagAlert[item.severity]?.value as
                              | 'success'
                              | 'secondary'
                              | 'error'
                              | 'warning'
                              | undefined
                          }
                        />
                      </Stack>
                    </Stack>
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      height={'100%'}
                      mt={'15px'}
                      ml={3}
                    >
                      <img
                        src={ChevronRight}
                        alt="chevron-right"
                        style={{
                          width: '20px',
                        }}
                      />
                    </Box>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Container>
      )}
    </>
  );
}

function DevicesList({ totals }: { totals: TotalType }) {
  const navigate = useNavigate();

  return (
    <>
      <Container
        style={{
          backgroundColor: '#fff',
          borderRadius: '20px',
          width: '100%',
          marginBottom: '1rem',
        }}
      >
        <Box
          style={{ display: 'flex', justifyContent: 'start', padding: '1rem 1.5rem 0 0rem' }}
          mb={{ sm: '1rem', md: 0 }}
        >
          <Typography fontWeight="bold" variant="h6" color={'#49454F'}>
            Dispositivos
          </Typography>
        </Box>
        <Box>
          <Stack direction={'row'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack
              direction={'row'}
              spacing={1}
              display={'flex'}
              justifyContent={'start'}
              alignItems={'center'}
            >
              <img src={DeviceIcon} alt="device-icon" style={{ width: '3rem', height: '2rem' }} />
              <Stack direction={'row'} spacing={2} display={'flex'} alignItems={'center'}>
                <span style={{ fontWeight: 'bold', color: '#ffdd00', fontSize: '1.2rem' }}>
                  {totals.devicesCount}
                </span>
                <span>{i18n.t('app.dashboard_page.silobag_lance_sensor')}</span>
              </Stack>
            </Stack>
            <IconButton onClick={() => navigate(PATH.REPORT_DEVICE)}>
              <img src={ChevronRight} alt="chevron-right" style={{ width: '20px' }} />
            </IconButton>
          </Stack>
        </Box>
      </Container>
    </>
  );
}
