import { ModalIOF } from '@ui/modal/modal';
import {
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Stack,
  Button,
  CircularProgress,
} from '@mui/material';
import {
  styledTextField,
  configButtonStylesContained,
  configButtonStylesOutlined,
} from '@styles/generic-styles';
import i18n from '../../libs/language';
import { useState } from 'react';
import { verifyPhoneService } from '@services/domain/user/notification/verify-phone';
import { useService } from '@hooks/use-service';
import { updateUserDataService } from '@services/domain/user/base/user-edition';
import { confirmPhoneService } from '@services/domain/user/notification/confirm-phone';
import { useEffectOnUpdate } from '@hooks/core';
import { useGlobalStore } from '@global-store/use-store';

type VerificationPhoneModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  userID: number;
  setSuccess: (success: boolean) => void;
  setError: (error: boolean) => void;
  setErrorMessage: (message: string) => void;
};

const STEP_TYPE = {
  PHONE: 'PHONE',
  CODE: 'CODE',
};

export const VerificationPhoneModal = (props: VerificationPhoneModalProps) => {
  const { open, setOpen, userID, setSuccess, setError, setErrorMessage } = props;
  const { country_id } = useGlobalStore();
  const [phone, setPhone] = useState('1123');
  const [phoneArea, setPhoneArea] = useState<'54' | '55'>(country_id !== 1 ? '55' : '54');
  const [code, setCode] = useState<string | number>('');
  const [step, setStep] = useState(STEP_TYPE.PHONE);
  const { trigger, isLoading, isError } = useService(verifyPhoneService, {
    onSuccess: () => {
      setStep(STEP_TYPE.CODE);
    },
  });
  const {
    trigger: confirmCode,
    isLoading: isLoadingConfirmCode,
    isError: isErrorCode,
  } = useService(confirmPhoneService, {
    onSuccess: () => {
      setOpen(false);
      setSuccess(true);
    },
  });
  const { trigger: updateUserPhone, isLoading: isLoadingEditUser } = useService(updateUserDataService, {
    onSuccess: () => {
      trigger();
    },
  });
  const phoneAreaDetails = {
    54: {
      flag: '🇦🇷',
      placeholder: '+54 ',
    },
    55: {
      flag: '🇧🇷',
      placeholder: '+55 ',
    },
  };

  useEffectOnUpdate(() => {
    if (!isLoading && isError) {
      setError(true);
      setErrorMessage(i18n.t('app.notification_page.confirm_error'));
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
    if (!isLoadingConfirmCode && isErrorCode) {
      setError(true);
      setErrorMessage(i18n.t('app.notification_page.confirm_error_code'));
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [isError, isErrorCode]);

  const exampleText = phoneArea == '54' ? 'Ej: +54 91157059192' : 'Ej: +55 11912345678';
  return (
    <ModalIOF open={open} ModalIsOpen={setOpen} width="800px">
      <>
        {step === STEP_TYPE.PHONE ? (
          <Box>
            <ModalIOF.HeaderCenter>
              <Stack direction={'column'} spacing={1}>
                <Typography variant="h6">{i18n.t('app.notification_page.validate_phone')}</Typography>
                <Typography variant="body2">{i18n.t('app.notification_page.validate_text')}</Typography>
              </Stack>
            </ModalIOF.HeaderCenter>
            <ModalIOF.Body>
              <Stack
                direction={'row'}
                spacing={1}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ margin: '0 auto', width: '95%', marginTop: 3 }}
              >
                <FormControl size="small" style={{ width: '74px' }}>
                  <InputLabel>Área</InputLabel>
                  <Select
                    label="Área"
                    renderValue={() => <div>{phoneAreaDetails[phoneArea]?.flag}</div>}
                    value={phoneArea}
                    sx={{ backgroundColor: 'white' }}
                    onChange={(e) => {
                      setPhoneArea(e.target.value as '54' | '55');
                    }}
                  >
                    <MenuItem value={54}>
                      🇦🇷 Argentina <span style={{ color: '#a7a7a7' }}>&nbsp;(+54)</span>
                    </MenuItem>
                    <MenuItem value={55}>
                      🇧🇷 Brasil <span style={{ color: '#a7a7a7' }}>&nbsp;(+55)</span>
                    </MenuItem>
                  </Select>
                  <Box sx={{ visibility: 'hidden' }}>msg</Box>
                </FormControl>
                <FormControl>
                  <TextField
                    required
                    id="phone"
                    size="small"
                    helperText={exampleText}
                    label={i18n.t('app.public_pages.phone')}
                    variant="outlined"
                    sx={{ ...styledTextField, width: '300px' }}
                    onChange={(e) => setPhone(phoneArea + e.target.value)}
                    //TODO: hacer validaciones globales para aplicar en los inputs de la app
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    InputProps={{
                      startAdornment: phoneAreaDetails[phoneArea as '54'] ? (
                        <Box style={{ width: 44 }}>{phoneAreaDetails[phoneArea as '54']?.placeholder}</Box>
                      ) : null,
                      inputProps: { maxLength: 25 },
                    }}
                  />
                </FormControl>
              </Stack>
              <Stack direction={'row'} justifyContent={'flex-end'} spacing={2} marginTop={2}>
                <Button
                  variant="outlined"
                  sx={{ ...configButtonStylesOutlined }}
                  onClick={() => setOpen(false)}
                >
                  {i18n.t('app.cancel')}
                </Button>
                <Button
                  variant="contained"
                  sx={{ ...configButtonStylesContained }}
                  onClick={() => updateUserPhone({ query: userID, body: { phone: phone } })}
                  disabled={isLoadingEditUser || isLoading}
                >
                  {isLoadingEditUser || isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    i18n.t('app.notification_page.send_sms')
                  )}
                </Button>
              </Stack>
            </ModalIOF.Body>
          </Box>
        ) : (
          <Box>
            <ModalIOF.HeaderCenter>
              <Stack direction={'column'} spacing={1} textAlign={'center'}>
                <Typography variant="h5">{i18n.t('app.notification_page.validation_modal_title')}</Typography>
                <Typography>{i18n.t('app.notification_page.validation_modal_text')}</Typography>
              </Stack>
            </ModalIOF.HeaderCenter>
            <ModalIOF.Body>
              <Stack direction={'column'} spacing={2} alignItems={'center'} marginTop={2}>
                <FormControl>
                  <TextField
                    required
                    id="code"
                    label="Codigo"
                    variant="outlined"
                    size="small"
                    sx={styledTextField}
                    value={code ?? ''}
                    onChange={(e) => setCode(+e.target.value)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{ maxLength: 6 }}
                  />
                </FormControl>
                <Button
                  variant="contained"
                  sx={{ ...configButtonStylesContained, width: '400px' }}
                  onClick={() => confirmCode({ body: { code: code } })}
                  disabled={isLoadingConfirmCode}
                >
                  {isLoadingConfirmCode ? <CircularProgress size={20} /> : 'Enviar'}
                </Button>
                <Typography variant="body2">{i18n.t('app.public_pages.no_received_code')}</Typography>
                <Stack direction={'row'} spacing={1}>
                  <FormControl>
                    <TextField
                      required
                      id="phone"
                      label="Telefono"
                      variant="outlined"
                      size="small"
                      sx={{ ...styledTextField, width: '300px' }}
                      value={phone ?? ''}
                      disabled
                    />
                  </FormControl>
                  <Button
                    variant="outlined"
                    sx={{ ...configButtonStylesOutlined }}
                    onClick={() => trigger()}
                    disabled={isLoading}
                  >
                    {isLoading ? <CircularProgress size={20} /> : 'Reenviar'}
                  </Button>
                </Stack>
              </Stack>
            </ModalIOF.Body>
          </Box>
        )}
      </>
    </ModalIOF>
  );
};
