import {
  Box,
  Button,
  Fade,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { SpeciesLocale } from '@typed/domain/base/silobag/silobag-species.types';
import { Silobag } from '@typed/domain/base/silobag/silobag.types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { PropsWithChildren } from 'react';
import i18n from '../../../../../libs/language';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';

type LabProtocolSummary = Awaited<
  ReturnType<typeof silobagDataDetailService>
>['summary']['lab_protocols_history'][0];
type LabResult = LabProtocolSummary['lab_protocols_results_history'][0];

type LabModalProps = {
  open: boolean;
  onClose: () => void;
  data: LabProtocolSummary;
  siloVariety?: Silobag['variety'];
  siloSpecies?: SpeciesLocale['description'];
};
export function LabHistoryModal({ open, onClose, data, siloVariety, siloSpecies }: LabModalProps) {
  const targetLabResults = data?.lab_protocols_results_history;
  const formData = targetLabResults?.reduce(
    (rec, entry) => ({ ...rec, [entry.lab_determinations.id]: entry.result }),
    {}
  );

  const HEADER_DATA = {
    species: siloSpecies,
    variety: siloVariety,
    acquisitionDate: data?.acquisition_date,
    analysisDate: data?.protocol_date,
    lab: data?.laboratory,
    analysisNumber: data?.protocol_number,
  };

  const hasLabData = Boolean(data);

  return (
    <MyModal open={open} onClose={onClose}>
      <Stack direction="row" paddingRight={4} justifyContent="space-between" alignItems="center" spacing={8}>
        <Box component="h1" fontWeight={500} style={{ color: '#49454F', flex: 5 }}>
          {i18n.t('app.lab.detail_sample_modal.title')}
        </Box>
        <Stack flex={1}>
          <Typography fontSize={13} color="#7A7A7A">
            {i18n.t('app.species')}
          </Typography>
          <Typography fontSize={14} fontWeight={500} color="#49454F">
            {HEADER_DATA.species ?? '―'}
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Typography fontSize={13} color="#7A7A7A">
            Variedad
          </Typography>
          <Typography fontSize={14} fontWeight={500} color="#49454F">
            {HEADER_DATA.variety ?? '―'}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack flex={1}>
          <Typography fontSize={13} color="#7A7A7A">
            {i18n.t('app.lab.detail_sample_modal.acquisition_date')}
          </Typography>
          <Typography fontSize={14} fontWeight={500} color="#49454F">
            {HEADER_DATA.acquisitionDate ? dayjs.utc(HEADER_DATA.acquisitionDate).format('DD/MM/YYYY') : '―'}
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Typography fontSize={13} color="#7A7A7A">
            {i18n.t('app.lab.detail_sample_modal.analysis_date')}
          </Typography>
          <Typography fontSize={14} fontWeight={500} color="#49454F">
            {HEADER_DATA.analysisDate ? dayjs.utc(HEADER_DATA.analysisDate).format('DD/MM/YYYY') : '―'}
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Typography fontSize={13} color="#7A7A7A">
            {i18n.t('app.lab.laboratory')}
          </Typography>
          <Typography fontSize={14} fontWeight={500} color="#49454F">
            {HEADER_DATA.lab ?? '―'}
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Typography fontSize={13} color="#7A7A7A">
            {i18n.t('app.lab.detail_sample_modal.analysis_number')}
          </Typography>
          <Typography fontSize={14} fontWeight={500} color="#49454F">
            {HEADER_DATA.analysisNumber ?? '―'}
          </Typography>
        </Stack>
      </Stack>
      <ResultTable
        editMode={false}
        hasLabData={hasLabData}
        targetLabResults={targetLabResults ?? []}
        formData={formData}
      />
      <Stack direction="row" justifyContent="end" marginTop={2}>
        <Button
          style={{
            borderRadius: 100,
            textTransform: 'none',
            border: 'none',
            color: 'black',
            alignSelf: 'flex-end',
          }}
          onClick={onClose}
        >
          {i18n.t('app.close')}
        </Button>
      </Stack>
    </MyModal>
  );
}

type ResultTableProps = {
  targetLabResults: LabResult[];
  hasLabData: boolean;
  editMode: boolean;
  formData: Record<number, string>;
  // formErrors: Record<number, string>;
  // onChangeFormData: (determination: Determination, input: string) => void;
};
function ResultTable(props: ResultTableProps) {
  const { targetLabResults, hasLabData, editMode } = props;
  const { formData } = props;

  return (
    <Box style={{ overflow: 'auto', height: '100%' }}>
      <TableContainer style={{ border: '1px solid #E2E5EC', height: '100%', minHeight: '400px' }}>
        <Table style={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow style={{ borderCollapse: 'separate' }}>
              <TableCell style={{ width: '45%' }}>
                {i18n.t('app.lab.detail_sample_modal.column_header.field')}
              </TableCell>
              <TableCell style={{ width: '25%' }}>
                {i18n.t('app.lab.detail_sample_modal.column_header.result')}
              </TableCell>
              <TableCell style={{ width: '30%', borderLeft: '1px solid #E2E5EC', fontSize: 14 }}>
                {i18n.t('app.lab.detail_sample_modal.column_header.ref')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hasLabData ? (
              <>
                {targetLabResults
                  .filter((e) => !e.lab_determinations.is_header_field)
                  .map((entry) => {
                    // Search for relevant data
                    const { lab_determinations } = entry;
                    const {
                      lab_determinations_translations,
                      lab_unit: { lab_units_translations },
                    } = lab_determinations;
                    const [determinationTranslation] = lab_determinations_translations;
                    const [unitTranslation] = lab_units_translations;

                    // Relevant data
                    const { id } = entry;
                    // const { result } = entry;
                    const { description, reference_values } = determinationTranslation;
                    const { symbol } = unitTranslation;

                    // Preprocess data before render
                    const symbolDisplay = symbol ? ` (${symbol})` : '';
                    const descriptionDisplay = description
                      .toLocaleLowerCase()
                      .replace(/^.{1}/g, description[0].toUpperCase());
                    const fieldDisplay = descriptionDisplay + symbolDisplay;

                    return (
                      <TableRow key={id}>
                        <TableCell>
                          <Typography fontSize={12}>{fieldDisplay}</Typography>
                        </TableCell>
                        <TableCell padding={editMode ? 'none' : 'normal'}>
                          {formData[lab_determinations.id]}
                        </TableCell>
                        <TableCell>{reference_values}</TableCell>
                      </TableRow>
                    );
                  })}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={3} rowSpan={2} align="center">
                  Sin muestra cargada
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    height: '95vh',
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}
