import { ModalIOF } from '@ui/modal/modal';
import { Typography, Stack, Button } from '@mui/material';
import { configButtonStylesContained } from '@styles/generic-styles';
import i18n from '../../libs/language';

type VerificationPhoneModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const WarningVerificationPhone = (props: VerificationPhoneModalProps) => {
  const { open, setOpen } = props;
  return (
    <ModalIOF open={open} ModalIsOpen={setOpen} width="800px">
      <ModalIOF.HeaderCenter>
        <Typography variant="h6">{i18n.t('app.notification_page.warning_modal_title')}</Typography>
      </ModalIOF.HeaderCenter>
      <ModalIOF.Body>
        <Typography variant="body2" textAlign={'center'}>
          {i18n.t('app.notification_page.warning_modal_text')}
        </Typography>
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
          <Button variant="contained" sx={configButtonStylesContained} onClick={() => setOpen(false)}>
            Cerrar
          </Button>
        </Stack>
      </ModalIOF.Body>
    </ModalIOF>
  );
};
