import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { useEffectOnUpdate, useToggle } from '@hooks/core';
import { mapInfoService } from '@services/domain/page/map/map-info';
import { PropsWithChildren, createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// --- SHORTCUTS (for types) --- \\\
type MapInfoSummary = Awaited<ReturnType<typeof mapInfoService>>['summary'];
type SetState<T> = React.Dispatch<React.SetStateAction<T>>;
type Unpacked<T> = T extends (infer U)[] ? U : never;

// --- GENERAL TYPES --- \\\

type UIState = 'normal' | 'extended' | 'detail-from-normal' | 'detail-from-extended';

// Data with markers and target for countryside and silobag
type CountrysideSummary = Unpacked<MapInfoSummary['countrysides']>;
type CountrysideWithMarkerAndBounds = Omit<CountrysideSummary, 'silobags'> & {
  marker: google.maps.Marker;
  silobags: SilobagWithMarker[];
  silobagsBounds: google.maps.LatLngBounds;
};
type SilobagSummary = Unpacked<CountrysideSummary['silobags']>;
type SilobagWithMarker = SilobagSummary & { marker: google.maps.Marker };

type BaseIDX = { IDX: number };
type TargetCountryside = BaseIDX & Partial<CountrysideWithMarkerAndBounds>;
export type TargetSilobag = BaseIDX & Partial<SilobagWithMarker>;

type SummaryWithMarkers = Omit<MapInfoSummary, 'countrysides'> & {
  countrysides: CountrysideWithMarkerAndBounds[];
};

type LotSummary = SilobagSummary['lots'];

type FiltersParams = {
  lot: number | '';
  species: number | '';
  product: number | '';
  harvest: string;
};

// Context to be used throughout the map components
type Context = {
  map: google.maps.Map | undefined;
  setMap: React.Dispatch<React.SetStateAction<google.maps.Map | undefined>>;
  fullData: SummaryWithMarkers | null;
  setFullData: SetState<SummaryWithMarkers | null>;
  initFullData: SummaryWithMarkers | null;
  setInitFullData: SetState<SummaryWithMarkers | null>;
  cluster: MarkerClusterer | undefined;
  setCluster: React.Dispatch<React.SetStateAction<MarkerClusterer | undefined>>;
  infoWindow: google.maps.InfoWindow | undefined;
  setInfoWindow: React.Dispatch<React.SetStateAction<google.maps.InfoWindow | undefined>>;
  globalBounds: google.maps.LatLngBounds | undefined;
  setGlobalBounds: React.Dispatch<React.SetStateAction<google.maps.LatLngBounds | undefined>>;
  ui: UIState;
  setUI: React.Dispatch<React.SetStateAction<UIState>>;
  hideUI: boolean;
  toggleHideUI: (newValue?: unknown) => void;
  lotsOptions: LotSummary[];
  targetCountryside: TargetCountryside;
  targetSilobag: TargetSilobag;
  MAP_UTILS: {
    CHANGE_TARGET: {
      countryside: (i: number) => void;
      silobag: (i: number) => void;
    };
    NAVIGATION: {
      fromCountryside: (i: number) => {
        toDetail: () => void;
        toExtended: () => void;
      };
      fromSilobag: (i: number) => { toDetail: () => void };
      fromDetailToMenu: () => void;
    };
    SILOBAG_INDEX_MOVE: {
      PREV: () => void;
      NEXT: () => void;
    };
    FILTER: (filters: FiltersParams) => void;
    HANDLERS: {
      onSilobagPinClick: (countrysideIDX: number, silobagIDX: number) => void;
      initOnDetail: (ci: number, si: number) => void;
    };
  };
};

export const MapContext = createContext<Context | null>(null);

export function MapProvider({ children }: PropsWithChildren) {
  const initTarget = { IDX: 0 };

  // Google Map related states
  const [map, setMap] = useState<google.maps.Map>();
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>();
  const [cluster, setCluster] = useState<MarkerClusterer>();
  const [globalBounds, setGlobalBounds] = useState<google.maps.LatLngBounds>();

  // hooks
  const navigate = useNavigate();

  // Features states
  const [fullData, setFullData] = useState<SummaryWithMarkers | null>(null);
  const [ui, setUI] = useState<UIState>('normal');
  const [hideUI, toggleHideUI] = useToggle(false);
  const [targetCountryside, setTargetCountryside] = useState<TargetCountryside>(initTarget);
  const [targetSilobag, setTargetSilobag] = useState<TargetSilobag>(initTarget);

  const CHANGE_TARGET = {
    countryside: (i: number) => {
      if (i < 0 || i > fullData!.countrysides.length - 1) return;

      setTargetSilobag({
        ...fullData!.countrysides[i].silobags[0],
        IDX: 0,
      });
      setTargetCountryside(() => {
        if (!fullData) return initTarget;
        return {
          ...fullData.countrysides[i],
          IDX: i,
        };
      });
    },
    silobag: (i: number) => {
      const { silobags } = targetCountryside;
      if (!silobags) return;

      const isNewTargetSilobagInRange = i >= 0 && i <= silobags.length - 1;
      if (!isNewTargetSilobagInRange) return;

      const newTargetSilobag = silobags[i];

      const pos = newTargetSilobag.marker.getPosition();
      if (pos) map?.panTo(pos);

      navigate(`/map/${newTargetSilobag.id}`);

      if (targetSilobag.IDX !== i && targetSilobag.marker) {
        // Increase the size of the new target silobag marker
        const marker = newTargetSilobag.marker;
        const icon = marker.getIcon();

        // This condition in the following IF is a TypeScript check (it doesn't change the behavior)
        if (icon && typeof icon !== 'string' && 'url' in icon) {
          marker.setIcon({
            url: icon.url,
            scaledSize: new window.google.maps.Size(107, 107),
          });
          marker.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1);

          // Decrease the size of the current target silobag marker
          targetSilobag.marker.setIcon({
            url: icon.url,
            scaledSize: new window.google.maps.Size(80, 80),
          });
          targetSilobag.marker.setZIndex(); // reset z-index
        }
      }

      // This update assumes that:
      // 1. Always the targetCountryside is set before the targetSilobag
      // 2. The targetSilobag is always belonging to the targetCountryside
      setTargetSilobag(() => {
        if (!fullData) return initTarget;
        return {
          ...targetCountryside.silobags![i],
          IDX: i,
        };
      });
    },
  };

  const SILOBAG_INDEX_MOVE = {
    PREV: () => CHANGE_TARGET.silobag(targetSilobag.IDX - 1),
    NEXT: () => CHANGE_TARGET.silobag(targetSilobag.IDX + 1),
  };

  const NAVIGATION = {
    fromCountryside: (i: number) => ({
      toDetail: () => {
        const newCountrysideTarget = fullData!.countrysides[i];

        cluster?.clearMarkers();
        newCountrysideTarget.silobags.forEach((s) => s.marker.setVisible(true));
        map?.fitBounds(newCountrysideTarget.silobagsBounds, { left: 400, top: 150 });

        // Increase the size of the first silobag marker
        const marker = newCountrysideTarget.silobags[0].marker;
        const icon = marker.getIcon();

        // This condition in the following IF is a TypeScript check (it doesn't change the behavior)
        if (icon && typeof icon !== 'string' && 'url' in icon) {
          marker.setIcon({
            url: icon.url,
            scaledSize: new window.google.maps.Size(107, 107),
          });
          marker.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1);
        }

        navigate(`/map/${newCountrysideTarget.silobags[0].id}`);

        // Update the target countryside to the new one
        CHANGE_TARGET.countryside(i);

        // Update the UI to the detail view
        setUI(`detail-from-${ui as 'normal' | 'extended'}`);
      },
      toExtended: () => {
        CHANGE_TARGET.countryside(i);
        if (ui === 'normal') {
          setUI('extended');
          return;
        }
        if (targetCountryside.IDX === i) setUI('normal');
      },
    }),
    fromSilobag: (i: number) => ({
      toDetail: () => {
        const { silobags, silobagsBounds } = targetCountryside;

        if (!silobags || !silobagsBounds) return;

        // Update the target silobag to the new one
        // It is important to change target before the map fitBounds
        CHANGE_TARGET.silobag(i);

        cluster?.clearMarkers();
        silobags.forEach((s) => s.marker.setVisible(true));
        map?.fitBounds(targetCountryside.silobagsBounds!, { left: 400, top: 150 });

        // Increase the size of the target silobag marker
        const marker = silobags[i].marker;
        const icon = marker.getIcon();

        // This condition in the following IF is a TypeScript check (it doesn't change the behavior)
        if (icon && typeof icon !== 'string' && 'url' in icon) {
          marker.setIcon({
            url: icon.url,
            scaledSize: new window.google.maps.Size(107, 107),
          });
          marker.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1);
        }

        // Update the UI to the detail view
        setUI('detail-from-extended');
      },
    }),
    fromDetailToMenu: () => {
      if (!targetCountryside.silobags) return;

      targetCountryside.silobags.forEach((s) => s.marker.setVisible(false));
      fullData?.countrysides.forEach((c) => {
        cluster?.addMarker(c.marker);
        c.marker.setVisible(true);
      });
      infoWindow?.close();
      map?.fitBounds(
        globalBounds as google.maps.LatLngBounds,
        ui === 'detail-from-normal' ? { left: 400, top: 150 } : { left: 750, top: 150 }
      );

      const marker = targetSilobag.marker;
      if (!marker) return;

      const icon = marker.getIcon();

      // This condition in the following IF is a TypeScript check (it doesn't change the behavior)
      if (icon && typeof icon !== 'string' && 'url' in icon) {
        marker.setIcon({
          url: icon.url,
          scaledSize: new window.google.maps.Size(80, 80),
        });
        marker.setZIndex();
      }

      navigate('/map');
      setUI((prev) => (prev === 'detail-from-normal' ? 'normal' : 'extended'));
    },
  };

  const FILTER = (filters: FiltersParams) => {
    if (!initFullData) return;

    const filteredIDs: (number | string)[] = [];
    let filteredRemainingStock = 0;

    for (const c of initFullData.countrysides) {
      let hasSilobagsFiltered = false;

      for (const s of c.silobags) {
        const { species, product, harvest, lot } = filters;

        const isSpecies = species ? s.silobag_species_types.id === species : true;
        const isProduct = product ? s.silobag_product_types.id === product : true;
        const isHarvest = harvest ? s.silobag_harvests?.name === harvest : true;
        const isLot = lot ? s.lots.id === lot : true;

        const passesFilterSelection = isSpecies && isProduct && isHarvest && isLot;

        if (!passesFilterSelection) continue;

        hasSilobagsFiltered = true;
        filteredRemainingStock += parseInt(s.remaining_stock ?? '0', 10);
        filteredIDs.push(s.id);
      }

      if (!hasSilobagsFiltered) continue;
      filteredIDs.push(c.id);
    }

    const newCountrysides: CountrysideWithMarkerAndBounds[] = [];

    for (const countryside of initFullData.countrysides) {
      if (!filteredIDs.includes(countryside.id)) {
        cluster?.removeMarker(countryside.marker);
        for (const silobag of countryside.silobags) silobag.marker.setVisible(false);
        continue;
      }

      const newSilobags = [];

      for (const silobag of countryside.silobags) {
        if (!filteredIDs.includes(silobag.id)) {
          silobag.marker.setVisible(false);
          continue;
        }
        //silobag.marker.setVisible(true);
        // TODO: acá probable mente haya que hacer chiquito el marker que actualmente está grande
        newSilobags.push(silobag);
      }

      const LABEL_TEXT_SIZE_BY_LENGTH: { [key: number]: string } = {
        1: '22px',
        2: '20px',
        3: '17px',
        4: '13px',
        5: '10px',
        6: '8px',
      };

      const setCountrysideLabel = (text: string) => ({
        text,
        color: 'white',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: '600',
        fontSize: LABEL_TEXT_SIZE_BY_LENGTH[text.length] ?? '6px',
      });

      const newLabel = setCountrysideLabel(newSilobags.length.toString());
      countryside.marker.setLabel(newLabel);

      newCountrysides.push({
        ...countryside,
        silobags: newSilobags,
      });
    }

    // Set new full data
    setFullData({
      ...initFullData,
      filteredRemainingStock: Math.round(filteredRemainingStock / 1000),
      countrysides: newCountrysides,
      rowCount: -Infinity,
    });

    // If target countryside was filtered, update to initial state
    if (!filteredIDs.includes(targetCountryside.id!)) {
      if (targetSilobag.marker) {
        const { marker } = targetSilobag;
        const icon = marker.getIcon();

        // This condition in the following IF is a TypeScript check (it doesn't change the behavior)
        if (icon && typeof icon !== 'string' && 'url' in icon) {
          marker.setIcon({
            url: icon.url,
            scaledSize: new window.google.maps.Size(80, 80),
          });
          marker.setZIndex();
        }
      }

      setTargetCountryside(initTarget);
      setTargetSilobag(initTarget);

      cluster?.addMarkers(newCountrysides.map((c) => c.marker));
      setUI('normal');
      navigate('/map');
      map?.fitBounds(globalBounds as google.maps.LatLngBounds, { left: 400, top: 150 });
      return;
    }

    // Update new target countryside index
    const newTargetCountrysideIDX = newCountrysides.findIndex((c) => c.id === targetCountryside.id);
    setTargetCountryside({
      ...newCountrysides[newTargetCountrysideIDX],
      IDX: newTargetCountrysideIDX,
    });

    // Update countryside PINS
    if (ui === 'normal' || ui === 'extended') {
      cluster?.addMarkers(newCountrysides.map((c) => c.marker));
      return;
    }

    // If target silobag was filtered, update target silobag to the first silobag of target countryside
    if (!filteredIDs.includes(targetSilobag.id!)) {
      setTargetSilobag((prevTargetSilobag) => {
        const marker = newCountrysides[newTargetCountrysideIDX].silobags[0].marker;
        const icon = marker.getIcon();

        // This condition in the following IF is a TypeScript check (it doesn't change the behavior)
        if (icon && typeof icon !== 'string' && 'url' in icon) {
          marker.setIcon({
            url: icon.url,
            scaledSize: new window.google.maps.Size(107, 107),
          });
          marker.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1);
        }

        if (prevTargetSilobag.marker) {
          const prevIcon = prevTargetSilobag.marker.getIcon();

          // This condition in the following IF is a TypeScript check (it doesn't change the behavior)
          if (prevIcon && typeof prevIcon !== 'string' && 'url' in prevIcon) {
            prevTargetSilobag.marker.setIcon({
              url: prevIcon.url,
              scaledSize: new window.google.maps.Size(80, 80),
            });
            prevTargetSilobag.marker.setZIndex();
          }
        }

        navigate(`/map/${newCountrysides[newTargetCountrysideIDX].silobags[0].id}`);
        map?.panTo(newCountrysides[newTargetCountrysideIDX].silobags[0].marker.getPosition()!);

        return {
          ...newCountrysides[newTargetCountrysideIDX].silobags[0],
          IDX: 0,
        };
      });
      newCountrysides[newTargetCountrysideIDX].silobags.forEach((s) => s.marker.setVisible(true));
      return;
    }

    // Update new target silobag index
    const newTargetSilobagIDX = newCountrysides[newTargetCountrysideIDX].silobags.findIndex(
      (s) => s.id === targetSilobag.id
    );
    setTargetSilobag({
      ...newCountrysides[newTargetCountrysideIDX].silobags[newTargetSilobagIDX],
      IDX: newTargetSilobagIDX,
    });

    // Update silobags PINS
    if (ui.includes('detail')) {
      newCountrysides[newTargetCountrysideIDX].silobags.forEach((s) => s.marker.setVisible(true));
      return;
    }
  };

  const handleOnSilobagPinClick = (countrysideIDX: number, silobagIDX: number) => {
    if (!fullData) return;

    const targetCountryside = fullData.countrysides[countrysideIDX];
    const newTargetSilobag = targetCountryside.silobags[silobagIDX];

    const pos = newTargetSilobag.marker.getPosition();
    if (pos) map?.panTo(pos);

    setTargetSilobag((prevTargetSilobag) => {
      if (prevTargetSilobag.IDX !== silobagIDX) {
        // Increase the size of the new target silobag marker
        const marker = newTargetSilobag.marker;
        const icon = marker.getIcon();

        // This condition in the following IF is a TypeScript check (it doesn't change the behavior)
        if (icon && typeof icon !== 'string' && 'url' in icon) {
          marker.setIcon({
            url: icon.url,
            scaledSize: new window.google.maps.Size(107, 107),
          });
          marker.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1);
        }

        // Decrease the size of the current target silobag marker
        if (prevTargetSilobag.marker) {
          const prevIcon = prevTargetSilobag.marker.getIcon();

          if (prevIcon && typeof prevIcon !== 'string' && 'url' in prevIcon) {
            prevTargetSilobag.marker.setIcon({
              url: prevIcon.url,
              scaledSize: new window.google.maps.Size(80, 80),
            });
            prevTargetSilobag.marker.setZIndex(); // reset z-index
          }
        }
      }

      return {
        ...targetCountryside.silobags[silobagIDX],
        IDX: silobagIDX,
      };
    });
  };

  const handleInitMapOnDetail = (ci: number, si: number) => {
    const initCountryside = fullData?.countrysides[ci];
    const initSilobag = fullData?.countrysides[ci].silobags[si];

    if (!initCountryside || !initSilobag) return;

    setTargetCountryside({
      ...initCountryside,
      IDX: ci,
    });
    setTargetSilobag({
      ...initSilobag,
      IDX: si,
    });

    setUI('detail-from-normal');

    map?.fitBounds(initCountryside.silobagsBounds, { left: 400, top: 150 });
    const pos = initSilobag.marker.getPosition();
    if (pos) map?.panTo(pos);

    // Increase the size of the target silobag marker
    const marker = initSilobag.marker;
    const icon = marker.getIcon();

    if (icon && typeof icon !== 'string' && 'url' in icon) {
      marker.setIcon({
        url: icon.url,
        scaledSize: new window.google.maps.Size(107, 107),
      });
      marker.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1);
    }
  };

  const [initFullData, setInitFullData] = useState<SummaryWithMarkers | null>(null);
  useEffectOnUpdate(() => setFullData(initFullData), [initFullData]);

  const [lotsOptions, setLotsOptions] = useState<LotSummary[]>([]);
  useEffectOnUpdate(() => {
    if (ui.includes('detail')) {
      setLotsOptions(
        targetCountryside
          .silobags!.map((s) => s.lots)
          // remove duplicated lots
          .filter((lot, index, self) => index === self.findIndex((lot2) => lot2.id === lot.id))
      );
    }
  }, [fullData, ui]);

  return (
    <MapContext.Provider
      value={{
        map,
        setMap,
        fullData,
        setFullData,
        initFullData,
        setInitFullData,
        cluster,
        setCluster,
        infoWindow,
        setInfoWindow,
        globalBounds,
        setGlobalBounds,
        ui,
        setUI,
        hideUI,
        lotsOptions,
        toggleHideUI,
        targetCountryside,
        targetSilobag,
        MAP_UTILS: {
          CHANGE_TARGET,
          NAVIGATION,
          SILOBAG_INDEX_MOVE,
          FILTER,
          HANDLERS: {
            onSilobagPinClick: handleOnSilobagPinClick,
            initOnDetail: handleInitMapOnDetail,
          },
        },
      }}
    >
      {children}
    </MapContext.Provider>
  );
}
