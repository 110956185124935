import { Stack } from '@mui/material';
import { Filters } from './map-filters';
import { Menu } from './map-menu';
import { useMap } from '../use-map';

export function MapUI() {
  const { map, hideUI } = useMap();

  return (
    <Stack
      direction="row"
      ref={(node) => {
        const mapControls = map?.controls[google.maps.ControlPosition.TOP_LEFT];
        const mapControlsArray = mapControls?.getArray();

        if (node && !mapControlsArray?.includes(node))
          map?.controls[google.maps.ControlPosition.TOP_LEFT].push(node);
      }}
      style={{ pointerEvents: 'none', display: hideUI ? 'none' : '' }}
    >
      <Menu />
      <Filters />
    </Stack>
  );
}
