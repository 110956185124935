import { BaseTable } from '@ui/table';
//import { ProTable } from '@ui/table/tablePro';
import {
  GridColDef,
  GridRenderCellParams,
  useGridSelector,
  useGridApiContext,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  DataGridProProps,
} from '@mui/x-data-grid-pro';
import ChevronLeftIcon from '@assets/svg/chevron-left.svg';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Stack,
  Grid,
  CircularProgress,
  Checkbox,
  Chip,
  Tooltip,
} from '@mui/material';
import i18n from '../../libs/language';
import {
  configButtonStylesContained,
  boxCircularProgressBarStyle,
  circularProgressBar,
} from '@styles/generic-styles';
import { userNotificationSettingsService } from '@services/domain/user/notification/notification-setting-list';
import { updateNotificationSettingsService } from '@services/domain/user/notification/notification-setting-edition';
import { useService } from '@hooks/use-service';
import ChevronDown from '@assets/svg/chevron-down.svg';
import { notificationsLabels, notificationsLabelsPt } from '@features/notification/notifications-labels';
import React, { useState, useEffect } from 'react';
import { ToastAction } from '@ui/toast/toast-action';
import helpIcon from '@assets/svg/help-icon-blue.svg';
import { useGlobalStore } from '@global-store/use-store';
import { useEffectOnUpdate } from '@hooks/core';
import { VerificationPhoneModal } from '@features/verification-phone/verification-phone-modal';
import { WarningVerificationPhone } from '@features/verification-phone/warning-verification-phone';

type UpdateCredentialNotification = Parameters<typeof updateNotificationSettingsService>[0]['body'];
type SummaryCredentialNotifications = Awaited<
  ReturnType<typeof userNotificationSettingsService>
>['summary']['alert_notification_recipients'];

type EventType = {
  SILOID: string;
  SILOSAT: string;
  SILOMETRIX: string;
};

type CredentialNotification = SummaryCredentialNotifications[0];

function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);
  const hasDetail = React.isValidElement(contentCache[id]);

  return (
    <Button
      endIcon={<img src={ChevronDown} />}
      size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? 'Close' : 'Open'}
      sx={{ color: '#22A7F0', textTransform: 'none', fontSize: 13.5 }}
    ></Button>
  );
}

//componente page notificactions
export const NotificactionPage = () => {
  const navigate = useNavigate();
  const { userID } = useParams();
  const numericUserID = parseInt(userID ?? '0', 10);
  //servicio get Notifications
  const {
    trigger: getNotifications,
    summary,
    isLoading,
    isSuccess: isGetNotificationsSuccess,
  } = useService(userNotificationSettingsService, {
    fetchOnMount: [{ query: numericUserID }],
  });
  //update notifications
  const [notificationData, setNotificationData] = useState<UpdateCredentialNotification>([]);
  const { preferedLanguageId, userID: userLoggedId } = useGlobalStore();
  const [openModal, setOpenModal] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [successValidation, setSuccessValidation] = useState(false);
  const [errorValidation, setErrorValidation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(i18n.t('app.notification_page.confirm_error'));
  const { trigger: updateNotificationRecipients, isSuccess } = useService(updateNotificationSettingsService, {
    onSuccess: () => {
      getNotifications({ query: numericUserID });
      setNotificationData([]);
    },
  });

  const dataWithoutFilter = summary?.alert_notification_recipients;
  const phoneVerification = summary?.phone_verification_at;
  //Filtrado de array con metric_id para solo eventos de SILOID
  const siloID = dataWithoutFilter?.filter(
    (item: CredentialNotification) => item.metric_id === 7 || item.metric_id === 8
  );
  //Filtrado de array con metric_id para solo eventos de SILOSAT
  const siloSat = dataWithoutFilter?.filter((item: CredentialNotification) => item.metric_id === 9);
  //Filtrado de array con metric_id para solo eventos de SILOMETRIX
  const siloMetrix = dataWithoutFilter?.filter((item: CredentialNotification) =>
    [3, 4, 5, 6].includes(item.metric_id)
  );
  //Array de objetos con los datos filtrados y ordenado para consumir de manera mas sencilla
  const rows = [
    {
      id: 1,
      name: 'SILOID',
      data: siloID ?? [],
    },
    {
      id: 2,
      name: 'SILOSAT',
      data: siloSat ?? [],
    },
    {
      id: 3,
      name: 'SILOMETRIX',
      data: siloMetrix ?? [],
    },
  ];
  //Logica para actualizar las notificaciones individualmente
  const handleSwitchChange = (id: number, field: keyof CredentialNotification, value: boolean) => {
    setNotificationData((prevNotificationData) => {
      const existingNotificationIndex = prevNotificationData.findIndex(
        (notification) => notification.id === id
      );
      if (existingNotificationIndex !== -1) {
        const updatedNotificationData = [...prevNotificationData];
        updatedNotificationData[existingNotificationIndex] = {
          ...updatedNotificationData[existingNotificationIndex],
          [field]: value,
        };
        return updatedNotificationData;
      } else {
        return [...prevNotificationData, { id, [field]: value }];
      }
    });
  };
  //Actualizar todas las notificaciones de un evento o de una fila/columna
  const handleActiveAllRowCheckbox = (
    items: CredentialNotification[],
    value: boolean,
    field?: keyof CredentialNotification
  ) => {
    setNotificationData((prevNotificationData) => {
      let updatedNotificationData = [...prevNotificationData];

      items.forEach((item) => {
        const existingNotificationIndex = updatedNotificationData.findIndex(
          (notification) => notification.id === item.id
        );

        if (existingNotificationIndex !== -1) {
          updatedNotificationData[existingNotificationIndex] = {
            ...updatedNotificationData[existingNotificationIndex],
            ...(field ? { [field]: !value } : { email: !value, sms: !value, whatsapp: !value }),
          };
        } else {
          updatedNotificationData = [
            ...updatedNotificationData,
            field
              ? { id: item.id, [field]: !value }
              : { id: item.id, email: !value, sms: !value, whatsapp: !value },
          ];
        }
      });

      updateNotificationRecipients({ body: updatedNotificationData }); // Llamar a la función para enviar los datos actualizados

      return updatedNotificationData;
    });
  };

  useEffectOnUpdate(() => {
    getNotifications({ query: numericUserID });
  }, [preferedLanguageId, successValidation]);

  const sendUpdateNotificationData = () => {
    updateNotificationRecipients({ body: notificationData });
  };

  useEffect(() => {
    if (isGetNotificationsSuccess && !isSuccess && phoneVerification == null) {
      setOpenWarning(true);
    }
  }, [isGetNotificationsSuccess]);

  //columns table notifications
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: i18n.t('app.notification_page.origin'),
      flex: 2.5,
      sortable: false,
      align: 'left',
      renderCell: (params) => {
        const value = params.value;
        const firstFourCharacters = value.substring(0, 4);
        const restOfTheText = value.substring(4);
        const id = params.row.id;
        const eventType: EventType = {
          SILOID: i18n.t('app.notification_page.silo_id_tooltip'),
          SILOSAT: i18n.t('app.notification_page.silo_sat_tooltip'),
          SILOMETRIX: i18n.t('app.notification_page.silo_metrix_tooltip'),
        };
        const textTooltip = eventType[params.value as keyof EventType];
        return (
          <Stack direction={'row'} width={{ xs: 200 }}>
            <Typography fontSize={13.5} color={id === 1 ? 'primary' : id === 2 ? '#22A7F0' : 'orange'}>
              {' '}
              <span style={{ fontWeight: 300 }}>{firstFourCharacters}</span>
              <span style={{ fontWeight: 600 }}>{restOfTheText}</span>
            </Typography>
            <Tooltip title={textTooltip} placement="top" arrow sx={{ display: { xs: 'none', md: 'block' } }}>
              <Typography fontSize={13.5} color={'#22A7F0'} style={{ marginLeft: '10px' }}>
                <img src={helpIcon} style={{ width: 15, height: 15 }} />
              </Typography>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: 'description',
      headerName: '',
      flex: 4,
      sortable: false,
      align: 'center',
    },
    {
      field: 'email',
      sortable: false,
      headerName: 'Email',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      renderCell: (params) => {
        const data = params.row.data;
        const allEmailsValue = data.every((item: CredentialNotification) => item.email);
        return (
          <Checkbox
            defaultChecked={allEmailsValue}
            onChange={() => handleActiveAllRowCheckbox(params.row.data, allEmailsValue, 'email')}
          />
        );
      },
    },
    {
      field: 'sms',
      sortable: false,
      headerName: 'SMS',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const data = params.row.data;
        const allSmsValue = data.every((item: CredentialNotification) => item.sms);
        return (
          <Checkbox
            defaultChecked={allSmsValue}
            onChange={() => handleActiveAllRowCheckbox(params.row.data, allSmsValue, 'sms')}
          />
        );
      },
    },
    {
      field: 'WhatsApp',
      sortable: false,
      headerName: 'WhatsApp',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const data = params.row.data;
        const allWhatsappValue = data.every((item: CredentialNotification) => item.whatsapp);
        return (
          <Checkbox
            defaultChecked={allWhatsappValue}
            onChange={() => handleActiveAllRowCheckbox(params.row.data, allWhatsappValue, 'whatsapp')}
          />
        );
      },
    },
    {
      field: 'activeAll',
      sortable: false,
      headerName: '',
      flex: 1,
      align: 'center',
      renderCell: (params) => {
        const data = params.row.data;
        const allEmailsValue = data.every((item: CredentialNotification) => item.email);
        const allWhatsappValue = data.every((item: CredentialNotification) => item.whatsapp);
        const allSmsValue = data.every((item: CredentialNotification) => item.sms);

        const allValuesAreTrue = allEmailsValue && allWhatsappValue && allSmsValue;
        return (
          <Button
            size="small"
            tabIndex={-1}
            sx={{ color: '#22A7F0', textTransform: 'none', fontSize: 13.5, fontWeight: 600 }}
            onClick={() => handleActiveAllRowCheckbox(params.row.data, allValuesAreTrue)}
          >
            {allValuesAreTrue
              ? i18n.t('app.notification_page.desactive_all')
              : i18n.t('app.notification_page.active_all')}
          </Button>
        );
      },
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: CustomDetailPanelToggle,
      flex: 1,
    },
  ];
  //detalle de las rows
  const getDetailPanelContent = React.useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
    ({ row }) => (
      <Stack>
        {row.data.map((item: CredentialNotification) => {
          const labels = preferedLanguageId == 'es' ? notificationsLabels : notificationsLabelsPt;
          const notification = labels.find((label) => label.id === item.metric_id);

          const event = {
            security: {
              label: i18n.t('app.notification_page.security'),
              bgColor: '#F3C1FC',
              color: '#BC3BD3',
            },
            technical: {
              label: i18n.t('app.notification_page.tecnical'),
              bgColor: '#FADCC0',
              color: 'orange',
            },
            quality: {
              label: i18n.t('app.notification_page.quality'),
              bgColor: '#D8ECFC',
              color: '#2898F4',
            },
          };

          const labelDisplay = notification && event[item?.metrics?.alert_class?.description]?.label;
          const bgColorDisplay = notification && event[item?.metrics?.alert_class?.description]?.bgColor;
          const colorDisplay = notification && event[item?.metrics?.alert_class?.description]?.color;

          const allItemsAreTrue = item.email && item.sms && item.whatsapp;

          return (
            <Stack
              direction={'row'}
              key={item.id}
              style={{ padding: '10px 0px', borderBottom: '1px solid #E5E5E5' }}
              alignItems={'center'}
            >
              <Typography style={{ flex: 2.5 }} fontSize={13} fontWeight={600} paddingLeft={'5px'}>
                {notification ? notification.name : item.metric_id}
                <Chip
                  label={labelDisplay}
                  style={{
                    backgroundColor: bgColorDisplay,
                    fontSize: '9px',
                    fontWeight: 'bold',
                    padding: '2px',
                    height: '20px',
                    width: '90px',
                    marginLeft: '10px',
                    color: colorDisplay,
                  }}
                />
              </Typography>
              <Typography
                style={{ flex: 4, textAlign: 'left' }}
                sx={{ display: { xs: 'none', md: 'block' } }}
                fontSize={11}
              >
                {notification ? notification.description : null}
              </Typography>
              <Typography sx={{ flex: 1, textAlign: { xs: 'start', md: 'center' } }} fontSize={11}>
                <Checkbox
                  key={item.id}
                  defaultChecked={item.email}
                  onChange={(e) => handleSwitchChange(item?.id, 'email', e.target.checked)}
                />
              </Typography>
              <Typography sx={{ flex: 1, textAlign: { xs: 'start', md: 'center' } }} fontSize={11}>
                <Checkbox
                  defaultChecked={item.sms}
                  onChange={(e) => handleSwitchChange(item?.id, 'sms', e.target.checked)}
                />
              </Typography>
              <Typography sx={{ flex: 1, textAlign: { xs: 'start', md: 'center' } }} fontSize={11}>
                <Checkbox
                  defaultChecked={item.whatsapp}
                  onChange={(e) => handleSwitchChange(item?.id, 'whatsapp', e.target.checked)}
                />
              </Typography>
              <Typography style={{ flex: 1, textAlign: 'center' }}>
                <Button
                  size="small"
                  tabIndex={-1}
                  sx={{ color: '#22A7F0', textTransform: 'none', fontSize: 13.5, fontWeight: 600 }}
                  onClick={() => handleActiveAllRowCheckbox([item], allItemsAreTrue!)}
                >
                  {allItemsAreTrue
                    ? i18n.t('app.notification_page.desactive_all')
                    : i18n.t('app.notification_page.active_all')}
                </Button>
              </Typography>
              <Typography style={{ flex: 1, textAlign: 'center' }}></Typography>
            </Stack>
          );
        })}
      </Stack>
    ),
    [preferedLanguageId]
  );

  return (
    <Box
      style={{
        width: '98%',
        margin: 'auto',
        paddingTop: '10px',
      }}
    >
      <Button
        startIcon={<img src={ChevronLeftIcon} style={{ width: 10, height: 10 }} />}
        style={{ color: '#22A7F0' }}
        onClick={() => navigate(-1)}
      >
        {i18n.t('app.return')}
      </Button>
      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant="h4" style={{ marginTop: '20px', fontSize: '25px', fontWeight: 600 }}>
          {i18n.t('app.notification_page.custom_notifications')}
        </Typography>
        <Stack direction={'row'} spacing={2}>
          {userLoggedId == numericUserID && (
            <Button
              variant="contained"
              sx={{ ...configButtonStylesContained }}
              onClick={() => setOpenModal(true)}
              disabled={phoneVerification ? true : false}
            >
              {i18n.t('app.notification_page.button_validate_phone')}
            </Button>
          )}
          <Button
            variant="contained"
            sx={{ ...configButtonStylesContained }}
            onClick={sendUpdateNotificationData}
          >
            {i18n.t('app.save')}
          </Button>
        </Stack>
      </Stack>
      <Grid
        container
        spacing={2}
        style={{ marginTop: '20px', backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}
      >
        {isLoading ? (
          <Box sx={boxCircularProgressBarStyle}>
            <CircularProgress sx={circularProgressBar} size={50} />
          </Box>
        ) : (
          <Grid item xs={12}>
            <Typography variant="h5" style={{ fontSize: '18px', fontWeight: 600 }}>
              {i18n.t('app.notification_page.username')}{' '}
              <span style={{ fontSize: '14px', fontWeight: 300, marginLeft: '10px' }}>
                {summary?.username}
              </span>
            </Typography>
            <Box>
              <BaseTable
                rows={rows ?? []}
                columns={columns}
                initialPageSize={10}
                loading={isLoading}
                getDetailPanelContent={getDetailPanelContent}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      {isSuccess && (
        <ToastAction message={i18n.t('app.notification_page.toast_success')} severity="success" />
      )}
      {openModal && (
        <VerificationPhoneModal
          open={openModal}
          setOpen={setOpenModal}
          userID={numericUserID}
          setSuccess={setSuccessValidation}
          setError={setErrorValidation}
          setErrorMessage={setErrorMessage}
        />
      )}
      {openWarning && <WarningVerificationPhone open={openWarning} setOpen={setOpenWarning} />}
      {successValidation && (
        <ToastAction severity="success" message={i18n.t('app.notification_page.confirm_success')} />
      )}
      {errorValidation && <ToastAction severity="error" message={errorMessage} />}
    </Box>
  );
};
