import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { ListSiloSatPayload, ListSiloSatResponse } from './list-silosat.types';

export async function listSiloSatService({ query: rawQuery }: ListSiloSatPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<ListSiloSatResponse>(ENDPOINT.SILOBAGS_SAT_VIEW, { query });

  return {
    response,
    summary: data,
  };
}
