import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { ConfirmPhonePayload } from './confirm-phone.type';

export async function confirmPhoneService({ body }: ConfirmPhonePayload) {
  const { response, data } = await BACKEND.post(ENDPOINT.CONFIRM_PHONE, { body })

  return {
    response,
    summary: data,
  };
}