import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { VerifyPhoneResponse } from './verify-phone.type';

export async function verifyPhoneService() {
  const { response, data } = await BACKEND.post<VerifyPhoneResponse>(ENDPOINT.VERIFY_PHONE)

  return {
    response,
    summary: data,
  };
}