import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';


import { SendInsurancePayload, SendedLaSegundaResponse } from './la-segunda-confirmation.types';

// deprecated service ref.: postLaSegundaInsurance_DEPRECATED
export async function confirmLaSegundaService({ body }: SendInsurancePayload) {
  const { response, data } = await BACKEND.post<SendedLaSegundaResponse>(ENDPOINT.INSURANCE_LASEGUNDA, {
    body,
  });

  return {
    response,
    summary: data,
  };
}
