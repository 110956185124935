import { Fragment, PropsWithChildren, useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  Modal,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import SiloIDExtendedLogo from '@assets/svg/siloreal/product-silo-id-extended.svg';
import SiloMetrixLogo from '@assets/svg/siloreal/product-silo-metrix-extended.svg';
import SiloSatLogo from '@assets/svg/siloreal/product-silo-sat-extended.svg';

import CountrysideInsignia from '@assets/svg/domain/map/countryside-insignia.svg';
import BlueEye from '@assets/svg/core/eye-blue.svg';
import CopyIcon from '@assets/svg/core/clipboard-blue.svg';
import DisabledPhotoGalleryIcon from '@assets/svg/core/picture-gray.svg';
import PhotoGalleryIcon from '@assets/svg/core/picture-blue.svg';
import AlertsIcon from '@assets/svg/core/bell-red.svg';
import DisabledAlertsIcon from '@assets/svg/core/bell-gray.svg';
import ChevronRight from '@assets/svg/core/chevron-right-blue.svg';
import ChevronLeft from '@assets/svg/core/chevron-left-blue.svg';
import EyeIcon from '@assets/svg/core/eye-green.svg';
import RedEyeIcon from '@assets/svg/core/eye-red.svg';
import discardedIcon from '@assets/svg/discarded.svg';
import NewReleaseIcon from '@assets/svg/SiloBagPage/new_releases.svg';
import warningIcon from '@assets/svg/warning.svg';
import { useMap } from '../use-map';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { CarouselModalPhotoGallery } from '@ui/carousel/carousel';
import { useToggle } from '@hooks/core';
import { IMAGES_STATUS } from '@data/to-refactor/constants';
import i18n from '../../../libs/language';
import { SPECIES_IMG_BY_ID } from '@data/species';
import { DrawerMapAlertCard } from '../map-drawer';
import { ToastAction } from '@ui/toast/toast-action';
import { useService } from '@hooks/use-service';
import { formatDate } from '@utils/helper';

import { MyModal } from '@features/silobags/silobag-detail/section-data/data-detail';
import { ModalContent } from '@features/silobags/silobag-detail/section-data/edit-silobag-modal';

import { updateSilobagService } from '@services/domain/base/silobag/base/silobag-edition';
import { SilobagSilosat } from '@features/silobags/silobag-detail/section-satellite/silobag-silosat';
import { listSiloSatService } from '@services/domain/silosat/list-silosat';

// TODO: check how to unify this type with the one in the data-detail.tsx and silobag-history.tsx file
type imagesProps = {
  url: string;
  label: string;
  init_azimuth?: string | null;
  init_timestamp?: string | null;
  init_lat?: string | null;
  init_lon?: string | null;
  init_accuracy?: string | null;
  main_azimuth?: string | null;
  main_timestamp?: string | null;
  main_lat?: string | null;
  main_lon?: string | null;
  main_accuracy?: string | null;
  end_azimuth?: string | null;
  end_timestamp?: string | null;
  end_lat?: string | null;
  end_lon?: string | null;
  end_accuracy?: string | null;
};

// type MenuProps = {
//   setOpenModal: (value: boolean) => void;
//   // setSilobagData: (data: TargetSilobag) => void;
// };

export function Menu() {
  const { fullData, ui, setUI, targetCountryside, targetSilobag, MAP_UTILS } = useMap();
  const navigate = useNavigate();

  const [nameOverflowing, setNameOverflowing] = useState('');
  const [openDrawer, setOpenDrawer] = useToggle(false);

  const [ubicationCopied, setUbicationCopied] = useToggle(false);

  const [openPhotoModal, setOpenPhotoModal] = useToggle(false);
  const [imagesForPhotoModal, setImagesForPhotoModal] = useState<imagesProps[]>([]);
  const [openModalSat, setOpenModalSat] = useToggle(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const handleClosePhotoModal = () => setOpenPhotoModal(false);

  const SCROLL = {
    toLeft: MAP_UTILS.SILOBAG_INDEX_MOVE.PREV,
    toRight: MAP_UTILS.SILOBAG_INDEX_MOVE.NEXT,
  };

  const speciesIconStyles = { width: 24, height: 24 };
  const showMainMenu = ui === 'normal' || ui === 'extended';
  const showSilobagMenu = ui === 'extended';
  const showDetailMenu = ui === 'detail-from-normal' || ui === 'detail-from-extended';

  const hasAvailableImages = targetSilobag.init_image || targetSilobag.main_image || targetSilobag.end_image;

  const numerator = fullData?.filteredRemainingStock ?? 0;
  const denominator = fullData?.totalRemainingStock ?? 1;
  const percentageOverTotal = (numerator / denominator) * 100;

  const percentageOverTotalDisplay = Number.isNaN(percentageOverTotal)
    ? '0 %'
    : Number.isInteger(percentageOverTotal)
    ? `${percentageOverTotal} %`
    : percentageOverTotal.toFixed(1) + ' %';

  const selectedSilobagNode = useRef<HTMLButtonElement>();
  useEffect(() => selectedSilobagNode.current?.focus(), [ui, openPhotoModal /*, openSiloSatModal */]); // TODO: AGREGAR BOOLEANO DEL MODAL DE SILO SAT ACÁ

  useEffect(() => {
    setTimeout(() => {
      setUbicationCopied(false);
    }, 3000);
  }, [ubicationCopied]);

  const targetSilobagID = targetSilobag.id ?? 'id-not-found';

  const {
    trigger: editSilobag, //
  } = useService(updateSilobagService, { onSuccess: () => setOpenModalSat(false) });

  const {
    bagging_date,
    bagging_date_check,
    bagging_date_init_image_timestamp,
    bagging_date_end_image_timestamp,
  } = targetSilobag;

  const dateToShow = bagging_date;

  // const handleCloseModal = () => setOpenModal(false);

  const handleUpdateDate = () => {
    if (selectedDate && targetSilobag) {
      const silobagToUpdate = {
        id: targetSilobagID,
        bagging_date: selectedDate,
      };
      editSilobag({ query: targetSilobagID, body: silobagToUpdate });
    }
    setOpenModalSat(false);
  };
  const handleClose = () => {
    setOpenModalSat(false);
  };

  const handleOpenPhotoModal = () => {
    const {
      init_image, //
      init_lat,
      init_lon,
      init_azimuth,
      init_timestamp,
      init_accuracy,
    } = targetSilobag;

    const {
      main_image, //
      main_lat,
      main_lon,
      main_azimuth,
      main_timestamp,
      main_accuracy,
    } = targetSilobag;

    const {
      end_image, //
      end_lat,
      end_lon,
      end_azimuth,
      end_timestamp,
      end_accuracy,
    } = targetSilobag;

    const IMAGE = {
      INIT: {
        url: init_image ?? '',
        label: i18n.t('app.silobag_data.silobag_init'),
        init_lat,
        init_lon,
        init_azimuth,
        init_timestamp,
        init_accuracy,
      },
      MAIN: {
        url: main_image ?? '',
        label: i18n.t('app.silobag_data.silobag_main_photo'),
        main_lat,
        main_lon,
        main_azimuth,
        main_timestamp,
        main_accuracy,
      },
      END: {
        url: end_image ?? '',
        label: i18n.t('app.silobag_data.silobag_end'),
        end_lat,
        end_lon,
        end_azimuth,
        end_timestamp,
        end_accuracy,
      },
    };
    const images = [IMAGE.INIT, IMAGE.MAIN, IMAGE.END].filter((e) => Boolean(e.url));

    const { silobags_actions } = targetSilobag;
    const hasActions = (silobags_actions?.length ?? -1) > 0;
    if (hasActions) {
      silobags_actions?.forEach((action) => {
        const hasImage = action?.image !== null;
        if (!hasImage) return;

        images.push({
          url: action.image,
          label: i18n.t('app.silobag_data.silobag_extra_photo'),
          init_azimuth: action?.azimuth,
          init_timestamp: action?.timestamp,
          init_lat: action?.lat,
          init_lon: action?.lon,
          init_accuracy: action?.accuracy,
        });
      });
    }

    setImagesForPhotoModal(images);
    setOpenPhotoModal(true);
  };

  const speciesName =
    i18n.locale === 'pt' &&
    (targetSilobag.silobag_species_types?.silobag_species_types_translations?.length ?? 0) > 0
      ? targetSilobag.silobag_species_types?.silobag_species_types_translations?.[0].description
      : targetSilobag.silobag_species_types?.name;

  const {
    trigger: triggerListSiloSat, //
    summary: listSiloSatSummary,
    isLoading: isListSiloSatLoading,
  } = useService(listSiloSatService);

  const silosatData = listSiloSatSummary?.response;
  const silosatExistenceStatuses = silosatData?.v_silobags_existence_statuses ?? [];
  // TODO (para emi): recordar mover el modal de silosat a map-menu.tsx en el futuro al lado del CarouselModalPhotoGallery
  const [openSiloSatModal, setOpenSiloSatModal] = useState(false);
  const handleOpenSiloSatModal = () => setOpenSiloSatModal(true);
  const handleCloseSiloSatModal = () => setOpenSiloSatModal(false);

  useEffect(() => {
    if (openSiloSatModal) triggerListSiloSat({ query: targetSilobagID });
  }, [openSiloSatModal]);

  const showBaggingDateAdviceCertified = bagging_date_check != null && bagging_date_check;
  const showBaggingDateAdviceWarning = bagging_date_check != null && !bagging_date_check;

  return (
    <>
      {showMainMenu ? (
        <Paper
          style={{
            pointerEvents: 'auto',
            margin: 18,
            borderRadius: 5,
          }}
        >
          <Stack direction="row" style={{ height: 'min-content', maxHeight: 'calc(80vh - 64px)' }}>
            <Stack style={{ width: 400, margin: 15 }}>
              <Box component="h1" style={{ fontSize: '1.2rem', margin: 0 }}>
                {i18n.t('app.countrysides')}
              </Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                style={{ margin: '8px 0' }}
              >
                <Stack>
                  <Box style={{ fontSize: '0.9rem', color: '#AAA' }}>Siloreales</Box>
                  <Box style={{ fontSize: '1.4rem' }}>
                    {fullData?.countrysides.reduce((rec: number, c) => rec + c.silobags.length, 0)}
                  </Box>
                </Stack>
                <Stack>
                  <Box style={{ fontSize: '0.9rem', color: '#AAA' }}>{i18n.t('app.validated_tons')}</Box>
                  <Box style={{ fontSize: '1.4rem' }}>
                    {i18n.numberToDelimited(fullData?.filteredRemainingStock ?? 0, {
                      delimiter: i18n.t('config.delimiter'),
                    })}
                  </Box>
                </Stack>
                <Stack>
                  <Box style={{ fontSize: '0.82rem', color: '#AAA' }}>
                    {i18n.t('app.silobag_list.your_stock_total')}
                  </Box>
                  <Box style={{ fontSize: '1.4rem' }}>{percentageOverTotalDisplay}</Box>
                </Stack>
              </Stack>
              {(fullData?.web_silobags_count ?? 0) > 0 && (
                <Tooltip title={i18n.t('app.missing_tons')} placement="top-start">
                  <Typography sx={{ opacity: '85%', cursor: 'default' }}>
                    {i18n.t('app.missing_tons_title')}
                  </Typography>
                </Tooltip>
              )}

              <Divider />

              <Stack spacing={1} style={{ overflowY: 'auto' }}>
                {fullData?.countrysides.map((countryside, i) => (
                  <Fragment key={countryside.id}>
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="left"
                      alignItems="start"
                      style={{ lineHeight: 1.4, margin: '12px 9px 12px 0' }}
                    >
                      <img
                        src={CountrysideInsignia}
                        style={{ display: 'inline-block', width: 34, height: 34 }}
                      />
                      <Stack style={{ width: '90%' }}>
                        <Tooltip
                          title={
                            nameOverflowing === countryside.companies.organizations.name
                              ? nameOverflowing
                              : ''
                          }
                          placement="top"
                          leaveTouchDelay={3_000}
                          disableInteractive
                          arrow
                          onMouseEnter={({ target: node }) => {
                            const name = countryside.companies.organizations.name;

                            const isOveflowing =
                              node instanceof HTMLElement && node.scrollWidth > node.clientWidth;
                            if (isOveflowing && nameOverflowing !== name) setNameOverflowing(name);
                          }}
                        >
                          <Box
                            style={{
                              fontSize: '0.73rem',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {countryside.companies.organizations.name}
                          </Box>
                        </Tooltip>
                        <Tooltip
                          title={nameOverflowing === countryside.name ? nameOverflowing : ''}
                          placement="top"
                          leaveTouchDelay={3_000}
                          disableInteractive
                          arrow
                          onMouseEnter={({ target: node }) => {
                            const name = countryside.name;

                            const isOveflowing =
                              node instanceof HTMLElement && node.scrollWidth > node.clientWidth;
                            if (isOveflowing && nameOverflowing !== name) setNameOverflowing(name);
                          }}
                        >
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            style={{ cursor: 'pointer', width: 'max-content', maxWidth: '100%' }}
                            onClick={MAP_UTILS.NAVIGATION.fromCountryside(i).toDetail}
                          >
                            <Box
                              style={{
                                fontSize: '0.85rem',
                                fontWeight: 600,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {countryside.name}
                            </Box>
                            <img src={ChevronRight} style={{ width: 10, height: 10 }} />
                          </Stack>
                        </Tooltip>

                        <Stack direction="row" alignItems="center" spacing={1.5}>
                          <Box style={{ fontSize: '0.66rem' }}>
                            <span style={{ color: '#AAA', marginRight: 3 }}>Silos</span>{' '}
                            {countryside.silobags.length}
                          </Box>
                          <Button
                            sx={{
                              color: '#22A7F0',
                              fontSize: '0.66rem',
                              padding: '0 3px',
                              textTransform: 'none',
                              alignSelf: 'start',
                              background:
                                targetCountryside.IDX === i && ui === 'extended'
                                  ? '#22A7F05A'
                                  : 'transparent',
                              '&:hover': { background: '#22A7F02A' },
                            }}
                            onClick={MAP_UTILS.NAVIGATION.fromCountryside(i).toExtended}
                          >
                            <img src={BlueEye} style={{ width: 11, height: 11, marginRight: 5 }} />
                            <Box>Listado</Box>
                          </Button>
                          <Box style={{ fontSize: '0.66rem', flex: 1, textAlign: 'right' }}>
                            {i18n.numberToDelimited(
                              Math.round(
                                countryside.silobags.reduce(
                                  (rec, s) => rec + parseInt(s.remaining_stock ?? '0', 10),
                                  0
                                ) / 1000
                              ) || 0,
                              { delimiter: i18n.t('config.delimiter') }
                            )}{' '}
                            <span style={{ color: '#AAA', margin: '0 3px' }}>Toneladas</span>
                          </Box>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Divider style={{ margin: 0 }} />
                  </Fragment>
                ))}
              </Stack>
            </Stack>
            {showSilobagMenu ? (
              <>
                <Divider orientation="vertical" style={{ height: 'initial', margin: '0 5px' }} />
                <Stack style={{ width: 380, margin: 15 }}>
                  <Box>
                    <Button
                      sx={{
                        color: '#22A7F0',
                        fontSize: '0.66rem',
                        textTransform: 'none',
                        marginBottom: 1,
                        padding: 0.3,
                        '&:hover': { background: '#22a7f02a' },
                      }}
                      onClick={() => setUI('normal')}
                    >
                      {'< Cerrar'}
                    </Button>
                  </Box>
                  <Box component="h1" style={{ fontSize: '1rem', margin: '8px 0' }}>
                    Siloreales
                  </Box>
                  <Divider />
                  <Stack spacing={1} style={{ overflowY: 'auto' }}>
                    {targetCountryside.silobags?.map((silobag, i) => (
                      <Fragment key={silobag.id}>
                        <Stack
                          direction="row"
                          spacing={1}
                          justifyContent="left"
                          alignItems="start"
                          style={{ lineHeight: 1.5, margin: '8px 12px 8px 0' }}
                        >
                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            style={{
                              width: 34,
                              height: 34,
                              background: '#6BAA0026',
                              padding: 2,
                              borderRadius: 5,
                            }}
                          >
                            {/* TODO: BORRAR "AS 1", SE DEJA DE MOMENTO PARA QUE FUNCIONE SE ESPERA A silobag.silobag_species_types.id ESTÉ BIEN TIPADO */}
                            <img
                              src={SPECIES_IMG_BY_ID[silobag.silobag_species_types.id as 1]}
                              style={speciesIconStyles}
                            />
                          </Stack>
                          <Stack style={{ width: '90%' }}>
                            <Tooltip
                              title={nameOverflowing === silobag.name ? nameOverflowing : ''}
                              placement="top"
                              leaveTouchDelay={3_000}
                              disableInteractive
                              arrow
                              onMouseEnter={({ target: node }) => {
                                const name = silobag.name;

                                const isOveflowing =
                                  node instanceof HTMLElement && node.scrollWidth > node.clientWidth;
                                if (isOveflowing && nameOverflowing !== name) setNameOverflowing(name);
                              }}
                            >
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                style={{ cursor: 'pointer', width: 'max-content', maxWidth: '100%' }}
                                onClick={MAP_UTILS.NAVIGATION.fromSilobag(i).toDetail}
                              >
                                <Box
                                  style={{
                                    fontSize: '0.85rem',
                                    fontWeight: 600,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {silobag.name}
                                </Box>
                                <img src={ChevronRight} style={{ width: 8, height: 8 }} />
                              </Stack>
                            </Tooltip>
                            <Stack direction="row" spacing={1}>
                              <Stack style={{ flex: 1 }}>
                                <Box style={{ fontSize: '0.66rem' }}>
                                  <span style={{ color: '#AAA', marginRight: 3 }}>Especie</span>{' '}
                                  {capitalizeFirstLetter(silobag.silobag_species_types.name)}
                                </Box>
                                <Box style={{ fontSize: '0.66rem' }}>
                                  <span style={{ color: '#AAA', marginRight: 3 }}>Cosecha</span>{' '}
                                  {silobag.silobag_harvests?.name}
                                </Box>
                              </Stack>
                              <Stack>
                                <Box style={{ fontSize: '0.66rem', flex: 1 }}>
                                  <span style={{ color: '#AAA', marginRight: 3 }}>Producto</span>{' '}
                                  {capitalizeFirstLetter(silobag.silobag_product_types.name)}
                                </Box>
                                <Box style={{ fontSize: '0.66rem', flex: 1 }}>
                                  <span style={{ color: '#AAA', marginRight: 3 }}>Stock en campo</span>{' '}
                                  {i18n.numberToDelimited(parseFloat(silobag.remaining_stock ?? '0'), {
                                    delimiter: i18n.t('config.delimiter'),
                                  })}{' '}
                                  Kg
                                </Box>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                        <Divider style={{ margin: 0 }} />
                      </Fragment>
                    ))}
                  </Stack>
                </Stack>
              </>
            ) : null}
          </Stack>
        </Paper>
      ) : null}
      {showDetailMenu ? (
        <Box
          style={{
            width: 380, // (first box sizing try) 400px width + 15px left margin + 15px right margin = 430px
            height: 'min-content',
            maxHeight: 'calc(80vh - 64px)',
            margin: 18,
          }}
          onClick={() => selectedSilobagNode.current?.focus()}
        >
          {ubicationCopied && (
            <ToastAction message={i18n.t('app.map_menu.ubication_copied')} severity="info" />
          )}
          <Stack spacing={2} style={{ width: '100%' }}>
            <Paper style={{ padding: 16, pointerEvents: 'auto' }}>
              <Button
                sx={{
                  color: '#22A7F0',
                  fontSize: '0.66rem',
                  textTransform: 'none',
                  marginBottom: 1,
                  padding: 0.3,
                  '&:hover': { background: '#22a7f02a' },
                }}
                onClick={MAP_UTILS.NAVIGATION.fromDetailToMenu}
              >
                {'< Volver'}
              </Button>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="left"
                alignItems="start"
                style={{ lineHeight: 1.4, margin: 0 }}
              >
                <img src={CountrysideInsignia} style={{ display: 'inline-block', width: 34, height: 34 }} />
                <Stack style={{ width: '100%' }}>
                  <Box style={{ fontSize: '0.7rem' }}>{targetCountryside.companies?.organizations.name}</Box>
                  <Box style={{ fontSize: '0.87rem', fontWeight: 600 }}>{targetCountryside.name}</Box>
                  <Stack direction="row" alignItems="center" spacing={1.5}>
                    <Box style={{ fontSize: '0.66rem' }}>
                      <span style={{ color: '#AAA', marginRight: 3 }}>Silos</span>{' '}
                      {targetCountryside.silobags!.length}
                    </Box>
                    <Box style={{ fontSize: '0.66rem', flex: 1, textAlign: 'right' }}>
                      {Math.round(
                        targetCountryside?.silobags?.reduce(
                          (rec, s) => rec + parseInt(s.remaining_stock ?? '0', 10),
                          0
                        ) ?? 0 / 1000
                      )}{' '}
                      <span style={{ color: '#AAA', margin: '0 3px' }}>Toneladas</span>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
            <Paper style={{ padding: '8px 16px', pointerEvents: 'auto' }}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Button
                  sx={{
                    color: '#22A7F0',
                    width: 18,
                    minWidth: 18,
                    height: 18,
                    visibility: targetSilobag.IDX !== 0 ? 'visible' : 'hidden',
                    '&:hover': { background: '#22a7f02a' },
                  }}
                  onClick={SCROLL.toLeft}
                >
                  <img src={ChevronLeft} style={{ width: 12, height: 12 }} />
                </Button>
                <Stack
                  component="div"
                  direction="row"
                  className="keep-scrolling-without-scroll"
                  spacing={0.5}
                  style={{
                    maxWidth: '90%',
                    //maskImage: 'linear-gradient(90deg,transparent 0,#000 70px,#000 calc(100% - 70px), transparent)',
                    overflowX: 'auto',
                  }}
                >
                  {targetCountryside.silobags!.map((silobag, i) => (
                    <Button
                      ref={(node) => {
                        if (i === targetSilobag.IDX) {
                          node?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
                          selectedSilobagNode.current = node ?? undefined;
                        }
                      }}
                      key={silobag.id}
                      style={{
                        color: i === targetSilobag.IDX ? 'white' : '#AAA',
                        background: i === targetSilobag.IDX ? '#6BAA00' : '#DEDEDE',
                        width: 20,
                        minWidth: 20,
                        height: 20,
                        padding: 8,
                        fontSize: '0.6rem',
                        borderRadius: 14,
                      }}
                      onClick={() => MAP_UTILS.CHANGE_TARGET.silobag(i)}
                      onKeyDown={(event) => {
                        if (event.key === 'ArrowRight') SCROLL.toRight();
                        if (event.key === 'ArrowLeft') SCROLL.toLeft();
                        if (event.key === 'Escape') MAP_UTILS.NAVIGATION.fromDetailToMenu();
                      }}
                    >
                      {i + 1}
                    </Button>
                  ))}
                </Stack>
                <Button
                  sx={{
                    color: '#22A7F0',
                    width: 20,
                    minWidth: 20,
                    height: 20,
                    visibility:
                      targetSilobag.IDX !== targetCountryside.silobags!.length - 1 ? 'visible' : 'hidden',
                    '&:hover': { background: '#22a7f02a' },
                  }}
                  onClick={SCROLL.toRight}
                >
                  <img src={ChevronRight} style={{ width: 12, height: 12 }} />
                </Button>
              </Stack>
            </Paper>
            <Paper
              style={{
                padding: 16,
                pointerEvents: 'auto',
                overflowY: 'auto',
                maxHeight: '60vh',
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                justifyContent="left"
                alignItems="center"
                style={{ lineHeight: 1.5, paddingBottom: '16px' }}
              >
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    width: 34,
                    minWidth: 34,
                    height: 34,
                    background: '#6BAA0026',
                    padding: 2,
                    borderRadius: 5,
                  }}
                >
                  {/* TODO: BORRAR "AS 1", SE DEJA DE MOMENTO PARA QUE FUNCIONE SE ESPERA A silobag.silobag_species_types.id ESTÉ BIEN TIPADO */}
                  <img
                    src={SPECIES_IMG_BY_ID[targetSilobag?.silobag_species_types!.id]}
                    style={speciesIconStyles}
                  />
                </Stack>
                <Stack style={{ maxWidth: '65%', flex: 1 }}>
                  <Tooltip
                    title={nameOverflowing === `detail-${targetSilobag.name}` ? nameOverflowing : ''}
                    placement="top"
                    leaveTouchDelay={3_000}
                    disableInteractive
                    arrow
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -10],
                            },
                          },
                        ],
                      },
                    }}
                    onMouseEnter={({ target: node }) => {
                      const name = targetSilobag.name;

                      const isOveflowing = node instanceof HTMLElement && node.scrollWidth > node.clientWidth;
                      if (isOveflowing && nameOverflowing !== name) setNameOverflowing(`detail-${name}`);
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      style={{ cursor: 'pointer', width: 'max-content', maxWidth: '100%' }}
                      onClick={() => navigate(`/silobag/${targetSilobag.id}`)}
                    >
                      <Box
                        component="span"
                        style={{
                          fontSize: '0.85rem',
                          fontWeight: 600,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {targetSilobag.name}
                      </Box>
                      <img src={ChevronRight} style={{ width: 10, height: 10 }} />
                    </Stack>
                  </Tooltip>
                  <Box style={{ fontSize: '0.75rem', color: '#ABABAB' }}>
                    ID <span style={{ fontWeight: 'bold', color: 'black' }}>{targetSilobag.id}</span>
                  </Box>
                </Stack>
                {/* BOTON CAMPANITA */}
                <Button
                  size="small"
                  disabled={!targetSilobag.alert_count}
                  sx={{
                    color: '#F00',
                    width: 50,
                    minWidth: 50,
                    height: 25,
                    padding: 2,
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                    '&:hover': { background: '#FF000022' },
                  }}
                  onClick={() => setOpenDrawer(true)}
                >
                  {targetSilobag.alert_count}{' '}
                  {targetSilobag.alert_count ? <img src={AlertsIcon} /> : <img src={DisabledAlertsIcon} />}
                </Button>
              </Stack>
              <DrawerMapAlertCard
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                targetSilobag={targetSilobag}
              />
              <Divider />
              <Box>
                <Stack direction="row" spacing={2} alignItems="center" style={{ padding: '12px 0' }}>
                  <Box style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                    <img src={SiloIDExtendedLogo} style={{ height: 16 }} />
                  </Box>
                  <Button
                    sx={{
                      color: '#22A7F0',
                      fontSize: '0.8rem',
                      padding: '0 3px',
                      textTransform: 'none',
                      '&:hover': { background: '#22a7f02a' },
                    }}
                    onClick={() => {
                      const { main_lat: lat, main_lon: lng } = targetSilobag;

                      navigator.clipboard.writeText(`${lat}, ${lng}`).then(() => {
                        selectedSilobagNode.current?.focus();
                      });
                      setUbicationCopied(true);
                    }}
                  >
                    <img src={CopyIcon} style={{ width: 11, height: 11, marginRight: 5 }} />
                    <Box>{i18n.t('app.ubication')}</Box>
                  </Button>
                  <Button
                    disabled={!hasAvailableImages}
                    sx={{
                      color: hasAvailableImages ? '#22A7F0' : '#a7a7a7',
                      fontSize: '0.8rem',
                      padding: '0 3px',
                      textTransform: 'none',
                      '&:hover': { background: '#22a7f02a' },
                    }}
                    onClick={() => handleOpenPhotoModal()}
                  >
                    <img
                      src={hasAvailableImages ? PhotoGalleryIcon : DisabledPhotoGalleryIcon}
                      style={{ width: 11, height: 11, marginRight: 5 }}
                    />
                    <Box>Galería</Box>
                  </Button>
                </Stack>
                <Stack direction="row" spacing={3} style={{ paddingBottom: 12 }}>
                  <Stack spacing={1}>
                    <Stack spacing={-0.3}>
                      <Box style={{ fontSize: '0.75rem', color: '#7a7a7a' }}>
                        {i18n.t('app.silobag_data.specie')}
                      </Box>
                      <Box style={{ fontSize: '0.9rem', fontWeight: 500 }}>{speciesName}</Box>
                    </Stack>
                    <Stack spacing={-0.3}>
                      <Box style={{ fontSize: '0.75rem', color: '#7a7a7a' }}>Total extraído</Box>
                      <Box style={{ fontSize: '0.9rem', fontWeight: 500 }}>
                        {i18n.numberToDelimited(targetSilobag.total_extracted_weight ?? 0, {
                          delimiter: i18n.t('config.delimiter'),
                        })}{' '}
                        Kg
                      </Box>
                    </Stack>
                    <Stack spacing={-0.3}>
                      <Box style={{ fontSize: '0.75rem', color: '#7a7a7a' }}>
                        {' '}
                        {i18n.t('app.silobag_data.initial_humidity')}
                      </Box>
                      <Box style={{ fontSize: '0.9rem', fontWeight: 500 }}>
                        {(targetSilobag.initial_humidity ?? '―') + ' %'}
                      </Box>
                    </Stack>
                  </Stack>
                  <Stack spacing={1}>
                    <Stack spacing={-0.3}>
                      <Box style={{ fontSize: '0.75rem', color: '#7a7a7a' }}>
                        {' '}
                        {i18n.t('app.silobag_data.product')}
                      </Box>
                      <Box style={{ fontSize: '0.9rem', fontWeight: 500 }}>
                        {targetSilobag.silobag_product_types?.name}
                      </Box>
                    </Stack>
                    <Stack spacing={-0.3}>
                      <Box style={{ fontSize: '0.75rem', color: '#7a7a7a' }}> {i18n.t('app.lot')}</Box>
                      <Box style={{ fontSize: '0.9rem', fontWeight: 500 }}>{targetSilobag.lots?.name}</Box>
                    </Stack>
                    <Stack spacing={-0.3}>
                      <Box style={{ fontSize: '0.75rem', color: '#7a7a7a' }}>
                        {' '}
                        {i18n.t('app.silobag_data.remaining_stock')}
                      </Box>
                      <Box style={{ fontSize: '0.9rem', fontWeight: 500 }}>
                        {i18n.numberToDelimited(parseFloat(targetSilobag.remaining_stock ?? '0'), {
                          delimiter: i18n.t('config.delimiter'),
                        })}{' '}
                        Kg
                      </Box>
                    </Stack>
                  </Stack>
                  <Stack spacing={1}>
                    <Stack spacing={-0.3}>
                      <Box style={{ fontSize: '0.75rem', color: '#7a7a7a' }}>
                        {' '}
                        {i18n.t('app.silobag_data.bagging_date')}
                      </Box>
                      <Box
                        style={{
                          fontSize: '0.9rem',
                          fontWeight: 500,
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.3rem',
                        }}
                      >
                        {bagging_date ? formatDate(bagging_date) : '―'}
                        {showBaggingDateAdviceCertified ? (
                          <Tooltip title="Esta fecha está validada por SiloSat">
                            <img src={NewReleaseIcon} alt="release-icon" style={{ marginLeft: '0.5rem' }} />
                          </Tooltip>
                        ) : null}
                        {showBaggingDateAdviceWarning ? (
                          <Tooltip title={i18n.t('app.silobag_detail.different_date')}>
                            <Button
                              size="small"
                              sx={{
                                padding: '0px',
                                minWidth: 'auto',
                                minHeight: 1,
                                marginLeft: '0rem',
                              }}
                              onClick={() => setOpenModalSat(true)}
                            >
                              <img src={warningIcon} alt="release-icon" />
                            </Button>
                          </Tooltip>
                        ) : null}
                      </Box>
                    </Stack>
                    <Stack spacing={-0.3}>
                      <Box style={{ fontSize: '0.75rem', color: '#7a7a7a' }}>
                        {' '}
                        {i18n.t('app.silobag_data.harvest')}
                      </Box>
                      <Box style={{ fontSize: '0.9rem', fontWeight: 500 }}>
                        {targetSilobag.silobag_harvests?.name ?? '―'}
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
              {targetSilobag.planet_image?.status_id ? (
                <>
                  <Box>
                    <Divider />
                    <Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="center"
                        style={{ padding: '12px 0' }}
                      >
                        <Stack direction="row" justifyContent="left" alignItems="center" style={{ flex: 1 }}>
                          <img src={SiloSatLogo} style={{ height: 16 }} />
                        </Stack>
                        <Button
                          sx={{
                            color: '#22A7F0',
                            fontSize: '0.8rem',
                            padding: '0 5px',
                            textTransform: 'none',
                            '&:hover': { background: '#22a7f02a' },
                          }}
                          onClick={handleOpenSiloSatModal}
                        >
                          Ver historial
                        </Button>
                      </Stack>
                      <img
                        src={targetSilobag.planet_image?.image_url}
                        style={{ margin: '0 auto', width: 250, height: 180, objectFit: 'cover' }}
                      />
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="center"
                        style={{ padding: '12px 0' }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="left"
                          alignItems="center"
                          style={{ flex: 1, color: '#A7A7A7' }}
                        >
                          {dayjs(targetSilobag.planet_image?.timestamp).format('DD/MM/YYYY HH:mm')}
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="right"
                          alignItems="center"
                          style={{
                            flex: 1,
                            color:
                              targetSilobag?.planet_image?.status_id === 1
                                ? '#73BD00'
                                : targetSilobag?.planet_image?.status_id === 3
                                ? '#7A7A7A'
                                : '#FF0000',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              backgroundColor:
                                targetSilobag?.planet_image?.status_id === 1
                                  ? '#9EC95526'
                                  : targetSilobag?.planet_image?.status_id === 3
                                  ? '#7A7A7A33'
                                  : '#FF000026',
                              padding: '0 8px',
                              borderRadius: '20px',
                            }}
                          >
                            {targetSilobag?.planet_image?.status_id === 1 ? (
                              <img
                                src={EyeIcon}
                                alt="eye"
                                style={{ width: '16px', height: '16px', marginRight: '8px' }}
                              />
                            ) : targetSilobag?.planet_image?.status_id === 2 ? (
                              <img
                                src={RedEyeIcon}
                                alt="red-eye"
                                style={{ width: '16px', height: '16px', marginRight: '8px' }}
                              />
                            ) : (
                              <img
                                src={discardedIcon}
                                alt="discarded"
                                style={{ width: '16px', height: '16px', marginRight: '8px' }}
                              />
                            )}
                            {IMAGES_STATUS[targetSilobag?.planet_image?.status_id ?? -1]}
                          </div>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Box>
                </>
              ) : null}
              {targetSilobag.silobags_devices?.length ? (
                <>
                  <Box>
                    <Divider />
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="center"
                      rowGap={2}
                      style={{ padding: '12px 0' }}
                      flexWrap="wrap"
                    >
                      <Stack direction="row" justifyContent="left" alignItems="center" style={{ flex: 1 }}>
                        <img src={SiloMetrixLogo} style={{ height: 16 }} />
                      </Stack>
                      <Stack direction="row" spacing={1} justifySelf="flex-end">
                        {targetSilobag.silobags_devices?.map((devRel, i) => {
                          const DEVICE_POSITION: { [key: number]: string } = {
                            1: i18n.t('app.silobag_devices.init'),
                            2: i18n.t('app.silobag_devices.init_middle'),
                            3: i18n.t('app.silobag_devices.middle'),
                            4: i18n.t('app.silobag_devices.middle_end'),
                            5: i18n.t('app.silobag_devices.end'),
                          };

                          return (
                            <Button
                              key={devRel.id}
                              disabled
                              style={{
                                color: devRel.hex === '#FFDD00' ? 'black' : 'white',
                                background: devRel.hex,
                                width: devRel.position ? 'auto' : 20,
                                minWidth: 20,
                                height: 20,
                                padding: 3,
                                fontSize: '0.66rem',
                                borderRadius: 14,
                                textTransform: 'none',
                              }}
                            >
                              {DEVICE_POSITION[devRel.position ?? -1] ?? i + 1}
                            </Button>
                          );
                        })}
                      </Stack>
                    </Stack>
                  </Box>
                </>
              ) : null}
            </Paper>
          </Stack>
          {/* TODO: revisar con el reemplazo de endpoints en el mapa porque esto quedó con una función dummy de cierre. */}
          <MyModal open={openModalSat} onClose={() => {}}>
            <ModalContent
              dateToShow={dateToShow!}
              selectedDate={selectedDate}
              silobagStatus={true}
              setSelectedDate={setSelectedDate}
              handleClose={handleClose}
              handleUpdateDate={handleUpdateDate}
              startDate={bagging_date_init_image_timestamp}
              endDate={bagging_date_end_image_timestamp}
            />
          </MyModal>

          <CarouselModalPhotoGallery
            open={openPhotoModal}
            onClose={handleClosePhotoModal}
            images={imagesForPhotoModal}
            title="Imágenes"
            sbId={targetSilobagID}
          />
        </Box>
      ) : null}
      <MyModal2 open={openSiloSatModal} onClose={handleCloseSiloSatModal}>
        {isListSiloSatLoading ? (
          <Stack height="550px" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            {listSiloSatSummary ? (
              <SilobagSilosat
                silosat={silosatData?.v_filtered_sat_view_images ?? []}
                statuses={{ sat_view_state: 'Active' }}
                existence={silosatExistenceStatuses ?? []}
              />
            ) : null}
          </>
        )}
        <Box display="flex" justifyContent="end">
          <Button variant="text" onClick={handleCloseSiloSatModal}>
            Cerrar
          </Button>
        </Box>
      </MyModal2>
    </>
  );
}

function capitalizeFirstLetter(str: string) {
  if (typeof str !== 'string') return str;

  return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

function MyModal2({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',

    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}
