export enum ENDPOINT {
  LOGIN = '/login',
  SILOBAGS_SEARCH = '/silobags/search/names',
  USER_SELECTABLE_ORGS = '/user_organizations',
  ORGANIZATIONS = '/organizations',
  POE = '/poe/questions',
  ALERT_ACTIONS = '/alert_actions',
  COUNTRIES = '/countries_l0',
  COUNTRIES_L1_BY_L0 = '/countries_l1/countryL0',
  COUNTRIES_L2_BY_L1 = '/countries_l2/countryL1',
  LOCALITIES = '/localities',
  COMPANIES = '/companies',
  COUNTRYSIDES = '/countrysides',
  REGISTER = '/register',
  VERIFY = '/verify',
  RECOVER = '/recover',
  USERS = '/users',
  SILOBAGS = '/silobags',
  SILOBAGS_MASSIVE = '/silobags/massive_upload',
  SILOBAG_SPECIES = '/silobagsSpeciesTypes',
  SILOBAG_PRODUCT = '/silobagsProductTypes',
  HARVESTS = '/harvests',
  DEVICES_REPORT = '/reports/devices',
  DEVICES_REPORT_SILOBAGS = '/reports/devices/filters/silobags',
  DEVICES_REPORT_COUNTRYSIDES = '/reports/devices/filters/countrysides',
  NOTIFICATION_RECIPIENTS = '/alert_notification_recipients',
  REPORT_STOCK = '/silobag_movements',
  FREE_DEVICES = '/available_devices',
  REPORT_STOCK_LASTBALANCE = '/silobag_movements/last_balance',
  LOTS = '/lots',
  DASHBOARD_INFO = '/dashboardInformation',
  DASHBOARD_SILOBAGS = '/silobags/get/home/dashboard',
  SILOBAGS_MEASUREMENTS = '/silobagsMeasurements',
  CMCS = '/cmcs',
  FILES = '/files',
  SILOBAG_EXTRACTION = '/silobag_extraction',
  ALERTS = '/alerts',
  MAP = '/silobags/get/web/map',
  SILO_PERMISSION = '/silobags_permissions',
  SILOBAG_HUMIDITY_SOURCE = '/humidity_sources',
  SILOBAG_WEIGHT_SOURCE = '/weight_sources ',
  SILOBAG_CLOSE = '/silobags/close',
  ALBOR_LOGIN = '/albor/login',
  ALBOR_COUNTRYSIDES = '/albor/getAlborCampos',
  REPORT_STATE_STOCK = '/reports/stock_status',
  REPORT_ACTIVITY = '/report_activity',
  REPORT_ACTIVITY_FILTERS = '/report_activity/filters/users',
  SILOBAG_EVENT_TYPES = '/silobag_event_types',
  REPORT_SAT_SILOBAGS_EXISTENCE = '/reports/sat/silobags/existence',
  SILO_INSURANCE = '/silobags/insurance/quote',
  EXTERNAL_LINKS = '/external_links',
  INSURANCE_LASEGUNDA = '/la_segunda/quoteEmail',
  COUNTRYSIDE_PERMISSIONS = '/countrysides_permissions/countryside',
  LAB_PROTOCOLS_BY_SILOBAG = '/lab/protocols/export/getTemplatesBySilobags',
  LAB_PROTOCOL = '/lab/protocols',
  LAB_PROTOCOL_EXPORT = '/lab/protocols/export/manualInput',
  LAB_PROTOCOL_IMPORT = '/lab/protocols/import/manualInput',
  LAB_SILOBAG_PROTOCOL = '/lab/protocols/bySilobag',
  LAB_LAST_SILOBAG_PROTOCOL = '/lab/protocols/last/bySilobag',
  INSURANCE_SPECIES = '/la_segunda/species_types',
  SILOBAGS_HISTORY = '/silobags_history',
  SILOBAGS_SAT_VIEW = '/sat_view/silobags',
  VERIFY_PHONE = '/verify/phone/otp',
  CONFIRM_PHONE = '/verify/phone',
  CORVUS_LOGIN = '/corvus/account/link_account',
  CORVUS_UPDATE_LOGIN = '/corvus/account/update_account',
  CORVUS_SYNC_ENTITIS = '/corvus/heavy_logs/sync_heavy_logs',
  CORVUS_ESTABLISHMENTS = '/corvus/entities/establishments',
  CORVUS_PRODUCTS = '/corvus/entities/products',
  CORVUS_HEAVYLOGS = '/corvus/heavy_logs',
  CORVUS_GET_SB_INFO = '/corvus/silobag/info',
  CORVUS_VINCULATE_HEAVYLOGS = '/corvus/silobag/link_heavy_logs',
  CORVUS_DELETE_HEAVYLOGS = '/corvus/silobag/unlink_heavy_logs',
}
/* ALL IOF ENDPOINTS (✅ = implemented, ❌ = not implemented)
✅ AUTH: "login",
✅ ALERTS: "alerts",
✅ ALERT_ACTIONS: "alert_actions",
❌ ALERT_ACTIONS_TYPES: "alertActionsTypes",
❌ CITY: "municipalities",
✅ CMCS: "cmcs",
✅ COMPANIES: "companies",
❌ COLORS: "colors",
✅ COUNTRYSIDES: "countrysides",
✅ COUNTRIES: "countries_l0",
❌ COUNTRIES_L1: "countries_l1",
❌ COUNTRIES_L2: "countries_l2",
✅ DASHBOARD_INFO: "dashboardInformation",
✅ DASHBOARD_ALERTS: "silobags/get/home/dashboard",
❌ DEVICES: "devices",
❌ DEVICES_UNLINK: "devices/unorganized",
✅ FILES: "files",
❌ FIRST_VIEW: "rpc/first_map_view",
✅  FREE_DEVICES: "available_devices",
❌ FEE_TYPES: "fee_types",
✅  HARVESTS: "harvests",
❌ SECOND_VIEW: "rpc/second_map_view",
❌ SECOND_VIEW_DEVICE_NAV: "rpc/second_map_view_devices_navbar",
❌ SECOND_VIEW_POINT_TOOLTIPS: "rpc/second_map_view_points_tooltips",
✅ LOCALITIES: "localities",
✅ LOTS: "lots",
❌ METRIC_ALERT_CONFIG: "metricsAlertConfig",
❌ METRIC_ALERT_TYPE: "metric_alert_types",
❌ METRIC_ALERT_ZONES: "metricAlertZones",
✅ METRIC_ALERT_SPECIES: "silobagsSpeciesTypes",
❌ NOTIFICATION: "alert_notifications",
✅ NOTIFICATION_RECIPIENTS: "alert_notification_recipients",
✅ ORGANIZATIONS: "organizations",
❌ PERMISSIONS: "permissions",
✅ POE: "poe/questions",
❌ USER_PERMISSIONS: "users_permissions",
❌ USER_DENIED_PERMISSIONS: "user_denied_permissions",
❌ USER_COUNTRYSIDES_PERMISSIONS: "user_countrysides_permissions",
✅  RECOVER: "recover",
❌ ROLES: "roles",
✅ SILOBAGS: "silobags",
✅ SILOBAG_HUMIDITY_SOURCE: "humidity_sources",
✅ SILOBAG_WEIGHT_SOURCE: "weight_sources ",
❌ SILOBAG_BRAND: "predictive/silobag_brand",
❌ SILOBAG_RESPONSIBLE_BAGGING: "predictive/responsible_for_bagging",
❌ SILOBAG_VARIETY: "predictive/variety",
❌ SILOBAGS_DEVICES: "silobagsDevices",
✅ SILOBAG_EXTRACTION: "silobag_extraction",
❌ SILOBAG_EXTRACTION_PUT: "silobag_extraction/editExtraction",
✅ SILOBAGS_MAP_DATA: "silobags/get/web/map",
✅ SILOBAGS_MEASUREMENTS: "silobagsMeasurements",
✅ SILO_PERMISSION: "silobags_permissions",
❌ SILO_PERMISSION_SEARCH: "silobags_permissions/predictive",
✅ SILO_PRODUCT: "silobagsProductTypes",
✅ SILO_SPECIES: "silobagsSpeciesTypes",
✅ USER_ORGANIZATION: "user_organizations",
✅ USERS: "users",
✅ VERIFY: "verify",
✅ REGISTER: "register",
✅ DEVICES_REPORT = 'reports/devices',
✅ REPORT_STOCK: "silobag_movements",
✅ SILO_INSURANCE: "silobags/insurance/quote/",
✅ INSURANCE_EXTERNAL: "external_links",
*/
