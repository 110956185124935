import { ENDPOINT } from '@data/enums';
import { BACKEND } from '@services/backend';
import { ISilobags_DEPRECATED } from '@utils/to-refactor/helper';

export const massiveCloseService_DEPRECATED = async (body: ISilobags_DEPRECATED) => {
  const { response, data } = await BACKEND.post(ENDPOINT.SILOBAGS_MASSIVE_CLOSE, { body });

  return {
    response,
    summary: data,
  };
};
