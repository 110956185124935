import { SPECIES_IMG_BY_ID } from '@data/species';
import { useEffectOnUpdate, useToggle } from '@hooks/core';
import { useService } from '@hooks/use-service';
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { listSilobagService } from '@services/domain/base/silobag/base/silobag-list';
import { exportLabProtocolService } from '@services/domain/laboratory/lab-protocol-export';
import { labProtocolBySilobagListService } from '@services/domain/laboratory/lab-protocol-list';
import { SummaryOf } from '@typed/extra/utils';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import i18n from '../../../libs/language';

type SummarySilobagList = Awaited<ReturnType<typeof listSilobagService>>['summary']['data'];

type Props = {
  open: boolean;
  onClose: () => void;
  selectedSilobags: SummarySilobagList;
};

export function DownloadLabTemplateModal({ open, onClose, selectedSilobags }: Props) {
  const initBody = { body: { silobags: selectedSilobags.map((s) => s.id) } };
  const { summary, isLoading } = useService(labProtocolBySilobagListService, { fetchOnMount: [initBody] });

  const [showMainBody, setShowMainBody] = useToggle(false);

  const HANDLER = {
    onClose,
    onContinue: () => setShowMainBody(true),
  };

  const labGroups = summary?.lab_grouped_silobag_by_specie_type ?? [];
  const availableSpecies = summary?.silobag_species_types_with_lab_templates ?? [];

  const labGroupsWithoutTemplates = labGroups.filter((s) => s.lab_templates.length === 0) ?? [];
  const hasProtocolsWithTemplates = (labGroups.filter((s) => s.lab_templates.length !== 0) ?? []).length > 0;
  const hasEmptyLabGroups = labGroupsWithoutTemplates.length > 0;

  useEffect(() => console.log('data', isLoading, summary), [summary]); // quitar

  return (
    <MyModal open={open} onClose={onClose}>
      {isLoading ? (
        <LoadingModal onClose={HANDLER.onClose} />
      ) : (
        <>
          {hasEmptyLabGroups && !showMainBody ? (
            <WarningModalBody
              templatesData={labGroupsWithoutTemplates}
              availableTemplatesData={availableSpecies}
              onContinue={HANDLER.onContinue}
              onClose={HANDLER.onClose}
              disableContinue={!hasProtocolsWithTemplates}
            />
          ) : (
            <ModalBody
              templatesData={labGroups.filter((s) => s.lab_templates.length !== 0)}
              onClose={HANDLER.onClose}
            />
          )}
        </>
      )}
    </MyModal>
  );
}

type ModalBodyProps = {
  templatesData: SummaryOf<typeof labProtocolBySilobagListService>['lab_grouped_silobag_by_specie_type'];
  onClose: () => void;
};
function ModalBody({ templatesData, onClose }: ModalBodyProps) {
  const { trigger } = useService(exportLabProtocolService, {
    onSuccess: (data) => {
      setURL(window.URL.createObjectURL(data));
    },
  });

  const [url, setURL] = useState<string>();

  const templatesWithUniqueOption = templatesData.filter((te) => te.lab_templates.length === 1);
  const initSelectedTemplates = templatesWithUniqueOption.reduce((acc, te) => {
    const [uniqueTemplate] = te.lab_templates;
    return { ...acc, [te.silobag_species_type_id]: uniqueTemplate.id };
  }, {});

  const [errorData, setErrorData] = useState<Record<number, string>>({});
  const [selectedTemplates, setSelectedTemplates] = useState<Record<number, number>>(initSelectedTemplates);

  const handleErrorChange = (speciesID: number, error: string) => {
    setErrorData((prev) => ({ ...prev, [speciesID]: error }));
  };
  const handleSelectionChange = (speciesID: number, templateID: number) => {
    handleErrorChange(speciesID, '');
    setSelectedTemplates((prev) => ({ ...prev, [speciesID]: templateID }));
  };

  const [fileName, setFileName] = useState('');
  const [index, setIndex] = useState<number>();

  // Download each template one by one until all templates are downloaded and reset the index
  useEffectOnUpdate(() => {
    if (index == null) return;

    // Check if the download is complete and reset the index

    const isDownloadComplete = index >= Object.keys(selectedTemplates).length;
    if (isDownloadComplete) {
      setTimeout(() => {
        setIndex(undefined);
        onClose();
      }, 1000);
      return;
    }

    // Download the next template

    const speciesID = parseInt(Object.keys(selectedTemplates).at(index)!, 10);
    const templateID = Object.values(selectedTemplates).at(index)!;

    const targetTemplateEntry = templatesData.find((tpl) => tpl.silobag_species_type_id === speciesID);
    const silobags = targetTemplateEntry!.silobags;
    const hasOnlyOneSilobag = silobags.length === 1;

    const targetTemplate = targetTemplateEntry!.lab_templates.find((tpl) => tpl.id === templateID);
    const [templateLocale] = targetTemplate!.lab_templates_translations;
    const templateName = templateLocale!.description;
    const branding = 'SiloReal';
    const ext = 'xlsx';
    const silobagID = hasOnlyOneSilobag ? ' - ' + silobags[0] : '';
    const fileName = templateName ? `${branding} - ${templateName}${silobagID}.${ext}` : '';
    setFileName(fileName);

    trigger({
      body: {
        lab_template_id: templateID,
        silobags,
      },
    });
  }, [index]);

  const handleDownloadAll = () => {
    const errorMsg = 'Debe seleccionar una plantilla.';
    for (const te of templatesData) {
      const speciesID = te.silobag_species_type_id;
      const templateID = selectedTemplates[speciesID];

      if (templateID == null) {
        handleErrorChange(speciesID, errorMsg);
        return;
      }
    }

    setIndex(0);
  };

  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    if (!url || !downloadLinkRef.current) return;
    downloadLinkRef.current.click();
    setIndex((prev) => (prev ?? 0) + 1);
  }, [url]);

  const isDownloadAllLoading = index != null;
  useEffect(() => console.log('selectedTemplates', selectedTemplates), [selectedTemplates]); // quitar

  return (
    <>
      <a ref={downloadLinkRef} href={url} download={fileName} style={{ display: 'none' }} />
      <Box component="h1" fontWeight={500} style={{ color: '#49454F' }}>
        {i18n.t('app.lab.download_modal.title')}
      </Box>
      <Stack style={{ border: '1px solid #E2E5EC' }}>
        <Stack p={1} style={{ minHeight: '40px' }} justifyContent="center">
          <Typography>{i18n.t('app.species')}</Typography>
        </Stack>
        {templatesData.map((templateEntry) => {
          const speciesID = templateEntry.silobag_species_type_id;
          const hasUniqueOption = templateEntry.lab_templates.length === 1;

          return (
            <Stack
              key={speciesID}
              direction="row"
              alignItems="center"
              py={1}
              px={1}
              style={{ borderTop: '1px solid #E2E5EC' }}
            >
              <Box style={{ flex: 1 }}>
                <Typography>{templateEntry.description}</Typography>
              </Box>
              <FormGroup style={{ minWidth: '50%' }}>
                <FormControl size="small" error={Boolean(errorData[speciesID])}>
                  <InputLabel>{i18n.t('app.choose')}</InputLabel>
                  <Select
                    label={i18n.t('app.choose')}
                    disabled={isDownloadAllLoading || hasUniqueOption}
                    value={selectedTemplates[speciesID]?.toString() ?? ''}
                    onChange={(e) => handleSelectionChange(speciesID, parseInt(e.target.value, 10))}
                  >
                    {templateEntry.lab_templates.map((tpl) => (
                      <MenuItem key={tpl.id} value={tpl.id}>
                        {tpl.lab_templates_translations.at(0)?.description}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorData[speciesID] ? <FormHelperText>{errorData[speciesID]}</FormHelperText> : null}
                </FormControl>
              </FormGroup>
            </Stack>
          );
        })}
      </Stack>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <Button
          sx={{
            color: 'black',
            borderRadius: 100,
            textTransform: 'none',
            border: 'none',
            padding: '8px 16px',
            '&:hover': { background: '#0002' },
          }}
          onClick={onClose}
        >
          {i18n.t('app.close')}
        </Button>
        <Button
          variant="contained"
          //color="primary"
          disabled={isDownloadAllLoading}
          onClick={handleDownloadAll}
          sx={{
            color: '#FFFE',
            background: '#22A7F0',
            minWidth: 200,
            borderRadius: 100,
            textTransform: 'none',
            '&:hover': { background: '#22A7F0' },
          }}
        >
          {isDownloadAllLoading ? (
            <Stack direction="row" spacing={2} alignItems="center">
              <Box>{`${i18n.t('app.downloading')} ${index ?? 0 + 1}/${
                Object.keys(selectedTemplates).length
              }`}</Box>
              <CircularProgress size={16} style={{ color: '#22A7F0' }} />
            </Stack>
          ) : (
            <>{i18n.t('app.download_all')}</>
          )}
        </Button>
      </Stack>
    </>
  );
}

type WarningModalProps = {
  templatesData: SummaryOf<typeof labProtocolBySilobagListService>['lab_grouped_silobag_by_specie_type'];
  availableTemplatesData: SummaryOf<
    typeof labProtocolBySilobagListService
  >['silobag_species_types_with_lab_templates'];
  onContinue: () => void;
  onClose: () => void;
  disableContinue: boolean;
};
function WarningModalBody({
  templatesData,
  availableTemplatesData,
  onContinue,
  onClose,
  disableContinue,
}: WarningModalProps) {
  const MAX_ENTRIES = 5;
  const FONT_SIZE = 14;

  const totalSilobagWithProblems = templatesData.reduce((acc, tpl) => acc + tpl.silobags.length, 0);

  return (
    <>
      <Stack spacing={2}>
        <Box component="h1" fontWeight={500} style={{ color: '#49454F' }}>
          {i18n.t('app.lab.warning_modal.attention')}
        </Box>
        <Stack px={10} style={{ textAlign: 'center' }}>
          {i18n.t('app.lab.warning_modal.unavailable', { silosQty: totalSilobagWithProblems })}
        </Stack>
        <Stack direction="row" spacing={4} justifyContent="center" style={{ overflowX: 'auto' }}>
          {templatesData.map((tpl) => (
            <Stack
              direction="row"
              spacing={1}
              key={tpl.silobag_species_type_id}
              justifyContent="center"
              alignItems="flex-start"
            >
              <Stack
                style={{ width: 30, height: 30, background: '#6BAA0026', borderRadius: 10 }}
                justifyContent="center"
                alignItems="center"
              >
                <img src={SPECIES_IMG_BY_ID[tpl.silobag_species_type_id]} style={{ width: 24, height: 24 }} />
              </Stack>

              <Stack spacing={2} justifyContent="center">
                <Typography fontSize={FONT_SIZE} style={{ paddingTop: '4px' }}>
                  {tpl.description}
                </Typography>
                <Stack>
                  {tpl.silobags.slice(0, MAX_ENTRIES).map((id) => (
                    <Typography key={id} fontSize={FONT_SIZE}>
                      • {id}
                    </Typography>
                  ))}
                  {tpl.silobags.length > MAX_ENTRIES ? (
                    <Typography fontSize={FONT_SIZE - 2} style={{ color: '#A7A7A7' }}>
                      <i>
                        +{tpl.silobags.length - MAX_ENTRIES} {i18n.t('app.silobag_plural').toLowerCase()}...
                      </i>
                    </Typography>
                  ) : null}
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
        <Stack justifyContent="center" alignItems="center" spacing={2} style={{ textAlign: 'center' }}>
          <Box>
            <Typography>{i18n.t('app.lab.warning_modal.available')}</Typography>
          </Box>
          <Stack direction="row" spacing={4}>
            {availableTemplatesData.map((spc) => (
              <Stack key={spc.id} spacing={1} alignItems="center">
                <Stack
                  style={{ width: 30, height: 30, background: '#6BAA0026', borderRadius: 10 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={SPECIES_IMG_BY_ID[spc.id]} style={{ width: 24, height: 24 }} />
                </Stack>
                <Box>{spc.silobag_species_types_translations[0].description}</Box>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Box style={{ textAlign: 'center' }}>
          <Typography fontWeight={600} fontSize={20}>
            {i18n.t('app.lab.warning_modal.continue')}
          </Typography>
        </Box>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <Button
          sx={{
            color: 'black',
            borderRadius: 100,
            textTransform: 'none',
            border: 'none',
            padding: '8px 16px',
            '&:hover': { background: '#0002' },
          }}
          onClick={onClose}
        >
          {i18n.t('app.close')}
        </Button>
        <Tooltip
          title={disableContinue ? i18n.t('app.lab.template_download.tooltip.continue') : ''}
          placement="top"
        >
          <Box>
            <Button
              variant="contained"
              style={{
                color: '#FFF',
                width: 108,
                height: 40,
                borderRadius: 100,
                textTransform: 'none',
              }}
              color="primary"
              disabled={disableContinue}
              onClick={onContinue}
            >
              {i18n.t('app.continue')}
            </Button>
          </Box>
        </Tooltip>
      </Stack>
    </>
  );
}

type LoadingModalProps = {
  onClose: () => void;
};
function LoadingModal({ onClose }: LoadingModalProps) {
  return (
    <>
      <Stack justifyContent="center" alignItems="center" style={{ height: '280px', maxHeight: '100%' }}>
        <CircularProgress />
      </Stack>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <Button
          sx={{
            color: 'black',
            borderRadius: 100,
            textTransform: 'none',
            border: 'none',
            padding: '8px 16px',
            '&:hover': { background: '#0002' },
          }}
          onClick={onClose}
        >
          Cerrar
        </Button>
        <Button
          variant="contained"
          disabled
          sx={{
            color: '#FFFE',
            background: '#22A7F0',
            minWidth: 200,
            borderRadius: 100,
            textTransform: 'none',
            '&:hover': { background: '#22A7F0' },
          }}
        >
          Descargar todos
        </Button>
      </Stack>
    </>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};
function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    overflowY: 'auto',
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}
