export enum DYNAMIC_PATH {
  NEW = 'new',
  EDIT = 'edit',
  SILO_ID = ':siloID',
  USER_ID = ':userID',
  STOCK = 'stock',
  DEVICE = 'device',
  ALERT = 'alert',
  ACTIVITY = 'activity',
  NOTIFICATION = 'notification/:userID',
  STATE_STOCK = 'state-stock',
  EXISTENCE_STOCK = 'existence-report',
  INSURANCE = 'insurance/:siloID',
}

export enum PATH {
  // PUBLIC
  LOGIN = '/login', // ✅ PARA ACCEDER A UNA CUENTA DE USUARIO
  NO_CONNECTION = '/no-connection', // ✅ PANTALLA PARA DISPOSITIVOS SIN INTERNET
  FORGOT_PASSWORD = '/forgot-password', // ✅ RECUPERACIÓN DE PW OLVIDADA
  VERIFY_REGISTER = '/verify-register', // ✅ ES PARA VERIFICAR REGISTRO DE EMPRESAS
  REGISTER = '/register', // ✅ ES PARA REGISTRO DE EMPRESAS (nueva organización)
  COMPLETE_REGISTER = '/complete-register', // ✅ REGISTRO EXITOSO DE EMPRESA
  VERIFY_RESET_ACCOUNT = '/verify-reset-account', // ✅ PONER EMAIL Y CÓDIGO ENVIADO POR MAIL
  RESET = '/reset', // ✅ AL PONER BIEN EL CÓDIGO, PERMITE PONER NUEVA CONTRASEÑA

  // PROTECTED
  DASHBOARD = '/dashboard', // ✅ página principal
  SILOBAG = '/silobag', // ✅ listado de sb
  SILOBAG_DETAIL = `${PATH.SILOBAG}/${DYNAMIC_PATH.SILO_ID}`, // ✅ detalle de sb
  SILOBAG_NEW = `${PATH.SILOBAG}/${DYNAMIC_PATH.NEW}`, // ✅ crear nuevo sb
  SILOBAG_EDIT = `${PATH.SILOBAG}/${DYNAMIC_PATH.SILO_ID}/${DYNAMIC_PATH.EDIT}`, // ✅ editar sb
  ADMINISTRATOR = '/admin', // ✅ dashboard de Administración
  ADMIN_NOTIFICATION = `${PATH.ADMINISTRATOR}/${DYNAMIC_PATH.NOTIFICATION}`, // ✅ panel de notificaciones de admin
  NOTIFICATION = '/user-notification',
  USER_NOTIFICATION = `${PATH.NOTIFICATION}/${DYNAMIC_PATH.USER_ID}`,
  MAP = '/map', // ✅ el mapa
  REPORT = '/report', // ✅ reportes
  REPORT_STOCK = `${PATH.REPORT}/${DYNAMIC_PATH.STOCK}`, // ✅ reporte de stock
  REPORT_DEVICE = `${PATH.REPORT}/${DYNAMIC_PATH.DEVICE}`, // ✅ reporte de dispositivos
  REPORT_ALERT = `${PATH.REPORT}/${DYNAMIC_PATH.ALERT}`, // ✅ reporte de alertas
  REPORT_ACTIVITY = `${PATH.REPORT}/${DYNAMIC_PATH.ACTIVITY}`, // ✅ reporte de actividades
  REPORT_STATE_STOCK = `${PATH.REPORT}/${DYNAMIC_PATH.STATE_STOCK}`, // ✅ reporte de estado de stock
  REPORT_SAT_SILOBAGS_EXISTENCE = `${PATH.REPORT}/${DYNAMIC_PATH.EXISTENCE_STOCK}`, // reporte de existencia de stock
  SILOBAG_INSURANCE = `${PATH.SILOBAG}/${DYNAMIC_PATH.INSURANCE}`, // ✅ seguro de sb
  // WAITING FOR DELETE
  ALERT = '/alert', // ❌ Dpersonalización de alertas (?) // desestimado (umbral de alertas)
  NAV = '/nav', // ❌ ESTO ES FALOPA DE LA BUENA
  SILOBAG_MAP = '/silobag/map', // ❌ se está usando siempre countryside (nueva ruta para el map)
  VIEW_SILO = '/silobag/:id', // ❌ repetido
  VERIFY = '/verify', // ❌ revisar más a fondo
  VERIFY_ACCOUNT = '/verify-account', // ❌ revisar más a fondo
  NOT_FOUND = '*', // ❌ (?) // no carga
}
