import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import {
  InsuranceListPayload,
  InsuranceListResponse,
} from '@services/integration/insurance/insurance-list.type';
import { transformIntoQueryParamString } from '@utils/service';

// deprecated service ref.: getSilobagInsurance_DEPRECATED
export async function silobagInsuranceListService({ query: rawQuery }: InsuranceListPayload) {
  const query = transformIntoQueryParamString(rawQuery);
  const { response, data } = await BACKEND.get<InsuranceListResponse>(ENDPOINT.SILO_INSURANCE, { query });

  return {
    response,
    summary: data,
  };
}
