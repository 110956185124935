import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

import { LicenseInfo } from '@mui/x-license-pro';
import mixpanel from 'mixpanel-browser';

import { routerConfigFromElements } from '@navigation/routes';

import './index.css';

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_KEY);
mixpanel.init('ac2d220d22fcc916323c9cd9019d9b52', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#6baa00',
    },
    secondary: {
      main: '#ffeb3b',
    },
    success: {
      main: '#739e29',
    },
    info: {
      main: '#22a7f0',
      dark: '#0074FF',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <RouterProvider router={routerConfigFromElements} />
  </ThemeProvider>
);
