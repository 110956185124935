import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { ListAlertPayload, ListAlertResponse } from './alert-list.types';
import { transformIntoQueryParamString } from '@utils/service';

export async function listAlertService({ query: rawQuery }: ListAlertPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<ListAlertResponse>(ENDPOINT.ALERTS, { query });

  return {
    response,
    summary: data,
  };
}
