import {
  DataGridPro,
  DataGridProProps,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import i18n from '../../../libs/language';
import { SetStateAction } from 'react';

type propTablet = {
  initialPageSize: number;
  noBorders?: boolean;
  noInternalBorders?: boolean;
  getDetailPanelContent?: (params: GridRowParams) => React.ReactNode;
  setPaginationModel?: (currentModel: SetStateAction<GridPaginationModel>) => void;
  onRowSelected?: (id: GridRowSelectionModel) => void;
  selectFullRow?: boolean;
  // setSelectedRows?: (rows: any[]) => void;
};

export function BaseTable(props: DataGridProProps & propTablet) {
  const {
    rows,
    columns,
    initialPageSize,
    loading,
    rowHeight,
    noBorders,
    noInternalBorders,
    getDetailPanelContent,
    setPaginationModel,
    paginationMode,
    initialState,
    onRowSelected,
    selectFullRow,
    ...dataGridProProps
  } = props;

  const apiRef = useGridApiRef();

  const hidePagination = paginationMode === 'client' && rows.length <= initialPageSize;

  const handleRowSelectionChange = (newSelection: GridRowSelectionModel) => {
    if (selectFullRow) {
      const selectedData = newSelection.map((id) => rows.find((row) => row.id === id));
      if (onRowSelected) {
        onRowSelected(selectedData);
      }
    } else {
      if (onRowSelected) {
        onRowSelected(newSelection);
      }
    }
  };

  return (
    <Box sx={{ width: '100%', backgroundColor: 'white', padding: '15px', borderRadius: '10px' }}>
      <DataGridPro
        apiRef={apiRef}
        /* STYLES SECTION */
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          border: noBorders ? 'none' : '',
          'div.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar': {
            display: hidePagination ? 'none' : 'flex', // Mostrar u ocultar la paginación según la condición
          },
          '.MuiDataGrid-withBorderColor': {
            border: noBorders ? 'none' : '',
          },
          '.MuiDataGrid-cell': {
            border: noBorders || noInternalBorders ? 'none' : '',
          },
        }}
        /* SETTINGS SECTION */
        autoHeight
        loading={loading}
        rowHeight={rowHeight ?? 60}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: initialPageSize,
            },
          },
          ...initialState,
        }}
        localeText={{
          MuiTablePagination: {
            labelRowsPerPage: i18n.t('app.rows_per_page'),
          },
          noRowsLabel: i18n.t('app.no_grid_data'),
        }}
        /* PAGINATION SECTION */
        pagination
        pageSizeOptions={[5, 10, 20, 50, 100]}
        paginationMode={paginationMode ?? 'client'}
        onPaginationModelChange={(newModel) => {
          if (!setPaginationModel) return;

          setPaginationModel((currentModel) => {
            if (newModel.pageSize === currentModel.pageSize) return newModel;

            const recalculatedPage = Math.floor(
              (currentModel.pageSize * currentModel.page) / newModel.pageSize
            );

            return {
              page: recalculatedPage,
              pageSize: newModel.pageSize,
            };
          });
        }}
        onRowSelectionModelChange={handleRowSelectionChange}
        /* GETTERS SECTION */
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 'auto'}
        /* DISABLING SECTION */
        disableRowSelectionOnClick
        disableColumnMenu
        disableColumnReorder
        disableColumnResize
        /* USER CUSTOM PROPS SECTION */
        {...dataGridProProps}
      />
    </Box>
  );
}
