import { useNavigate } from 'react-router-dom';
import { useGlobalStore } from '../global-store/use-store';
import { jwtDecode } from 'jwt-decode';
import { PATH } from '@data/enums';
import { useToggle } from './core';
import { userAuthService } from '@services/domain/auth/authentication';
import { refreshCredentialService } from '@services/domain/auth/refresh-auth-credential';
import mixpanel from 'mixpanel-browser';

type LoginParams = {
  username: string;
  password: string;
  fromPath: string;
};

type useAuthResponse = {
  isAuthenticated: boolean;
  isAdmin: boolean;
  login: (data: LoginParams) => void;
  logout: () => void;
  refreshLogin: () => void;
  isLoading: boolean;
  isError: boolean;
};

export function useAuth(): useAuthResponse {
  const {
    accessToken,
    refreshToken,
    permissions,
    setAccessToken,
    setRefreshToken,
    setPermissions,
    setExpirationTokenDate,
    clearAuthData,
    setOrganizationId,
    setUserOrganizationId,
    setWasTutorialShow,
    setUserFullname,
    setWas_agrotoken_integration_popup_shown,
    setUserID,
    setOrganizationName,
    setAwsTempCredentials,
    setOrganizationTaxId,
    setOrganizationLogo,
    setLanguage,
    setEmail,
    setCountryId,
    setCorvusIntegrated,
    setWas_lasegunda_integration_popup_shown,
    setWas_rus_popup_shown,
    setSelectedOrganizationName,
  } = useGlobalStore();

  const navigate = useNavigate();

  const [isLoading, toggleLoading] = useToggle(false);
  const [isError, toggleError] = useToggle(false);

  const login = async ({ username, password, fromPath }: LoginParams): Promise<void> => {
    toggleLoading(true);
    toggleError(false);

    try {
      const { summary } = await userAuthService({ body: { username, password } });
      const preferedLanguageId = summary?.prefered_language_id === 1 ? 'es' : 'pt';
      localStorage.setItem('LANGUAGE_PREFERENCE', preferedLanguageId);
      setLanguage(preferedLanguageId);
      setPermissions(summary?.permissions);
      setOrganizationId(summary?.permissions?.organization?.id);
      setUserOrganizationId(summary?.permissions?.organization?.id);
      setWasTutorialShow(summary?.was_tutorial_shown);
      setWas_agrotoken_integration_popup_shown(summary?.was_agrotoken_integration_popup_shown);
      setWas_lasegunda_integration_popup_shown(summary?.was_la_segunda_popup_shown);
      setWas_rus_popup_shown(summary?.was_rus_popup_shown);
      setUserFullname(summary.userFullname);
      setUserID(summary?.userID);
      setEmail(summary?.email);
      setOrganizationTaxId(summary.organizationTaxId);
      setOrganizationName(summary.organizationName);
      setSelectedOrganizationName(summary.organizationName);
      setAwsTempCredentials(summary.awsTempCredentials);
      setOrganizationLogo(summary.organizationLogo);
      setCountryId(summary?.country_id);
      setCorvusIntegrated(summary?.corvusIntegration);
      if (summary.accessToken && summary.refreshToken) {
        setAccessToken(summary.accessToken);
        setRefreshToken(summary.refreshToken);
        const jwtToken = jwtDecode(summary.accessToken);
        setExpirationTokenDate((jwtToken.exp as number) * 1000);
      }

      mixpanel.identify(summary.userID.toString());
      mixpanel.people.set({
        user_id: summary.userID,
        user_name: summary.userFullname,
        user_email: summary.email,
        user_organization: {
          name: summary.organizationName,
          permissions: summary.permissions.organization,
        },
        user_countryside: {
          id: summary.country_id,
          permissions: summary.permissions.countryside,
        },
        user_language: summary.prefered_language_id,
      });
      mixpanel.track('DEV@LOG_IN');

      navigate(fromPath ?? PATH.DASHBOARD);
    } catch (error) {
      toggleError(true);
      return;
    } finally {
      toggleLoading(false);
    }
  };

  const refreshLogin = async () => {
    toggleLoading(true);
    toggleError(false);
    try {
      const { response, summary } = await refreshCredentialService();

      if (!response.ok && response.status === 401) logout();

      setPermissions(summary.permissions);
      if (summary.accessToken && summary.refreshToken && summary.awsTempCredentials) {
        setAccessToken(summary.accessToken);
        setRefreshToken(summary.refreshToken);
        setAwsTempCredentials(summary.awsTempCredentials);

        const jwtToken = jwtDecode(summary.accessToken);
        setExpirationTokenDate((jwtToken.exp as number) * 1000);
      }
    } catch (error: PromiseRejectedResult | any) {
      toggleError(true);

      if (error?.response?.status === 401) logout();

      console.error(error);
      return;
    } finally {
      toggleLoading(false);
    }
  };

  const logout = () => (clearAuthData(), navigate(PATH.LOGIN));

  return {
    isAuthenticated: Boolean(accessToken && refreshToken),
    isAdmin: Boolean(permissions?.organization?.full),
    login,
    logout,
    refreshLogin: refreshLogin,
    isLoading,
    isError,
  };
}
