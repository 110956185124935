import { ENDPOINT } from '@data/enums';
import { BACKEND } from '@services/backend';

export async function postInsuranceExternalLinks_DEPRECATED(body: any) {
  const { response, data } = await BACKEND.post(ENDPOINT.EXTERNAL_LINKS, { body: body });

  return {
    response,
    summary: data,
  };
}

