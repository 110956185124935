import {
  Grid,
  Paper,
  Typography,
  Box,
  Menu,
  MenuItem,
  Fade,
  Snackbar,
  Alert,
  AlertColor,
  Stack,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import { SilobagListFilters } from '../../features/silobags/silobags-list/silobag-filters';
import {
  Title,
  CustomPaper,
  mainContainerStyle,
  typographyStyle,
  configButtonStylesOutlined,
  configButtonStylesContained,
} from '../../styles/generic-styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH } from '@data/enums';
import { formatTons, formatDate } from '@utils/helper';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { GenerateSilobagExcelData, GenerateExtractionsExcelData } from '@utils/export-files';
import { useService } from '@hooks/use-service';
import { useToggle } from '@hooks/core';
import { ExtractionAction } from '@features/silobags/silobag-detail/exctraction-modal';
import { useEffectOnUpdate } from '@hooks/core';
import downloadPdfIcon from '@assets/svg/downloadIcon.svg';
import i18n from '../../libs/language';
import {
  downloadSilobagExcel,
  uploadSilobagExcel,
  uploadCloseSilobagExcel,
  downloadCloseExcel,
} from '@utils/import-files';
import { massiveCloseService_DEPRECATED } from '@services/silobag/silobag';
import { listSilobagService } from '@services/domain/base/silobag/base/silobag-list';
import { listCountrysideService } from '@services/domain/base/countryside/countryside-list';
import { ToastAction } from '@ui/toast/toast-action';
import { utils, writeFile } from 'xlsx';
import { ModalInfoRegisterSilobags } from '@features/silobags/modal-info-silobag/modal-info';
import { SilobagShareModal } from '@features/silobags/silobag-share/silobag-share';
import { sharePermissionListService } from '@services/domain/base/silobag/share/share-list';
import ShareICON from '@assets/svg/share.svg';
import shareDisabledIcon from '@assets/svg/share-disabled.svg';
import { useGlobalStore } from '@global-store/use-store';
import { SILOBAG_STATUSES_ID } from '@data/to-refactor/constants';
import { SilobagModalMassiveClose } from '@features/silobags/silobag-close/silobag-close';
import { DownloadLabTemplateModal } from '@features/silobags/lab-template-modal/lab-template-modal';
import { importLabProtocolService } from '@services/domain/laboratory/lab-protocol-import';
import { silobagCMAService } from '@services/domain/base/silobag/file/cmc';
import { extractionListService } from '@services/domain/base/silobag/extraction/extraction-list';
import { silobagMassiveUploadService } from '@services/domain/base/silobag/base/massive-upload';
import {
  invertMap,
  culturaMap,
  produtoMap,
  humidityMap,
  weightMap,
  sidechargeMap,
} from '../../utils/import-files';

type ResponseItemClose = {
  id: string;
  status: string;
  message?: string;
};

interface ErrorItem {
  Errores: string;
  id?: string;
}

type SummarySilobagList = Awaited<ReturnType<typeof listSilobagService>>['summary']['data'];

export function SilobagListPage() {
  const {
    country_id,
    filters,
    filteredUrl,
    organizationId,
    selectedOrganizationName,
    setFilters,
    permissions: organizationPermissions,
    userOrganizationId,
  } = useGlobalStore();
  const orgWritePermission = organizationPermissions?.organization.write;
  const orgFullPermission = organizationPermissions?.organization.full_permission;
  const location = useLocation();
  const { state } = location;
  const newSiloSuccess = useRef(state?.newOrEditSilobagSuccessfull);
  const [siloHeaderData, setSiloHeaderData] = useState({
    totalRemainingStock: 0,
    silobagQty: 0,
    remainingStock: 0,
    web_silobags_stock: 0,
    filteredInputWeight: 0,
    filteredExtractedWeight: 0,
    filteredRemainingStock: 0,
  });
  const [siloListAll, setSiloListAll] = useState<SummarySilobagList | undefined>([]);
  const [siloListFilter, setSiloListFilter] = useState<SummarySilobagList | undefined>([]);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useToggle(false);
  const [typeSnackbar, setTypeSnackbar] = useState<'success' | 'error'>('success');
  const [remainingStock, setRemainingStock] = useState(0);
  const [modalExtraction, setModalExtraction] = useToggle(false);
  const [paramsExportFilter, setParamsExportFilter] = useState({});
  const [extractionSuccess, setExtractionSuccess] = useState(false);
  const [percetageFiltered, setPercentageFiltered] = useState(100);
  const [selectedSilobags, setSelectedSilobags] = useState<SummarySilobagList>([]);
  const [modalShare, setModalShare] = useToggle(false);
  const [modalClose, setModalClose] = useToggle(false);
  const [permissions, setPermissions] = useState([]);

  // CHEQUEA SI EL LISTADO ES DE SILOBOLSAS CERRADAS
  const [isCloseList, setIsCloseList] = useToggle(!filters.active);

  const [silobagShared, setSilobagShared] = useState<{
    message: string;
    state: boolean;
    type: 'success' | 'error';
  }>({ message: '', state: false, type: 'success' });
  const [isNonValidatedSBList, setIsNonValidatedSBList] = useToggle(false);
  const [hasFilters, setHasFilters] = useToggle(true);
  const [toastState, setToastState] = useToggle(false);
  useEffectOnUpdate(() => {
    setToastState(silobagShared.state);
  }, [silobagShared]);

  const {
    trigger: getExtracions,
    summary: dataExtractions,
    isLoading: isLoadingExtractions,
  } = useService(extractionListService);
  const navigate = useNavigate();

  // SE HACE UN GET DE countrysides Y NO EL ESTADO GLOBAL PORQUE NO ESTÁ ACTUALIZANDO CUANDO SE ELIMINA/AGREGA UNO DESDE ADMINISTRADOR
  const queryCountrysideList = { organization_id: organizationId };
  const { summary: countrysides, isLoading: isCountrysideLoading } = useService(listCountrysideService, {
    fetchOnMount: [{ query: queryCountrysideList }],
  });

  useEffectOnUpdate(() => {
    if (!isLoadingExtractions && dataExtractions) {
      GenerateExtractionsExcelData(dataExtractions);
    }
  }, [!isLoadingExtractions]);

  const handlerUpdateHeaderData = (newState: any) => {
    setSiloHeaderData(newState);
    if (newState?.totalRemainingStock === 0) return setPercentageFiltered(0);
    const percentage = (newState?.remainingStock / newState?.totalRemainingStock) * 100;
    const percentageInt = parseInt(percentage.toString(), 10);
    setPercentageFiltered(percentageInt);
  };

  const {
    trigger: getSiloPermission,
    summary: dataSiloPermission,
    isLoading: isLoadingSiloPermission,
  } = useService(sharePermissionListService);

  const handleGetSiloListFilter = (newState?: SummarySilobagList) => {
    setSiloListFilter(newState);
  };

  const handleGetSiloListAll = (newState?: SummarySilobagList) => {
    setSiloListAll(newState);
  };

  const handleGetParamsFilter = (newState: any) => {
    setParamsExportFilter(newState);
  };
  const paramsExportFilterString = Object.values(paramsExportFilter).join(' ');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElMassive, setAnchorElMassive] = useState<null | HTMLElement>(null);
  const [openMassive, setOpenMassive] = useState({ upload: false, close: false });
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [isMassiveClose, setIsMassiveClose] = useState(false);
  const [toast, setToast] = useState({
    message: '',
    severity: undefined as AlertColor | undefined,
    open: false,
  });
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickMassive = (menuId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMassive(event.currentTarget);
    // Explicitly setting states based on the action to avoid unintended side effects
    if (menuId === 'upload') {
      setOpenMassive((prev) => ({ ...prev, upload: !prev.upload, close: false }));
    } else if (menuId === 'close') {
      setOpenMassive((prev) => ({ ...prev, close: !prev.close, upload: false }));
    }
  };

  const handleUploadClick = () => {
    setAnchorElMassive(null);
    setOpenMassive((prev) => ({ ...prev, upload: false }));
    const fileUpload = document.getElementById('fileUpload');
    fileUpload?.click();
  };

  const handleDownloadClick = () => {
    setAnchorElMassive(null);
    setOpenMassive((prev) => ({ ...prev, upload: false }));
    downloadSilobagExcel();
  };

  const {
    summary: dataCMA,
    isLoading: isLoadingCMA,
    isSuccess: isSuccessCmA,
  } = useService(silobagCMAService);
  function downloadFile(fileUrl: string, fileName: string) {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  useEffect(() => {
    if (isSuccessCmA) {
      dataCMA?.forEach((item, index: number) => {
        setTimeout(() => {
          downloadFile(item.file_path, `file${index}.pdf`);
        }, index * 2000);
      });
    }
  }, [dataCMA, isSuccessCmA]);

  const handleUploadCloseClick = () => {
    setAnchorElMassive(null);
    setOpenMassive((prev) => ({ ...prev, upload: false }));
    const fileUpload = document.getElementById('fileUploadClose');
    fileUpload?.click();
  };

  const handleDownloadCloseClick = () => {
    setAnchorElMassive(null);
    setOpenMassive((prev) => ({ ...prev, upload: false }));
    downloadCloseExcel();
  };

  const {
    trigger: createMassiveSilobag,
    summary: massiveSilobagSummary,
    isSuccess,
  } = useService(silobagMassiveUploadService, {
    onSuccess: () => {
      setFilters({ ...filters, silobag_data_status_id: 1 });
      setUploading(true);
      setToast({
        message: '',
        severity: 'success',
        open: false,
      });
    },
  });

  const {
    trigger: createMassiveCloseSilobag,
    summary: summaryClose,
    isSuccess: isSuccessClose,
    isError: errorClose,
  } = useService(massiveCloseService_DEPRECATED, {
    onSuccess: () => {
      setUploading(true);
      setToast({
        message: '',
        severity: 'success',
        open: false,
      });
    },
  });

  useEffect(() => {
    if (errorClose) {
      setToast({
        message: 'No posee permisos suficientes.',
        severity: 'error',
        open: true,
      });
    }
  }, [errorClose]);
  const handleSummaryClose = (summary: ResponseItemClose[] | undefined) => {
    if (!summary) return;

    const { successCount, errorCount, errors } = summarizeResults(summary);

    setToastMessage(successCount, errorCount);

    if (errors.length > 0) {
      generateErrorReport(errors);
    }
  };
  const summarizeResults = (summary: ResponseItemClose[]) => {
    let successCount = 0;
    let errorCount = 0;
    const errors: ErrorItem[] = [];

    summary.forEach(({ id, status, message }) => {
      if (status === 'closed') {
        successCount++;
      } else if (status === 'error') {
        errorCount++;
        errors.push({ id, Errores: message || 'Error desconocido' });
      }
    });

    return { successCount, errorCount, errors };
  };

  const setToastMessage = (successCount: number, errorCount: number) => {
    if (errorCount === 0) {
      return setToast({
        message: i18n.t('app.silobag_action.close_success'),
        severity: 'success',
        open: true,
      });
    }

    if (successCount > 0) {
      return setToast({
        message: i18n.t('app.silobag_action.close_warning'),
        severity: 'warning',
        open: true,
      });
    }

    return setToast({
      message: i18n.t('app.silobag_action.close_error'),
      severity: 'error',
      open: true,
    });
  };

  const generateErrorReport = (errors: ErrorItem[]) => {
    const worksheet = utils.json_to_sheet(errors);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Errores');
    writeFile(workbook, 'errores.xlsx');
  };

  const CulturaMap = invertMap(culturaMap);
  const ProdutoMap = invertMap(produtoMap);
  const HumidityMap = invertMap(humidityMap);
  const WeightMap = invertMap(weightMap);
  const SidechargeMap = invertMap(sidechargeMap);

  const translateData = (data: any, language: string) => {
    if (language !== 'pt') {
      return data;
    }

    return {
      ...data,
      silobag_species_type_name: CulturaMap[data.silobag_species_type_name] || data.silobag_species_type_name,
      silobag_product_type_name: ProdutoMap[data.silobag_product_type_name] || data.silobag_product_type_name,
      humidity_source_description:
        HumidityMap[data.humidity_source_description] || data.humidity_source_description,
      weight_source_description: WeightMap[data.weight_source_description] || data.weight_source_description,
      side_charge_description: SidechargeMap[data.side_charge_description] || data.side_charge_description,
    };
  };

  type MassiveUploadType = Awaited<ReturnType<typeof silobagMassiveUploadService>>['summary'];
  type SilobagMassiveUploadType = MassiveUploadType[0];

  const handleSummary = (summary: MassiveUploadType | undefined) => {
    const language = i18n.locale;
    if (summary) {
      let successCount = 0;
      let errorCount = 0;
      const allRows = [];
      // Encabezado de la plantilla excel de producto segun idioma
      const headerRow1 =
        language === 'pt'
          ? [
            'Requerimento',
            'Obrigatório',
            'Obrigatório',
            'Obrigatório',
            'Obrigatório',
            'Obrigatório',
            'Obrigatório',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
          ]
          : [
            'Requerimiento',
            'Obligatorio',
            'Obligatorio',
            'Obligatorio',
            'Obligatorio',
            'Obligatorio',
            'Obligatorio',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
            'Opcional',
          ];
      const headerRow2 =
        language === 'pt'
          ? [
            'Erro',
            'Empresa',
            'Fazenda',
            'Nome silo bolsa',
            'Cultura',
            'Produto',
            'Peso embolsado (kg)',
            'Talhão estoque',
            'Data embolsamento',
            'Variedade',
            'Umidade inicial (%)',
            'Tamanho silobolsa (m)',
            'Safra',
            'Talhão origem',
            'Responsável embolsamento',
            'Marca silobolsa',
            '% Impureza',
            '% Avariados',
            'Coeficiente de enchimento (kg/m)',
            'Fonte de umidade',
            'Fonte de pesagem',
            'Local do silo bolsa',
            'Tem danos?',
            'Observações',
          ]
          : [
            'Error',
            'Compañía',
            'Establecimiento',
            'Nombre Silobolsa',
            'Especie',
            'Producto',
            'Peso Embolsado (Kg)',
            'Lote Stock',
            'Fecha Embolsado',
            'Variedad',
            'Humedad (%)',
            'Largo (m)',
            'Cosecha',
            'Lotes origen',
            'Responsable embolsado',
            'Marca del silobolsa',
            '% Impureza',
            '% Defectuoso',
            'Coeficiente de llenado (kg/m)',
            'Fuente de la humedad',
            'Fuente del peso',
            'Camión carga sobre',
            'Observaciones',
          ];

      allRows.push(headerRow1, headerRow2);

      summary.forEach((item: SilobagMassiveUploadType) => {
        if (item.success) {
          successCount++;
        } else {
          errorCount++;
          if (item.error) {
            const translatedData = translateData(item.error.data, language);
            const errorMessage = i18n.t(`app.silobag_action.error_messages.${item.error.code}`);

            const errorRow = [
              errorMessage,
              translatedData.companies,
              translatedData.countryside_name,
              translatedData.name,
              translatedData.silobag_species_type_name,
              translatedData.silobag_product_type_name,
              translatedData.input_weight,
              translatedData.lots_name,
              translatedData.bagging_date,
              translatedData.variety,
              translatedData.initial_humidity,
              translatedData.size,
              translatedData.silobag_harvest_name,
              translatedData.origin_lots,
              translatedData.responsible_for_bagging,
              translatedData.silobag_brand,
              translatedData.varietal_purity,
              translatedData.split_grain_percentage,
              translatedData.fillying_coefficient,
              translatedData.humidity_source_description,
              translatedData.weight_source_description,
              translatedData.side_charge_description,
              translatedData.observations,
            ];

            // Arreglo de errores para meterlos al excel

            allRows.push(errorRow);
          }
        }
      });

      // Mensajes de éxito, advertencia y error
      if (errorCount === 0) {
        setToast({
          message: i18n.t('app.silobag_action.upload_success'),
          severity: 'success',
          open: true,
        });
      } else if (successCount > 0) {
        setToast({
          message: i18n.t('app.silobag_action.upload_warning'),
          severity: 'warning',
          open: true,
        });
      } else {
        setToast({ message: i18n.t('app.silobag_action.upload_error'), severity: 'error', open: true });
      }

      if (allRows.length > 2) {
        const worksheet = utils.aoa_to_sheet(allRows);
        const workbook = utils.book_new();
        const sheetName = language === 'pt' ? 'Erros' : 'Errores';
        utils.book_append_sheet(workbook, worksheet, sheetName);
        writeFile(workbook, `${sheetName}.xlsx`);
      }
    }
  };

  useEffect(() => {
    if (
      Array.isArray(massiveSilobagSummary) &&
      massiveSilobagSummary.every((item) => typeof item === 'object')
    ) {
      handleSummary(massiveSilobagSummary);
    }
  }, [massiveSilobagSummary]);

  useEffect(() => {
    if (Array.isArray(summaryClose) && summaryClose.every((item) => typeof item === 'object')) {
      handleSummaryClose(summaryClose as ResponseItemClose[]);
    }
  }, [summaryClose]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploading(true);
    if (event.target.files && event.target.files.length > 0) {
      uploadSilobagExcel(event.target.files[0], createMassiveSilobag);
      event.target.value = '';
    }
  };

  const handleFileChangeClose = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploading(true);
    if (event.target.files && event.target.files.length > 0) {
      uploadCloseSilobagExcel(event.target.files[0], createMassiveCloseSilobag);
      event.target.value = '';
    }
  };

  const today = new Date().toString();
  const todayDate = formatDate(today);

  const handleRegisterSilobag = () => {
    if (countrysides && countrysides.length > 0) {
      navigate(PATH.SILOBAG + '/new', {
        state: { isGuest: userOrganizationId !== organizationId },
      });
    } else {
      setOpenInfoModal(true);
    }
  };

  const handleSilobagsSelected = (silobags: SummarySilobagList) => {
    setSelectedSilobags(silobags);
  };

  const hasSilobagWithStatusOne = siloListFilter?.some((silobag) => silobag.silobag_data_statuses.id === 1);

  const handleModalShare = async (silobags: string[]) => {
    const permissionsPromises = silobags.map((siloId) => sharePermissionListService({ query: siloId }));
    const permissions = await Promise.all(permissionsPromises);
    const permissionsData = permissions.map((permission) => permission.summary);
    setPermissions(permissionsData);
    setModalShare(true);
  };
  //EXPORT SB DATA FUNCIONALITY
  const [caseExport, setCaseExport] = useState<string>('');
  const queryParamSilobagService = { organization_id: organizationId, active: filters.active };
  const {
    trigger: getSiloData,
    summary: silosToExport,
    isLoading: isLoadingExport,
  } = useService(listSilobagService);

  useEffectOnUpdate(() => {
    if (silosToExport && caseExport.length > 0 && !isLoadingExport) {
      if (caseExport == 'all') {
        GenerateSilobagExcelData(
          silosToExport?.data,
          `${i18n.t('app.silobags')} ${selectedOrganizationName} ${todayDate}`
        );
        setCaseExport('');
      } else {
        GenerateSilobagExcelData(
          silosToExport?.data,
          `${i18n.t(
            'app.silobags'
          )} ${selectedOrganizationName} ${todayDate} - Filtros - + ${paramsExportFilterString}`
        );
        setCaseExport('');
      }
    }
  }, [silosToExport, caseExport, isLoadingExport]);

  const selectedSilobagsIds = selectedSilobags.length
    ? selectedSilobags.map((silobag: SummarySilobagList[0]) => silobag.id)
    : [];

  /// --- [LAB-TEMPLATE] Upload template logic --- \\\
  const {
    trigger: importLabProtocol,
    isLoading: isTemplateImportLoading,
    isSuccess: isTemplateImportSuccess,
    isError: isTemplateImportError,
    error: templateImportError,
  } = useService(importLabProtocolService);

  const uploadLabTemplateRef = useRef<HTMLInputElement>(null);
  const handleOpenFileExplorer = () => uploadLabTemplateRef.current?.click();

  const [isInvalidFileType, setIsInvalidFileType] = useState(false);
  const [targetUploadedTemplateName, setTargetUploadedTemplateName] = useState('');
  const handleSubmitLabTemplate = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const hasFiles = files && files.length > 0;
    if (!hasFiles) return;

    const [file] = Array.from(files);

    const acceptedFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    if (!acceptedFileTypes.includes(file.type)) {
      setIsInvalidFileType(true);
      return;
    }

    setTargetUploadedTemplateName(file.name.replace(/\.[^/.]+$/, ''));
    importLabProtocol({ body: { inputFile: file } });
  };

  /// --- [LAB-TEMPLATE] Download template (with upload validations errors) logic --- \\\
  const downloadLabTemplateErrorRef = useRef<HTMLAnchorElement>(null);
  useEffectOnUpdate(() => {
    const { current } = downloadLabTemplateErrorRef;
    const isExcelError = isTemplateImportError && templateImportError?.data?.message == null;

    if (!isExcelError || !current) return;

    const { URL } = window;
    current.setAttribute('href', URL.createObjectURL(templateImportError.data));
    current.setAttribute('download', targetUploadedTemplateName + ' - ERROR.xlsx');
    current.click();
  }, [isTemplateImportError]);

  /// --- [LAB-TEMPLATE] Modal for downloading templates --- \\\
  const [downloadLabTemplateModal, setDownloadLabTemplateModal] = useToggle(false);

  const hasInactiveSilobags = siloListFilter?.some((silobag) => !silobag.active);

  const ERROR_MSG: Record<number, string> = {
    10019: `${i18n.t('app.lab.error.err10019')} (E.#10019)`,
    10020: `${i18n.t('app.lab.error.err10020')} (E.#10020)`,
  };
  const errorCode = templateImportError?.data?.message?.code;
  const errorMessage = templateImportError?.data?.message?.message;
  const displayErrorMessage = ERROR_MSG[errorCode] ?? errorMessage ?? i18n.t('app.lab.error.default');

  /// --- [CLOSURE-SILOBAG] --- \\\
  const selectedSilobagsCloseMobile = selectedSilobags
    .filter((silobag) => silobag.v_is_silobag_available_for_closure.result === false)
    .map((silobag) => silobag.id);

  const insuranceValidation =
    selectedSilobags.length === 0 || country_id !== 1 || !orgWritePermission || isNonValidatedSBList;

  const textInsuranceDisabled = insuranceValidation
    ? i18n.t('app.silobag_insurance.validation')
    : i18n.t('app.silobag_insurance.disabled_close_mobile', { ids: selectedSilobagsCloseMobile.join(', ') });

  const selectedSilobagsWithFullPermission: SummarySilobagList = selectedSilobags.filter(
    (silobag) => silobag.full_permission === true
  );

  return (
    <Box
      style={{
        width: '98.5%',
        margin: 'auto',
      }}
    >
      <a ref={downloadLabTemplateErrorRef} download={'asd'} style={{ display: 'none' }} />
      <input
        ref={uploadLabTemplateRef}
        type="file"
        hidden
        accept=".xlsx"
        onChange={handleSubmitLabTemplate}
      />
      {isInvalidFileType ? (
        <ToastAction
          key="invalid-file-type"
          message={i18n.t('app.lab.error.invalid_file_type')}
          severity="error"
          onClose={() => setIsInvalidFileType(false)}
        />
      ) : null}
      {isTemplateImportSuccess ? (
        <ToastAction
          key="upload-template-success"
          message={i18n.t('app.lab.success.upload')}
          severity="success"
        />
      ) : null}
      {isTemplateImportError ? (
        <ToastAction key="upload-template-error" message={displayErrorMessage} severity={'error'} />
      ) : null}
      {newSiloSuccess.current && (
        <ToastAction key={'success'} message={i18n.t('app.silobag_list.new_silobag')} severity={'success'} />
      )}
      <ModalInfoRegisterSilobags open={openInfoModal} setIsOpen={setOpenInfoModal} />
      {toast.message && (
        <ToastAction
          key={toast.message}
          message={toast.message}
          severity={toast.severity as 'success' | 'error' | 'info' | 'warning'}
          onActionClick={() => setToast({ ...toast, open: false })}
        />
      )}
      {silobagShared.state ? (
        <ToastAction
          message={silobagShared.message}
          severity={silobagShared.type}
          openToast={toastState}
          setOpenToast={setToastState}
        />
      ) : null}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity={typeSnackbar} variant="filled" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Grid container sx={mainContainerStyle} style={{ width: '100%', margin: 'auto', paddingTop: '20px' }}>
        <Stack direction={{ xs: 'column', md: 'row' }} style={{ width: '100%' }}>
          <Stack style={{ flex: 1 }}>
            <Paper sx={CustomPaper}>
              <Typography sx={Title} variant="h5">
                {filters.silobag_data_status_id === SILOBAG_STATUSES_ID.NON_VALID_SILOBAG_STATUS_ID
                  ? i18n.t('app.silobag_list.your_silobags')
                  : i18n.t('app.silobag_list.your_siloreales')}
              </Typography>
            </Paper>
          </Stack>
          <Stack direction="row" flexWrap="wrap" justifyContent={{ xs: 'center' }}>
            <Box component="span" columnGap={10}>
              <Button
                startIcon={<img src={downloadPdfIcon} alt="download" />}
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="outlined"
                sx={(theme) => ({
                  ...configButtonStylesOutlined,
                  [theme.breakpoints.down('sm')]: {
                    fontSize: 12.7,
                  },
                })}
                size="medium"
                disabled={isLoadingCMA}
              >
                {isLoadingCMA ? (
                  <CircularProgress sx={{ color: 'green' }} size={20} />
                ) : (
                  i18n.t('app.silobag_list.export_data')
                )}
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem
                  onClick={() => {
                    setCaseExport('all');
                    getSiloData({ query: queryParamSilobagService });
                  }}
                  sx={{ color: '#26C4B7' }}
                >
                  {i18n.t('app.silobag_list.export_all')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    const { paginationModel, ...restFilters } = filters;
                    paginationModel;
                    setCaseExport('filtered');
                    getSiloData({
                      query: {
                        ...queryParamSilobagService,
                        ...restFilters
                      }
                    });
                  }}
                  sx={{ color: '#26C4B7' }}
                  disabled={filteredUrl.length === 0}
                >
                  {i18n.t('app.silobag_list.export_view')}
                </MenuItem>
                <Tooltip
                  title={i18n.t('app.tooltip_sb_selected')}
                  disableHoverListener={selectedSilobags.length > 0}
                >
                  <Box>
                    <MenuItem
                      onClick={() =>
                        GenerateSilobagExcelData(
                          selectedSilobags,
                          `${i18n.t('app.silobags')} ${selectedOrganizationName} ${todayDate} - ${i18n.t(
                            'app.silobag_list.selected'
                          )}`
                        )
                      }
                      sx={{ color: '#26C4B7' }}
                      disabled={selectedSilobags.length === 0}
                    >
                      {i18n.t('app.silobag_list.export_selects')}
                    </MenuItem>
                  </Box>
                </Tooltip>
                <MenuItem onClick={getExtracions} sx={{ color: '#26C4B7' }}>
                  {i18n.t('app.silobag_list.export_extraction')}
                </MenuItem>
              </Menu>
            </Box>
            <Box>
              <Button
                id="fade-button-massive-upload"
                aria-controls={openMassive ? 'fade-menu-massive-upload' : undefined}
                aria-haspopup="true"
                aria-expanded={openMassive ? 'true' : undefined}
                onClick={handleClickMassive('upload')}
                variant="contained"
                disabled={!orgWritePermission || isTemplateImportLoading}
                sx={configButtonStylesContained}
                style={{ minWidth: '180px' }}
                size="medium"
              >
                {isTemplateImportLoading ? (
                  <CircularProgress sx={{ color: 'green' }} size={20} />
                ) : (
                  i18n.t('app.silobag_list.massive_actions')
                )}
              </Button>
              <Menu
                id="fade-menu-massive-upload"
                MenuListProps={{
                  'aria-labelledby': 'fade-button-massive-upload',
                }}
                anchorEl={anchorElMassive}
                open={openMassive.upload}
                onClose={() => setOpenMassive((prev) => ({ ...prev, upload: false }))}
                TransitionComponent={Fade}
              >
                <MenuItem disabled sx={{ fontWeight: 'bold', color: 'black', fontSize: '0.875rem' }}>
                  {i18n.t('app.silobag_list.quality_samples')}
                </MenuItem>
                <MenuItem onClick={handleOpenFileExplorer} sx={{ color: '#26C4B7' }}>
                  {i18n.t('app.silobag_list.charge_page.quality')}
                </MenuItem>
                <Tooltip
                  title={!selectedSilobags.length ? i18n.t('app.lab.template_download.tooltip.init') : ''}
                >
                  <Box>
                    <MenuItem
                      disabled={!selectedSilobags.length}
                      onClick={() => setDownloadLabTemplateModal(true)}
                      sx={{ color: '#26C4B7' }}
                    >
                      {i18n.t('app.silobag_list.download_page.quality')}
                    </MenuItem>
                  </Box>
                </Tooltip>
                <MenuItem disabled sx={{ fontWeight: 'bold', color: 'black', fontSize: '0.875rem' }}>
                  {i18n.t('app.silobag_list.masive_register')}
                </MenuItem>
                <MenuItem onClick={handleUploadClick} sx={{ color: '#26C4B7' }}>
                  {i18n.t('app.silobag_list.charge_page.register')}
                </MenuItem>
                <MenuItem onClick={handleDownloadClick} sx={{ color: '#26C4B7' }}>
                  {i18n.t('app.silobag_list.download_page.register')}
                </MenuItem>
                <MenuItem disabled sx={{ fontWeight: 'bold', color: 'black', fontSize: '0.875rem' }}>
                  {i18n.t('app.silobag_list.massive_extraction')}
                </MenuItem>
                <Tooltip title={i18n.t('app.soon')}>
                  <Box>
                    <MenuItem disabled sx={{ color: '#26C4B7' }}>
                      {i18n.t('app.silobag_list.charge_page.extraction')}
                    </MenuItem>
                  </Box>
                </Tooltip>
                <Tooltip title={i18n.t('app.soon')}>
                  <Box>
                    <MenuItem disabled sx={{ color: '#26C4B7' }}>
                      {i18n.t('app.silobag_list.download_page.extraction')}
                    </MenuItem>
                  </Box>
                </Tooltip>
                <MenuItem disabled sx={{ fontWeight: 'bold', color: 'black', fontSize: '0.875rem' }}>
                  {i18n.t('app.silobag_list.massive_close')}
                </MenuItem>
                <MenuItem onClick={handleUploadCloseClick} sx={{ color: '#26C4B7' }}>
                  {i18n.t('app.silobag_list.charge_page.close')}
                </MenuItem>
                <MenuItem onClick={handleDownloadCloseClick} sx={{ color: '#26C4B7' }}>
                  {i18n.t('app.silobag_list.download_page.close')}
                </MenuItem>
              </Menu>
              <input
                type="file"
                id="fileUpload"
                style={{ display: 'none' }}
                accept=".xls,.xlsx"
                onChange={handleFileChange}
              />
              <input
                type="file"
                id="fileUploadClose"
                style={{ display: 'none' }}
                accept=".xls,.xlsx"
                onChange={handleFileChangeClose}
              />
              {selectedSilobags.length === 0 && !hasInactiveSilobags ? (
                <>
                  <Button
                    variant="contained"
                    size="medium"
                    sx={configButtonStylesContained}
                    disabled={isCountrysideLoading || !orgWritePermission}
                    onClick={handleRegisterSilobag}
                  >
                    {i18n.t('app.silobag_list.register_silobag')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    sx={{ ...configButtonStylesContained, background: '#FF0000', color: '#FFFFFF' }}
                    onClick={() => {
                      setModalClose(true);
                      setIsMassiveClose(false);
                    }}
                    disabled={
                      hasInactiveSilobags ||
                      (!orgWritePermission && selectedSilobagsWithFullPermission.length === 0)
                    }
                  >
                    {i18n.t('app.silobag_list.close_silobags')}
                  </Button>
                </>
              )}
              {selectedSilobags.length < 3 && !hasInactiveSilobags ? (
                <Button
                  variant="outlined"
                  size="medium"
                  sx={(theme) => ({
                    ...configButtonStylesOutlined,
                    [theme.breakpoints.down('sm')]: {
                      fontSize: 12.7,
                    },
                  })}
                  onClick={() => setModalExtraction(true)}
                  disabled={!orgWritePermission}
                >
                  {i18n.t('app.silobag_list.make_extraction')}
                </Button>
              ) : null}
              <Tooltip title={i18n.t('app.coming_soon')}>
                <span>
                  <Button
                    variant="contained"
                    sx={{
                      ...configButtonStylesContained,
                      width: { xs: '95%', md: '180px' },
                      backgroundColor: '#961EFA',
                      color: '#FFF',
                    }}
                    disabled
                  >
                    {i18n.t('app.quote_silo_warrant')}
                  </Button>
                </span>
              </Tooltip>
              {insuranceValidation || selectedSilobagsCloseMobile.length > 0 ? (
                <Tooltip title={textInsuranceDisabled}>
                  <span>
                    <Button
                      variant="contained"
                      sx={{
                        ...configButtonStylesContained,
                        width: { xs: '95%', md: '180px' },
                        backgroundColor: '#961EFA',
                        color: '#FFF',
                      }}
                      disabled
                      onClick={() => {
                        navigate(`${PATH.SILOBAG}/insurance/${selectedSilobagsIds.join(',')}`);
                      }}
                    >
                      {i18n.t('app.quote_silo_insurance')}
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    ...configButtonStylesContained,
                    width: { xs: '95%', md: '180px' },
                    backgroundColor: '#961EFA',
                    color: '#FFF',
                  }}
                  onClick={() => navigate(`${PATH.SILOBAG}/insurance/${selectedSilobagsIds.join(',')}`)}
                >
                  {i18n.t('app.quote_silo_insurance')}
                </Button>
              )}
              <Tooltip
                title={i18n.t('app.tooltip_sb_share_selected')}
                disableHoverListener={selectedSilobags.length > 0}
              >
                <span>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      ...configButtonStylesContained,
                      background: 'rgba(107, 170, 0, 0.25)',
                      color: selectedSilobags.length === 0 ? 'inherit' : '#6baa00',
                    }}
                    onClick={() => {
                      handleModalShare(selectedSilobagsIds);
                      setModalShare(true);
                    }}
                    disabled={
                      selectedSilobags.length === 0 ||
                      (!orgFullPermission && selectedSilobagsWithFullPermission.length === 0)
                    }
                  >
                    <span>{i18n.t('app.silobag_share.share_silobag_title')}</span>
                    <span style={{ marginLeft: '10px', marginTop: '5px' }}>
                      <img
                        src={
                          selectedSilobags.length === 0 ||
                            (!orgFullPermission && selectedSilobagsWithFullPermission.length === 0)
                            ? shareDisabledIcon
                            : ShareICON
                        }
                        style={{ objectFit: 'contain' }}
                      />
                    </span>
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Stack>
        </Stack>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          style={{
            textAlign: 'left',
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: '20px',
            marginTop: '15px',
          }}
          width={'100%'}
          display={'flex'}
          alignItems={{ xs: 'start', md: 'center' }}
        >
          <Stack direction={'row'} display={'flex'} alignItems={'center'}>
            <Typography
              component="span"
              style={{ borderRight: '1px solid #B1B3B4', padding: '8px 12px' }}
              sx={typographyStyle}
            >
              <span style={{ fontSize: '1.5rem' }}>{siloHeaderData.silobagQty} </span>
              {filters.silobag_data_status_id === SILOBAG_STATUSES_ID.NON_VALID_SILOBAG_STATUS_ID
                ? i18n.t('app.silobag_list.your_silobags')
                : i18n.t('app.silobag_list.your_siloreales')}
            </Typography>

            {!isCloseList ? (
              <Typography component="span" sx={typographyStyle}>
                <span style={{ fontSize: '1.5rem' }}>
                  {i18n.numberToDelimited(formatTons(siloHeaderData?.remainingStock) || 0, {
                    delimiter: i18n.t('config.delimiter'),
                  })}{' '}
                </span>
                Toneladas
              </Typography>
            ) : null}
          </Stack>
          {isCloseList ? null : (
            <>
              <Typography
                component="span"
                style={{ marginRight: '10px', borderRight: '1px solid #B1B3B4', padding: '8px 12px' }}
              >
                <span style={{ fontSize: '1.5rem' }}>{percetageFiltered} %</span>
                {i18n.t('app.silobag_list.your_stock_total')}
              </Typography>
              {siloHeaderData.web_silobags_stock > 0 && (
                <Tooltip title={i18n.t('app.missing_tons')} placement="top" arrow>
                  <Typography component="span" style={{ marginRight: '10px' }}>
                    <span style={{ fontSize: '1.5rem' }}>
                      {i18n.numberToDelimited(formatTons(siloHeaderData.web_silobags_stock) || 0, {
                        delimiter: i18n.t('config.delimiter'),
                      })}{' '}
                    </span>
                    {i18n.t('app.silobag_list.non_validated_tons')}
                  </Typography>
                </Tooltip>
              )}
            </>
          )}
          {isCloseList ? (
            <>
              <Typography component="span" sx={typographyStyle}>
                <span style={{ fontSize: '1.5rem' }}>
                  {i18n.numberToDelimited(formatTons(siloHeaderData?.filteredInputWeight) || 0, {
                    delimiter: i18n.t('config.delimiter'),
                  })}{' '}
                </span>
                {i18n.t('app.silobag_list.bagged_tons')}
              </Typography>
              <Typography component="span" sx={typographyStyle}>
                <span style={{ fontSize: '1.5rem' }}>
                  {i18n.numberToDelimited(formatTons(siloHeaderData?.filteredExtractedWeight) || 0, {
                    delimiter: i18n.t('config.delimiter'),
                  })}{' '}
                </span>
                {i18n.t('app.silobag_list.extracted_tons')}
              </Typography>
              <Typography component="span">
                <span style={{ fontSize: '1.5rem' }}>
                  {i18n.numberToDelimited(formatTons(siloHeaderData?.filteredRemainingStock) || 0, {
                    delimiter: i18n.t('config.delimiter'),
                  })}{' '}
                </span>

                {i18n.t('app.silobag_list.tonnage_difference')}
              </Typography>
            </>
          ) : null}
        </Stack>
        <SilobagListFilters
          updateHeaderData={handlerUpdateHeaderData}
          handleGetSiloListFilter={handleGetSiloListFilter}
          handleGetParamsFilter={handleGetParamsFilter}
          handleGetSiloListAll={handleGetSiloListAll}
          isSuccess={isSuccess}
          isSuccessClose={isSuccessClose}
          isMassiveSuccess={isMassiveClose}
          extractionSuccess={extractionSuccess}
          silobagsSelected={handleSilobagsSelected}
          disableFilterExportBtn={setHasFilters}
          setSiloListAll={setSiloListAll}
          setIsNonValidatedSBList={setIsNonValidatedSBList}
          setIsCloseList={setIsCloseList}
          isCloseList={isCloseList}
        />
        {modalClose && (
          <SilobagModalMassiveClose
            open={modalClose}
            setOpen={setModalClose}
            silobagData={selectedSilobags}
            setIsMassiveClose={setIsMassiveClose}
          />
        )}
        {modalShare && (
          <SilobagShareModal
            open={modalShare}
            setOpen={setModalShare}
            siloId={selectedSilobagsIds}
            data={dataSiloPermission}
            massiveData={permissions}
            isLoadingData={isLoadingSiloPermission}
            getData={getSiloPermission}
            setSilobagShared={setSilobagShared}
            hasInvalidStatus={hasSilobagWithStatusOne}
          />
        )}

        <SilobagModalMassiveClose
          open={modalClose}
          setOpen={setModalClose}
          silobagData={selectedSilobags}
          setIsMassiveClose={setIsMassiveClose}
        />
      </Grid>
      {modalExtraction && (
        <ExtractionAction
          type="new"
          selectedSilobags={selectedSilobags}
          fromSilobag={true}
          isUpdate={false}
          open={modalExtraction}
          setOpen={setModalExtraction}
          silobagsList={siloListAll}
          remainingStock={remainingStock} // TODO: Verificar si hay que consumir endpoint o si se puede sacar de otro lado
          setRemainingStock={setRemainingStock}
          setSnackbarMessage={setSnackbarMessage}
          setTypeSnackbar={setTypeSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          setExtractionSuccess={setExtractionSuccess}
        />
      )}
      {downloadLabTemplateModal ? (
        <DownloadLabTemplateModal
          selectedSilobags={selectedSilobags}
          open={downloadLabTemplateModal}
          onClose={() => {
            setDownloadLabTemplateModal(false);
            setAnchorElMassive(null);
            setOpenMassive({ upload: false, close: false });
          }}
        />
      ) : null}
    </Box>
  );
}
