import { Box, Paper, Button, Chip, Snackbar, Alert, Stack, Tooltip } from '@mui/material';
import { customBgWhite } from '@styles/generic-styles';
import {
  setHistoricalPoeImgs,
  setSiloMetricImg,
  setExtractionImgs,
  setDesvSilometricImg,
  formatDateTime,
} from '@utils/helper';
import { BaseTable } from '@ui/table';
import { GridColDef } from '@mui/x-data-grid-pro';
import { PoeModal } from './poe-silobag-modal';
import { useState } from 'react';
import { CarouselModalPhotoGallery } from '@ui/carousel/carousel';
import { useEffectOnUpdate, useToggle } from '@hooks/core';
import { useService } from '@hooks/use-service';
import { GenerateExtractionsExcelData } from '@utils/export-files';
import { useParams } from 'react-router';
import i18n from '../../../../libs/language';
import { ExtractionAction } from '../exctraction-modal';
import { EditSBModal } from './edit-silobag-modal';
import { SILOBAG_EVENTS_IDS } from '@data/to-refactor/constants';
import viewIcon from '@assets/svg/view_blue.svg';
import imgIcon from '@assets/svg/img.svg';
import GreenUpArrow from '@assets/svg/core/arrow-upward-green.svg';
import RedDownArrow from '@assets/svg/core/arrow-downward-red.svg';
import { extractionListService } from '@services/domain/base/silobag/extraction/extraction-list';
import downloadArchiveIcon from '@assets/svg/download_blue.svg';
import BlueEyeIcon from '@assets/svg/view_blue.svg';
import { LabHistoryModal } from '../modal/laboratory/laboratory-history-modal';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';
import { MobileCloseModal } from './mobile-close-modal';

type SummarySilobagDetail = Awaited<ReturnType<typeof silobagDataDetailService>>['summary'];
type PoeSummary = Awaited<ReturnType<typeof silobagDataDetailService>>['summary']['poe'];

type SilobagHistoricalProps = {
  data?: SummarySilobagDetail;
  isLoading: boolean;
  Poe?: PoeSummary;
};

export function HistorySection({ data, isLoading, Poe }: SilobagHistoricalProps) {
  const { siloID } = useParams();
  const [modalPoe, setModalPoe] = useState(false);
  const [modalEditSB, setModalEditSB] = useState(false);
  const [eventId, setEventId] = useState();
  const [dataSb, setDataSb] = useState<SummarySilobagDetail>();
  const [ModifiedData, setModifiedData] = useState();
  const [modalImage, setModalImage] = useToggle(false);
  const [images, setImages] = useState([]);
  const [openModalImages, setOpenModalImages] = useToggle(false);
  const [modalExtraction, setModalExtraction] = useToggle(false);
  const [extractionData, setExtractionData] = useState();
  const [remainingStock, setRemainingStock] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [type, setType] = useState<'view' | 'edit'>('view');
  const [openSnackbar, setOpenSnackbar] = useToggle(false);
  const [typeSnackbar, setTypeSnackbar] = useState<'success' | 'error'>('success');
  const [extractionID, setExtractionID] = useState();
  const [openMobileModal, setOpenMobileModal] = useToggle(false);
  const [openLabModal, toggleOpenLabModal] = useToggle(false);
  const handleOpenLabModal = () => toggleOpenLabModal(true);
  const handleCloseLabModal = () => toggleOpenLabModal(false);

  const originalRows = data?.silobags_history !== undefined ? data?.silobags_history : [];
  const eventWeb = data?.silobags_history?.some((item) => item.silobag_event_type_id === 16);
  const eventMobile = data?.silobags_history?.some((item) => item.silobag_event_type_id === 14);

  const rows = originalRows.map((item, index: number) => ({
    ...item,
    isFirst: index === 0,
    isLast: index === originalRows.length - 1,
  }));

  const dataPoe = Poe !== undefined ? Poe : [];
  const handleOpenPoe = (event_id: any) => {
    setModalPoe(true);
    setEventId(event_id);
  };

  const handleOpenEditSB = (EditHistoryId: number) => {
    if (!EditHistoryId || !data?.silobags_history) return;

    const foundRow = data.silobags_history.find((item: { id: number }) => item.id === EditHistoryId);

    if (!foundRow) return;

    const correctData = data?.silobags_history.filter(
      (arr) =>
        arr.silobag_event_type_id === SILOBAG_EVENTS_IDS.EDICION_SILOBOLSA ||
        arr.silobag_event_type_id === SILOBAG_EVENTS_IDS.ALTA ||
        arr.silobag_event_type_id === SILOBAG_EVENTS_IDS.ALTA_CAMPO ||
        arr.silobag_event_type_id === SILOBAG_EVENTS_IDS.ALTA_WEB ||
        arr.silobag_event_type_id === SILOBAG_EVENTS_IDS.COMPLETADO_DATOS ||
        arr.silobag_event_type_id === SILOBAG_EVENTS_IDS.EMBOLSADO
    );

    const currentVersionIndex = correctData?.findIndex((item: { id: number }) => item.id === EditHistoryId);

    const previousVersion = correctData[currentVersionIndex + 1];

    setModifiedData(foundRow?.silobags_versions?.data);
    setDataSb(previousVersion?.silobags_versions?.data);
    setModalEditSB(true);
  };
  const mainImage = data?.main_image;
  const initImage = data?.init_image;
  const endImage = data?.end_image;

  const silobag_actions = data?.silobags_actions;

  const imagesSilo = [
    {
      url: initImage,
      label: initImage !== null ? `Imagen inicial` : '',
      init_azimuth: data?.init_azimuth,
      init_timestamp: data?.init_timestamp,
      init_lat: data?.init_lat,
      init_lon: data?.init_lon,
      init_accuracy: data?.init_accuracy,
    },
    {
      url: mainImage,
      label: mainImage !== null ? `Imagen principal` : '',
      main_azimuth: data?.main_azimuth,
      main_timestamp: data?.main_timestamp,
      main_lat: data?.main_lat,
      main_lon: data?.main_lon,
      main_accuracy: data?.main_accuracy,
    },
    {
      url: endImage,
      label: endImage !== null ? `Imagen final` : '',
      end_azimuth: data?.end_azimuth,
      end_timestamp: data?.end_timestamp,
      end_lat: data?.end_lat,
      end_lon: data?.end_lon,
      end_accuracy: data?.end_accuracy,
    },
  ].filter((image) => image.url != null);

  if (mainImage !== null || initImage !== null || endImage !== null) {
    if (silobag_actions && silobag_actions?.length > 0) {
      silobag_actions.map((action: any) => {
        if (action?.image !== null) {
          imagesSilo.push({
            url: action?.image,
            label: 'Fotos Extras',
            init_azimuth: action?.azimuth,
            init_timestamp: action?.timestamp,
            init_lat: action?.lat,
            init_lon: action?.lon,
            init_accuracy: action?.accuracy,
          });
        }
      });
    }
  }

  const handleModalImageOpen = (eventId: number, deviceIdOrPoeId: any) => {
    switch (eventId) {
      case 1: //Extracción
        setExtractionImgs(deviceIdOrPoeId, data, setImages);
        setModalImage(true);
        break;
      case 2: //Poe
        setHistoricalPoeImgs(deviceIdOrPoeId, data, setImages);
        setModalImage(true);
        break;
      case 3: // Descargar archivo
        return null;
      case 4: //Silobag
      case 14: //Silobag completado datos
      case 15: //Silobag
      case 16: //Silobag alta web
        setImages(imagesSilo);
        setModalImage(true);
        break;
      case 5: //Embolsado
        return null;
      case 11: // Vinculacion de SiloMetrix
        setSiloMetricImg(deviceIdOrPoeId, data, setImages);
        setModalImage(true);
        break;
      case 12: // Desvinculacion de SiloMetrix
        setDesvSilometricImg(deviceIdOrPoeId, data, setImages);
        setModalImage(true);
        break;
    }
  };

  //Extractions Service and hook
  const {
    trigger: getExtracions,
    summary: dataExtractions,
    isLoading: isLoadingExtractions,
  } = useService(extractionListService);

  useEffectOnUpdate(() => {
    if (!isLoadingExtractions && dataExtractions) {
      GenerateExtractionsExcelData(dataExtractions, siloID);
    }
  }, [!isLoadingExtractions]);

  const ShowExtractionDetail = (extractionData: any, remainingStock: number) => {
    setExtractionData(extractionData);
    setExtractionID(extractionData.id);
    setRemainingStock(remainingStock);
    setModalExtraction(true);
  };
  const enableEditMode = () => setType('edit');

  const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  type LabProtocolSummary = Awaited<
    ReturnType<typeof silobagDataDetailService>
  >['summary']['lab_protocols_history'][0];

  const [targetProtocol, setTargetProtocol] = useState<LabProtocolSummary>();

  const STATE = {
    OPENED: 4,
    CLOSED: 6,
  };
  const isOpenedOrClosedState = (id: number) => id === STATE.OPENED || id === STATE.CLOSED;
  const columns: GridColDef[] = [
    {
      field: 'created_at',
      headerName: i18n.t('app.date'),
      flex: 1,
      minWidth: 160,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              position: 'relative',
              marginRight: 2,
              '&:before': {
                content: params.api.getSortedRowIds().at(0) === params.row.id ? 'none' : '""',
                position: 'absolute',
                bottom: 1,
                left: -1,
                width: '2px',
                height: '30px',
                backgroundColor: '#49454F',
                zIndex: -1,
              },
              '&:after': {
                content: params.api.getSortedRowIds().at(-1) === params.row.id ? 'none' : '""',
                position: 'absolute',
                top: 1,
                left: -1,
                width: '2px',
                height: '30px',
                backgroundColor: '#49454F',
                zIndex: -1,
              },
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                width: isOpenedOrClosedState(params.row.silobags_history_event_types.id) ? '14px' : '8px',
                height: isOpenedOrClosedState(params.row.silobags_history_event_types.id) ? '14px' : '8px',
                borderRadius: '50%',
                backgroundColor: '#49454F',
              }}
            />
          </Box>
          <span>{formatDateTime(params.value, 3)}</span>
        </Box>
      ),
    },
    {
      field: 'silobags_history_event_types',
      headerName: 'Evento',
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        const description = params.value.silobags_history_event_types_translations[0].description;
        const isRecorrida = description === 'Recorrida' || description === 'Existência';
        const isValid = params.row.poe ? params.row.poe.valid : null;
        const chipLabel =
          isRecorrida && (isValid === null || isValid === false) ? `${description} 🔴` : description;
        const chip = (
          <Chip
            label={chipLabel}
            sx={{
              backgroundColor: params.value.bk_color,
              color: params.value.text_color,
              fontSize: '11px',
              fontWeight: 'bold',
              borderRadius: '8px',
            }}
          />
        );
        return isRecorrida && (isValid === null || isValid === false) ? (
          <Tooltip title={i18n.t('app.silobag_history.poe_warning')}>{chip}</Tooltip>
        ) : (
          chip
        );
      },
    },
    {
      field: 'egress',
      headerName: i18n.t('app.stock_movement'),
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        const hasIncomeValue = params.row.income !== null;
        const hasEgressValue = params.value !== null;

        // Default settings
        let displayValue = '―';
        let styles = {};
        let icon;

        // Income settings
        if (hasIncomeValue) {
          const delimitedValue = i18n.numberToDelimited(params.row.income, {
            delimiter: i18n.t('config.delimiter'),
          });
          displayValue = `${delimitedValue} kg`;
          styles = { color: '#73BD00', fontWeight: 500 };
          icon = GreenUpArrow;
        }

        // Egress settings
        if (hasEgressValue) {
          const delimitedValue = i18n.numberToDelimited(params.value, {
            delimiter: i18n.t('config.delimiter'),
          });
          displayValue = `${delimitedValue} kg`;
          styles = { color: '#FF0000', fontWeight: 500 };
          icon = RedDownArrow;
        }

        return (
          <span style={styles}>
            {' '}
            <img src={icon} /> {displayValue}
          </span>
        );
      },
    },
    {
      field: 'remaining_stock',
      headerName: i18n.t('app.silobag_data.remaining_stock'),
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: (params) => (
        <span>
          {i18n.numberToDelimited(parseInt(params.value), { delimiter: i18n.t('config.delimiter') })} kg
        </span>
      ),
    },
    {
      field: 'silobags_history_statuses',
      headerName: 'Estado',
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" gap={0.7}>
          <Box
            sx={{
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              backgroundColor: params.value.color,
            }}
          />
          <Box>{params.value.description}</Box>
        </Stack>
      ),
    },
    {
      field: 'users',
      headerName: 'Usuario',
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: (params) => <span>{params.value?.fullname}</span>,
    },
    {
      field: 'detail',
      headerName: i18n.t('app.detail'),
      flex: 1,
      minWidth: 300,
      sortable: false,
      renderCell: (params) => {
        const detailID = params.row.silobags_history_event_types.id;
        const poe_id = parseInt(params.row.poe_id, 10);
        // TODO: CREAR UN ARRAY CONSTANTE CON LOS IDS.
        const excludedDetails = [2, 3, 5, 6, 7, 8, 16, 17, 18, 19, 21, 20];
        // const editSbVersion = params.row.silobags_versions
        //   ? parseInt(params.row.silobags_versions.id, 10)
        //   : null;
        const EditHistoryId = parseInt(params.row.id, 10);

        const labModalAction = () => {
          const protocolID = params.row.lab_protocol_history_id;
          //TODO FALTO TIPAR DESARROLLO LAB
          const protocolHistory = data?.lab_protocols_history;

          const newTargetProtocol = protocolHistory!.find((protocol) => protocol.id === protocolID);
          setTargetProtocol(newTargetProtocol);
          handleOpenLabModal();
        };

        const actionMap: { [key: number]: () => void } = {
          2: () => handleOpenPoe(poe_id),
          7: () => handleOpenEditSB(EditHistoryId),
          19: () => setOpenMobileModal(true),
          20: labModalAction,
          21: labModalAction,
        };

        return (
          <Box>
            {detailID == 2 || detailID == 7 || detailID == 19 || detailID == 21 || detailID == 20 ? (
              <Button
                startIcon={<img src={viewIcon} style={{ width: 18, height: 15 }} />}
                style={{ color: '#09AAEB', textTransform: 'none' }}
                onClick={actionMap[detailID]}
              >
                {i18n.t('app.detail')}
              </Button>
            ) : null}
            {!excludedDetails.includes(detailID) && (
              <Button
                startIcon={<img src={imgIcon} style={{ width: 16, height: 13 }} />}
                sx={{ fontSize: '14px', color: '#09AAEB', textTransform: 'none' }}
                onClick={() =>
                  handleModalImageOpen(
                    detailID,
                    params.row.silobag_device_id || params.row.poe_id || params.row.id
                  )
                }
              >
                {i18n.t('app.images')}
              </Button>
            )}
            {detailID === 3 ? (
              <a download href={params.row.files?.file_path}>
                <Button
                  startIcon={<img src={downloadArchiveIcon} alt="download" />}
                  sx={{ fontSize: '14px', color: '#09AAEB', textTransform: 'none' }}
                >
                  {i18n.t('app.silobag_history.download_file')}
                </Button>
              </a>
            ) : null}
            {detailID === 6 ? (
              <Button
                startIcon={<img src={downloadArchiveIcon} alt="download" />}
                sx={{ fontSize: '14px', color: '#09AAEB', textTransform: 'none' }}
                onClick={getExtracions}
              >
                {i18n.t('app.silobag_history.resumen')}
              </Button>
            ) : null}
            {(detailID === 1 || detailID === 8) && (
              <Button
                startIcon={<img src={BlueEyeIcon} alt="extraction-detail" />}
                sx={{ fontSize: '14px', color: '#09AAEB', textTransform: 'none' }}
                onClick={() =>
                  ShowExtractionDetail(
                    params?.row?.silobags_extractions_versions?.data,
                    params?.row?.remaining_stock
                  )
                }
              >
                {i18n.t('app.silobag_history.extraction')}
              </Button>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Paper elevation={0} sx={customBgWhite}>
        {openModalImages && (
          <CarouselModalPhotoGallery
            open={openModalImages}
            onClose={setOpenModalImages}
            images={images}
            title="Imagenes"
            sbId={data?.id}
          />
        )}
        {PoeModal && (
          <PoeModal
            open={modalPoe}
            setOpen={setModalPoe}
            data={dataPoe}
            poeId={eventId}
            eventWeb={eventWeb}
            eventMobile={eventMobile}
          />
        )}
        {modalEditSB && (
          <EditSBModal
            open={modalEditSB}
            setOpen={setModalEditSB}
            dataSb={dataSb}
            modifiedDataSb={ModifiedData}
          />
        )}
        {modalImage && (
          <CarouselModalPhotoGallery
            open={modalImage}
            onClose={setModalImage}
            images={images}
            title="Imagenes"
            sbId={data?.id}
          />
        )}
        {openLabModal ? (
          <LabHistoryModal
            open={openLabModal}
            onClose={handleCloseLabModal}
            data={targetProtocol!}
            siloSpecies={data?.silobag_species_types.silobag_species_types_translations.description}
            siloVariety={data?.variety}
          />
        ) : null}
        {openMobileModal && (
          <MobileCloseModal
            open={openMobileModal}
            setOpen={setOpenMobileModal}
            data={data?.silobags_history}
          />
        )}
        <Box component="h1" margin={0}>
          {i18n.t('app.silobag_data.history')}
        </Box>
        <BaseTable rows={rows ?? []} columns={columns} initialPageSize={10} loading={isLoading} noBorders />
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity={typeSnackbar} variant="filled" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {/* // TODO: ❌ TypeScript Error: ExtractionAction no tiene la propiedad 'siloTrigger' porque es un modal que no admite modificaciones por lo que no tiene que hacer refresh al vuelo */}
      {modalExtraction && (
        <ExtractionAction
          extractionData={extractionData}
          silobagOrExtractionId={extractionID}
          siloName={data?.name}
          type={type}
          fromHistory={true}
          open={modalExtraction}
          setOpen={setModalExtraction}
          remainingStock={remainingStock} // TODO: Verificar si hay que consumir endpoint o si se puede sacar de otro lado
          setRemainingStock={setRemainingStock}
          setSnackbarMessage={setSnackbarMessage}
          setTypeSnackbar={setTypeSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          enableEditMode={enableEditMode}
        />
      )}
    </>
  );
}
