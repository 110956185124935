import { Wrapper } from '@googlemaps/react-wrapper';
import { Box, CircularProgress, Stack } from '@mui/material';
import { PropsWithChildren, useCallback, useRef } from 'react';
import { useMap } from '../use-map';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { initConstants } from './map-root.const';

import { useService } from '@hooks/use-service';
import { useEffectOnUnmount, useEffectOnUpdate } from '@hooks/core';
import { INFO_WINDOW_GENERATION } from './map-root.utils';
import { useGlobalStore } from '@global-store/use-store';
import { useParams } from 'react-router-dom';
import { mapInfoService } from '@services/domain/page/map/map-info';

export function MapRoot({ children }: PropsWithChildren) {
  const { organizationId } = useGlobalStore();

  const { siloID } = useParams();

  const payload = { query: { organization_id: organizationId, silobag_id: siloID } };
  const {
    trigger,
    isLoading,
    summary: mapDataFromBackend,
  } = useService(mapInfoService, { fetchOnMount: [payload] });
  useEffectOnUpdate(() => trigger(payload), [organizationId]);

  const {
    map,
    setMap,
    fullData,
    setInitFullData,
    setInfoWindow,
    setCluster,
    setGlobalBounds,
    ui,
    MAP_UTILS,
  } = useMap();

  //const { state: navigationState } = useLocation();
  const hasToStartInSilobagDetail = Boolean(siloID);

  const initMap = useCallback(
    (node: HTMLDivElement) => {
      if (!node) return;
      if (!window.google) return;
      if (!mapDataFromBackend) return;

      const MAP_CONSTANT = initConstants(window.google);

      //  MAP CREATION
      const newMap = new window.google.maps.Map(node, {
        center: MAP_CONSTANT.CENTER,
        zoom: MAP_CONSTANT.ZOOM,
        mapTypeId: window.google.maps.MapTypeId.HYBRID,
        zoomControl: false,
        disableDefaultUI: true,
        minZoom: 3,
      });
      setMap(newMap);

      // INFO WINDOW CREATION
      const newInfoWindow = new google.maps.InfoWindow({
        position: MAP_CONSTANT.CENTER,
        maxWidth: 1200,
      });
      setInfoWindow(newInfoWindow);

      // CLUSTER CREATION
      const newCluster = new MarkerClusterer({
        map: newMap,
        renderer: {
          render: (cluster) => {
            const sumOfTotalSilobags = cluster.markers?.reduce((rec, m) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return rec + parseInt(m.getLabel()?.text, 10);
            }, 0);
            const newLabel = String(sumOfTotalSilobags);

            const newMarkerForCluster = new window.google.maps.Marker({
              position: cluster.position,
              icon: MAP_CONSTANT.PIN.cluster,
              label: MAP_CONSTANT.LABEL.cluster(newLabel),
            });

            const clusterInfoWindowContent = INFO_WINDOW_GENERATION.forCluster(cluster);

            newMarkerForCluster.addListener('mouseover', () => {
              newInfoWindow.setContent(clusterInfoWindowContent);
              newInfoWindow.setPosition(cluster.position);
              newInfoWindow.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1);
              newMarkerForCluster.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1);
              newInfoWindow.open({ anchor: newMarkerForCluster, map: newMap });
            });
            newMarkerForCluster.addListener('mouseout', () => {
              newMarkerForCluster.setZIndex();
              newInfoWindow.close();
            });

            return newMarkerForCluster;
          },
        },
      });
      setCluster(newCluster);

      // BOUNDS CREATION
      const newGlobalBounds = new window.google.maps.LatLngBounds();
      setGlobalBounds(newGlobalBounds);

      let totalRemainingStock = 0;
      // COUNTRYSIDE MARKER CREATION
      const unifiedDataWithMarkers = mapDataFromBackend.countrysides?.map((countryside) => {
        const coords = { lat: parseFloat(countryside.lat!), lng: parseFloat(countryside.lon!) };

        const newCountrysideMarker = new window.google.maps.Marker({
          position: coords,
          map: newMap,
          icon: MAP_CONSTANT.PIN.countryside,
          label: MAP_CONSTANT.LABEL.countryside(countryside.silobags.length.toString()),
          visible: !hasToStartInSilobagDetail,
        });
        newGlobalBounds.extend(coords);
        newCountrysideMarker.set('countryside', countryside);
        if (!hasToStartInSilobagDetail) newCluster.addMarker(newCountrysideMarker);

        const countrysideInfoWindowContent = INFO_WINDOW_GENERATION.forCountryside(countryside);

        newCountrysideMarker.addListener('mouseover', () => {
          newInfoWindow.setContent(countrysideInfoWindowContent);
          newInfoWindow.setPosition(coords);
          newInfoWindow.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1);
          newCountrysideMarker.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1);
          newInfoWindow.open({ anchor: newCountrysideMarker, map: newMap });
        });
        newCountrysideMarker.addListener('mouseout', () => {
          newCountrysideMarker.setZIndex();
          newInfoWindow.close();
        });

        // SILOBAG MARKER CREATION
        const newSilobagsBounds = new window.google.maps.LatLngBounds();

        const OFFSET = 0.00005;

        const unifiedSilobagDataWithMarkers = countryside.silobags.map((silobag, index) => {
          const coords = { lat: parseFloat(silobag.main_lat!), lng: parseFloat(silobag.main_lon!) };

          if (index > 0) {
            // Cambiar las coordenadas según el índice para evitar superposición
            coords.lat = parseFloat(silobag.main_lat!) + OFFSET * index;
            coords.lng = parseFloat(silobag.main_lon!) + OFFSET * index;
          }

          const newSilobagMarker = new window.google.maps.Marker({
            position: coords,
            map: newMap,
            icon: MAP_CONSTANT.PIN.silobag(silobag.silobag_species_types.name, silobag.hex),
            visible: countryside.id === mapDataFromBackend?.silobag_info?.countryside_id,
          });
          newSilobagsBounds.extend(coords);

          const silobagInfoWindowContent = INFO_WINDOW_GENERATION.forSilobag(silobag);

          newSilobagMarker.addListener('mouseover', () => {
            newInfoWindow.setContent(silobagInfoWindowContent);
            newInfoWindow.setPosition(coords);
            newInfoWindow.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1);
            newInfoWindow.open({ anchor: newSilobagMarker, map: newMap });
          });
          newSilobagMarker.addListener('mouseout', () => {
            newInfoWindow.close();
          });

          totalRemainingStock += parseInt(silobag.remaining_stock ?? '0', 10);

          return {
            ...silobag,
            marker: newSilobagMarker,
          };
        });

        return {
          ...countryside,
          marker: newCountrysideMarker,
          silobags: unifiedSilobagDataWithMarkers,
          silobagsBounds: newSilobagsBounds,
        };
      });

      const hasCountrySides = unifiedDataWithMarkers.length > 0;
      if (hasCountrySides) {
        newMap.fitBounds(newGlobalBounds, { left: 400, top: 150 });
        newMap.setZoom(4);
      } else {
        // TODO: paises hardcodeados en el mapa acá
        const COUNTRY_CENTER_COORDS = {
          1: { lat: -37, lng: -65 },
          2: { lat: -15, lng: -47 },
        };
        const COUNTRY_DEFAULT_ZOOM = {
          1: 5,
          2: 5,
        };
        newMap.setCenter(COUNTRY_CENTER_COORDS[mapDataFromBackend.country_id as 1]);
        newMap.setZoom(COUNTRY_DEFAULT_ZOOM[mapDataFromBackend.country_id as 1]);
      }

      const totalTons = Math.round(totalRemainingStock / 1000);

      setInitFullData({
        ...mapDataFromBackend,
        totalRemainingStock: totalTons,
        filteredRemainingStock: totalTons,
        countrysides: unifiedDataWithMarkers,
      });
    },
    [mapDataFromBackend]
  );

  const firstUpdate = useRef(true);
  useEffectOnUpdate(() => {
    if (
      fullData &&
      hasToStartInSilobagDetail &&
      firstUpdate.current &&
      mapDataFromBackend &&
      mapDataFromBackend.silobag_info
    ) {
      const { countrysides, silobag_info } = mapDataFromBackend;

      const countrysideIDX = countrysides.findIndex((c) => c.id === silobag_info.countryside_id);
      const newTargetCountryside = countrysides[countrysideIDX];

      const silobagIDX = newTargetCountryside.silobags.findIndex((s) => s.id === siloID);

      MAP_UTILS.HANDLERS.initOnDetail(countrysideIDX, silobagIDX);
      //MAP_UTILS.CHANGE_TARGET.countryside(countrysideIDX);
      //MAP_UTILS.CHANGE_TARGET.silobag(silobagIDX);
      //setUI('detail-from-normal');

      firstUpdate.current = false;
    }
  }, [fullData]);

  useEffectOnUpdate(() => {
    if (!fullData) return;

    fullData.countrysides.forEach((countryside, i: number) => {
      window.google.maps.event.clearListeners(countryside.marker, 'click');
      countryside.marker.addListener('click', MAP_UTILS.NAVIGATION.fromCountryside(i).toDetail);
      countryside.silobags.forEach((silobag, j: number) => {
        window.google.maps.event.clearListeners(silobag.marker, 'click');
        silobag.marker.addListener('click', () => MAP_UTILS.HANDLERS.onSilobagPinClick(i, j));
      });
    });
  }, [fullData, ui]);

  useEffectOnUnmount(() => {
    if (!map) return;
    window.google.maps.event.clearInstanceListeners(map);
  });

  return (
    <Wrapper apiKey={import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_KEY}>
      {isLoading || !mapDataFromBackend ? (
        <Stack justifyContent="center" alignItems="center" height="100%">
          <CircularProgress size={50} />
        </Stack>
      ) : (
        <Box component="div" id="main-map-root" ref={initMap}>
          {children}
        </Box>
      )}
    </Wrapper>
  );
}
